import React from "react";
import ReactDOM from "react-dom/client";
import "./styles/index.css";
import App from "./pages/App";
import reportWebVitals from "./reportWebVitals";
import { ChakraProvider } from "@chakra-ui/react";
import { BrowserRouter, useNavigate } from "react-router-dom";
import { extendTheme } from "@chakra-ui/react";
import "@fontsource/baumans";
import "@fontsource/comfortaa";
import "@fontsource/caveat";
import { ApolloProvider } from "@apollo/client";
import { createAppSyncClient } from "./appsync/AppSyncClient";
import { Auth } from "aws-amplify";
import AnalyticsWrapper from "./utils/google-analytics-lib/analytics-wrapper";
declare module "react-table";

const theme = extendTheme({
  fonts: {
    logoFont2: `'Source Sans Pro', sans-serif`,
    logoFont4: `'Baumans', cursive`,
    logoFont: `"Comfortaa", sans-serif;`,
    CaveaFont: `'Caveat', cursive;`,
  },
  colors: {
    brand: {
      "50": "#E5EAFB",
      "100": "#D6E4FF",
      "200": "#ADC8FF",
      "300": "#84A9FF",
      "400": "#6690FF",
      "500": "#3366FF",
      "600": "#254EDB",
      "700": "#162F83",
      "800": "#162F83",
      "900": "#162F83",
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

const qs = window.location.search;
if (window.location.pathname.indexOf("signup") > -1 && qs.indexOf("code=") > -1) {
  window.location.search = qs.replace("code", "bCode");
}

const validateUserSession = async () => {
  try {
    await Auth.currentSession();
  } catch (error) {}
};

root.render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>
      <BrowserRouter>
        <AnalyticsWrapper>
          <ApolloProvider client={createAppSyncClient(validateUserSession)}>
            <App />
          </ApolloProvider>
        </AnalyticsWrapper>
      </BrowserRouter>
    </ChakraProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
