import { Flex, Text, VStack, Spinner, Button } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { config } from "../../deployment/config";
import { useCreateSite } from "../../hooks/create-site";

export const CreateSite = ({ getFreshAccountInformation }: { getFreshAccountInformation: any }) => {
  const { createSite, createSiteData, createSiteLoading, createSiteError } = useCreateSite();
  const navigate = useNavigate();

  let [isCalled, setCalled] = useState(false);
  useEffect(() => {
    if (!isCalled) {
      isCalled = true;
      setCalled(true);
      const code = localStorage.getItem("phrase");
      localStorage.removeItem("phrase");
      if (!code) {
        redirectToWebflowForAuth();
      } else {
        createSite({ variables: { code: code } });
      }
    }

    if (createSiteData && createSiteData["__typename"] == "Success") {
      getFreshAccountInformation();
      const FirstTimeSetupNotDone = localStorage.getItem("ftsNotDone"); // first time setup instruction check
      if (FirstTimeSetupNotDone == "true") {
        window.location.href = "/first-time-setup";
        //navigate("/first-time-setup", { replace: true });
      } else {
        window.location.href = "/orders";
        //navigate("/orders", { replace: true });
      }
    }
  }, [createSiteData, createSiteLoading]);

  const redirectToWebflowForAuth = () => {
    window.location.replace(`${config.API_ENDPOINT}/webflow/install`);
  };

  const redirectToLogin = () => {
    getFreshAccountInformation();
    navigate("/", { replace: true });
  };

  return (
    <Flex h="100vh" justifyContent="center" alignItems="center">
      {createSiteLoading && (
        <VStack spacing={"10"}>
          <Spinner thickness="4px" speed="0.5s" emptyColor="gray.200" color="brand.500" size="xl" />
          <Text fontSize={"3xl"} as="b">
            Your workspace is getting dressed up and ready to go...
          </Text>
        </VStack>
      )}

      {isCalled && !createSiteLoading && createSiteData && (createSiteData["__typename"] == "CreateSiteInvalidCode" || createSiteData["__typename"] == "CreateSiteUserNotAllowedAuthorization") && (
        <VStack spacing={"10"}>
          <Text fontSize={"xl"} as="b" bg={"orange.200"} px={"5"} py={"1"} borderRadius={"3xl"}>
            Sites authorization that was granted by you has expired, please reauthorize them.
          </Text>
          <Button colorScheme={"brand"} size={"lg"} onClick={redirectToWebflowForAuth}>
            Reauthorize
          </Button>
        </VStack>
      )}

      {isCalled && !createSiteLoading && (createSiteError || (createSiteData && (createSiteData["__typename"] == "CreateSiteFailedToGetAccessToken" || createSiteData["__typename"] == "UserAuthenticationError"))) && (
        <VStack spacing={"10"}>
          <Text fontSize={"xl"} as="b" bg={"red.300"} px={"5"} py={"1"} borderRadius={"3xl"}>
            Something went wrong while contacting Webflow. Please try again after sometime..
          </Text>
          <Button colorScheme={"brand"} size={"lg"} onClick={redirectToLogin}>
            Refresh
          </Button>
        </VStack>
      )}
    </Flex>
  );
};
