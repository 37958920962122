import { Button, Flex, HStack, Text } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

export const PlanLimitReached = ({ isUsageLimitReached }: { isUsageLimitReached: boolean }) => {
  const navigate = useNavigate();
  const upgradePlan = () => {
    navigate("/change-plan");
  };
  return (
    <>
      {isUsageLimitReached && (
        <Flex bg={"orange"} w={"100%"}>
          <HStack px={5} py={2} spacing={5}>
            <Text as="b" fontSize={"md"}>
              Your current plan has reached its monthly orders limit. Please upgrade your plan to continue processing the orders.
            </Text>
            <Button size="sm" rounded={"full"} boxShadow={"xl"} px={10} cursor={"pointer"} border={0} onClick={upgradePlan}>
              Upgrade
            </Button>
          </HStack>
        </Flex>
      )}
    </>
  );
};
