import { gql, useLazyQuery } from "@apollo/client";
import { Analytics } from "../appsync/API";
import { getProAnalytics } from "../appsync/graphql/queries";

export const useGetProAnalytics = () => {
  const [call, { loading, error, data }] = useLazyQuery<{ getProAnalytics: Analytics }>(gql(getProAnalytics), {
    fetchPolicy: "no-cache",
  });

  return {
    getProAnalytics: call,
    getProAnalyticsData: data?.getProAnalytics,
    getProAnalyticsLoading: loading,
    getProAnalyticsError: error,
  };
};
