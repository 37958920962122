import { Button, FormControl, Flex, Heading, Input, Stack, Text, useColorModeValue, Center, AlertIcon, Alert, AlertTitle } from "@chakra-ui/react";
import { Auth } from "aws-amplify";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

export default function ForgotPassword(): JSX.Element {
  const [userEmail, setUserEmail] = useState("");
  const [failedVerifyErrorMessage, setFailedVerifyErrorMessage] = useState("");
  const [isForgotPasswordInProgress, setIsForgotPasswordInProgress] = useState(false);

  const setResetEmail = (event: any) => {
    setUserEmail(event.target.value);
    setFailedVerifyErrorMessage("");
  };
  const navigate = useNavigate();

  async function sendVerificationEmail() {
    const username = userEmail;
    if (username.length > 2) {
      setIsForgotPasswordInProgress(true);
      localStorage.setItem("username", username);
      await Auth.forgotPassword(username)
        .then(() => {
          navigate("/reset-password", { replace: true });
        })
        .catch((error) => {
          handleForgotPasswordErrorException(error);
        })
        .finally(() => {
          setIsForgotPasswordInProgress(false);
        });
    } else {
      setFailedVerifyErrorMessage("Enter valid email.");
    }
  }

  const handleForgotPasswordErrorException = (error: any) => {
    if (error?.code == "UserNotFoundException") {
      setFailedVerifyErrorMessage("Email is not registered.");
    } else {
      setFailedVerifyErrorMessage("Something went wrong.");
    }
    //Log
  };

  return (
    <Flex minH={"100vh"} align={"center"} justify={"center"} bg={useColorModeValue("gray.50", "gray.800")}>
      <Stack w={"full"} maxW={"md"} bg={useColorModeValue("white", "gray.700")} rounded={"xl"} boxShadow={"lg"} p={10} my={12}>
        <Heading lineHeight={1.1} fontSize={{ base: "2xl", md: "3xl" }}>
          Forgot your password?
        </Heading>
        <Text fontSize={"md"} color={"gray.600"}>
          No worries, we&apos;ll send you reset instructions.
        </Text>
        <FormControl id="email" pt={10}>
          <Input placeholder="your-email@example.com" _placeholder={{ color: "gray.500" }} type="email" onChange={setResetEmail} isDisabled={isForgotPasswordInProgress} />
          <Center h={"20px"} mt={5}>
            {failedVerifyErrorMessage != "" && (
              <Alert status="error" bg="white" textAlign={"center"} justifyContent={"center"} size="sm">
                <AlertIcon />
                <AlertTitle>{failedVerifyErrorMessage}</AlertTitle>
              </Alert>
            )}
          </Center>
        </FormControl>
        <Stack spacing={6}>
          <Button
            isLoading={isForgotPasswordInProgress}
            onClick={() => sendVerificationEmail()}
            bg={"brand.500"}
            color={"white"}
            _hover={{
              bg: "brand.600",
            }}
          >
            Request Reset
          </Button>
        </Stack>
      </Stack>
    </Flex>
  );
}
