import { ChevronDownIcon } from "@chakra-ui/icons";
import { Button, Flex, Icon, Text, VStack, Box, HStack, NumberInput, NumberInputField, NumberInputStepper, NumberIncrementStepper, NumberDecrementStepper, Menu, MenuButton, MenuList, MenuItem, Heading, Input, Textarea, Tag, Center, InputGroup, InputLeftElement } from "@chakra-ui/react";
import { has, isEmpty, pickBy } from "lodash";
import { useState } from "react";
import { ChromePicker } from "react-color";
import { RiSettings4Fill } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { useFirstTimeSetup } from "../../hooks/first-time-setup";

export const FirstTimeSetup = () => {
  const { firstTimeSetup, firstTimeSetupData, firstTimeSetupLoading, firstTimeSetupError } = useFirstTimeSetup();
  const [siteSettings, setSiteSettings] = useState({ settings: {}, widget: {}, email: {} });
  const [step, setStep] = useState(1);
  const saveChanges = () => {
    setStep(3);
    localStorage.removeItem("ftsNotDone");

    const filteredSiteSettings: any = pickBy(siteSettings, (value) => !isEmpty(value));
    if (filteredSiteSettings && Object.keys(filteredSiteSettings).length != 0) {
      firstTimeSetup({ variables: { siteSettings: filteredSiteSettings } });
    }
  };

  return (
    <Flex h="100vh" justifyContent="center" bg={"gray.100"}>
      <VStack>
        <HStack py={3} spacing={5}>
          <Box rounded={"full"} w={8} p={1} bg={"brand.600"}>
            <Center>
              <Text color={"white"}>1</Text>
            </Center>
          </Box>
          <Box rounded={"full"} w={8} p={1} bg={step >= 2 ? "brand.600" : "gray.400"}>
            <Center>
              <Text color={"white"}>2</Text>
            </Center>
          </Box>
          <Box rounded={"full"} w={8} p={1} bg={step >= 3 ? "brand.600" : "gray.400"}>
            <Center>
              <Text color={"white"}>3</Text>
            </Center>
          </Box>
        </HStack>
        {step == 1 && (
          <PersonalizeReviewWidget
            goToNextStep={() => setStep(2)}
            updateInParent={(val: any) => {
              const widget = { ...siteSettings.widget, [Object.keys(val)[0]]: Object.values(val)[0] };
              setSiteSettings({
                ...siteSettings,
                widget,
              });
            }}
          ></PersonalizeReviewWidget>
        )}
        {step == 2 && (
          <PersonalizeReviewRequestEmail
            goToNextStep={saveChanges}
            updateInParent={(val: any) => {
              if (has(val, "body")) {
                const email = { ...siteSettings.email, [Object.keys(val)[0]]: Object.values(val)[0] };
                setSiteSettings({
                  ...siteSettings,
                  email,
                });
              } else {
                const settings = { ...siteSettings.settings, [Object.keys(val)[0]]: Object.values(val)[0] };
                setSiteSettings({
                  ...siteSettings,
                  settings,
                });
              }
            }}
          ></PersonalizeReviewRequestEmail>
        )}
        {step == 3 && <Installation></Installation>}
      </VStack>
    </Flex>
  );
};

const PersonalizeReviewWidget = ({ goToNextStep, updateInParent }: { goToNextStep: any; updateInParent: any }) => {
  const [primaryColor, setPrimaryColor] = useState("#7d43e3");
  const [starColor, setStarColor] = useState("#f5d332");

  return (
    <Box>
      <VStack spacing={10} w={"3xl"} bg={"white"} p={10} mt={0} boxShadow="lg" alignItems={"center"}>
        <Text fontSize={"2xl"} as="b" color={"pink.500"}>
          Personalize Review Widget
        </Text>
        <VStack alignItems={"left"} w={"400px"}>
          <Heading size="sm">Primary Color</Heading>
          <Text color={"gray.500"} fontSize={"sm"}>
            Main site color or heading/subheading color scheme.
          </Text>
          <HStack>
            <ColorPicker
              currentColor={primaryColor}
              updateCurrentColor={(val: string) => {}}
              colorUpdateComplete={(val: string) => {
                setPrimaryColor(val);
                updateInParent({ primaryColor: val });
              }}
            ></ColorPicker>
          </HStack>
        </VStack>

        <VStack alignItems={"left"} w={"400px"} pt={50}>
          <Heading size="sm">Star Color</Heading>
          <Text color={"gray.500"} fontSize={"sm"}>
            Review widget and star widget star color.
          </Text>
          <HStack>
            <ColorPicker
              currentColor={starColor}
              updateCurrentColor={(val: string) => {}}
              colorUpdateComplete={(val: string) => {
                setStarColor(val);
                updateInParent({ starColor: val });
              }}
            ></ColorPicker>
          </HStack>
        </VStack>

        <VStack spacing={4} pt={20}>
          <Button colorScheme={"brand"} w={"lg"} size={"lg"} onClick={goToNextStep}>
            Continue
          </Button>
          <Text color={"gray.600"}>You can always change this settings later.</Text>
        </VStack>
      </VStack>
    </Box>
  );
};

const PersonalizeReviewRequestEmail = ({ goToNextStep, updateInParent }: { goToNextStep: any; updateInParent: any }) => {
  const [emailDelay, setEmailDelay] = useState(10);
  const [emailTriggerAt, setEmailTriggerAt] = useState("Placed");
  const [body, setBody] = useState(`Dear [Customer_Name],

Thank you for choosing [Site_Name]. We hope you're enjoying your recent purchase!

We'd appreciate it if you could take a moment to share your experience. Your feedback helps us improve and serve you better.`);

  return (
    <Box>
      <VStack spacing={10} w={"3xl"} bg={"white"} p={10} mt={0} boxShadow="lg">
        <Text fontSize={"2xl"} as="b" color={"pink.500"}>
          Personalize Review Request Email
        </Text>
        <VStack spacing={10} w={"100%"} justifyContent={"center"} alignItems={"center"}>
          <VStack alignItems={"left"} w={"100%"}>
            <Text fontSize={"md"} as="b">
              Email timing
            </Text>

            <HStack spacing={100}>
              <HStack spacing={5}>
                <NumberInput
                  size="md"
                  maxW={24}
                  defaultValue={emailDelay}
                  min={1}
                  max={60}
                  onChange={(value) => {
                    setEmailDelay(Number(value));
                    updateInParent({ emailDelay: Number(value) });
                  }}
                >
                  <NumberInputField />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
                <Text fontSize={"md"}>days</Text>
              </HStack>
              <HStack spacing={5}>
                <Text>After: </Text>
                <Menu>
                  <MenuButton as={Button} color="brand.800" rightIcon={<ChevronDownIcon />}>
                    {emailTriggerAt}
                  </MenuButton>
                  <MenuList>
                    <MenuItem
                      onClick={() => {
                        setEmailTriggerAt("Placed");
                        updateInParent({ emailTriggerAt: "Placed" });
                      }}
                    >
                      Placed
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        setEmailTriggerAt("Fulfilled");
                        updateInParent({ emailTriggerAt: "Fulfilled" });
                      }}
                    >
                      Fulfilled
                    </MenuItem>
                  </MenuList>
                </Menu>
              </HStack>
            </HStack>
          </VStack>

          <VStack alignItems={"left"} w={"100%"}>
            <Box>
              <Heading size="sm" my={4}>
                Email Text
              </Heading>
              <Textarea
                placeholder="Enter the body"
                minH={250}
                fontSize={"md"}
                value={body}
                onChange={(e) => {
                  setBody(e.target.value);
                  updateInParent({ body: e.target.value });
                }}
              />
            </Box>

            <Box py={5}>
              <Text pb={3}>Use following placeholders to personalise email for the users:</Text>
              <HStack spacing={3}>
                <Tag p={2}>[Order_Id]</Tag>
                <Tag>[Customer_Name]</Tag>
                <Tag>[Site_Name]</Tag>
              </HStack>
            </Box>
          </VStack>
        </VStack>
        <VStack spacing={4}>
          <Button colorScheme={"brand"} w={"lg"} size={"lg"} onClick={goToNextStep}>
            Continue
          </Button>
          <Text color={"gray.600"}>You can always change this settings later.</Text>
        </VStack>
      </VStack>
    </Box>
  );
};

const Installation = () => {
  const navigate = useNavigate();
  return (
    <Box>
      <VStack spacing={16} w={"3xl"} bg={"white"} p={10} mt={0} boxShadow="lg">
        <Text fontSize={"2xl"} as="b" color={"pink.500"}>
          Congratulations! You're at the last step. Let's get it done!
        </Text>

        <VStack border={"1px"} borderColor={"gray.300"} spacing={35}>
          <Text as="b" pt={5} fontSize={"2xl"}>
            Installation
          </Text>
          <Icon as={RiSettings4Fill} fontSize={"96px"} />
          <Button colorScheme={"brand"} size={"lg"} borderRadius={0} onClick={() => navigate("/how-to-install", { replace: true })}>
            See Instructions
          </Button>
        </VStack>

        <Button color={"brand.600"} variant="link" onClick={() => navigate("/customize", { replace: true })}>
          I'll install later
        </Button>
      </VStack>
    </Box>
  );
};

const ColorPicker = ({ currentColor, updateCurrentColor, colorUpdateComplete }: any) => {
  //const [currentColor, updateCurrentColor] = useState("#671b1b");
  const [isColorPickerOpen, toggleColorPicker] = useState(false);
  const openColorPicker = (val: boolean) => {
    toggleColorPicker(val);
  };

  const onColorUpdate = (val: any) => {
    if (val.length >= 0) {
      updateCurrentColor(val);
    }
  };

  return (
    <>
      <InputGroup>
        <InputLeftElement>
          <Box bg={currentColor} w={10} height={"100%"} border={1} borderColor={"black"} borderRadius={7} onClick={() => openColorPicker(true)}></Box>
        </InputLeftElement>
        <Input type="tel" placeholder="#F23ED7" value={currentColor} pl={50} htmlSize={10} width="auto" onClick={() => openColorPicker(true)} onChange={(event) => onColorUpdate(event?.target.value)} onBlur={(event) => colorUpdateComplete(event?.target.value)} />
      </InputGroup>
      {isColorPickerOpen ? (
        <Box position={"absolute"} zIndex={5}>
          <Box position={"fixed"} top={0} left={0} right={0} bottom={0} onClick={() => openColorPicker(false)}></Box>
          <Box position={"absolute"} top={5} left={0}>
            <ChromePicker color={currentColor} onChange={(color) => updateCurrentColor(color.hex)} onChangeComplete={(color) => colorUpdateComplete(color.hex)}></ChromePicker>
          </Box>
        </Box>
      ) : null}
    </>
  );
};
