import { Button, Flex, Icon, Text, VStack, Box } from "@chakra-ui/react";
import { MdAdd } from "react-icons/md";
import { CreateSite } from "./create-site";

export const NewUserSetup = () => {
  return (
    <Flex h="100vh" justifyContent="center" alignItems="center">
      <VStack>
        <Text fontSize={"6xl"} as="b">
          Welcome to Brandzway
        </Text>
        <Text bg={"#feebc8"} px={"6"} py={"1"} borderRadius={"20px"}>
          It appears that there are no sites added to your account. Kindly add them now.
        </Text>
        <Box pt={"20"}>
          <Button leftIcon={<Icon as={MdAdd} color={"white"} />} px={4} size={"lg"} colorScheme={"brand"}>
            Add Site
          </Button>
        </Box>
      </VStack>
    </Flex>
  );
};
