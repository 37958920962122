import { Button, HStack, useToast, VStack } from "@chakra-ui/react";
import { RangeDatepicker } from "chakra-dayzed-datepicker";
import { useState } from "react";

export const DateRangePicker = ({ selectedDateRange, isHorizontalStack }: { selectedDateRange: any; isHorizontalStack: boolean }) => {
  const toast = useToast();
  const geLast30DaysDateRange = () => {
    const today = new Date();
    const thirtyDaysAgo = new Date(today.getTime() - 30 * 24 * 60 * 60 * 1000);
    return [thirtyDaysAgo, today];
  };

  const [selectedDates, setSelectedDates] = useState<Date[]>(geLast30DaysDateRange());
  const [isDateRangeChanged, setDateRangeChanged] = useState(false);
  const [minDate, setMinDate] = useState(new Date(Date.now() - 2 * 365 * 24 * 60 * 60 * 1000)); // 2 years ago
  const propsConfigs = {
    dateNavBtnProps: {
      colorScheme: "gray",
      padding: "2px",
      "aria-label": "date range navigation",
    },

    dayOfMonthBtnProps: {
      defaultBtnProps: {
        _hover: {
          background: "brand.600",
          color: "white",
        },
        borderRadius: "20px",
        padding: "2px",
        margin: "2px",
        size: "sm",
      },
      isInRangeBtnProps: {
        background: "brand.100",
        color: "black",
        size: "sm",
      },
      selectedBtnProps: {
        background: "brand.600",
        color: "white",
        size: "sm",
      },
      todayBtnProps: {
        background: "gray.100",
        color: "black",
        fontWeight: "bold",
        borderColor: "transparent",
        size: "sm",
      },
    },

    inputProps: {
      size: "sm",
      p: 5,
      fontWeight: "bold",
      bg: "brand.100",
      borderRadius: "5px",
      borderColor: "transparent",
      "aria-label": "Selected date range",
      role: "combobox",
    },
  };
  const configs = {
    dateFormat: "dd MMM yyyy",
    dayNames: "SMTWTFS".split(""), // length of 7
  };

  const onDateChanges = (dateRange: any) => {
    if (selectedDates != dateRange) {
      setSelectedDates(dateRange);
      if (dateRange.length !== 2) {
        setDateRangeChanged(false);
      } else {
        setDateRangeChanged(true);
      }
    }
  };

  const applySelectedDateRange = () => {
    if (validateDateRange(selectedDates)) {
      selectedDateRange(selectedDates);
      setDateRangeChanged(false);
    }
  };

  const validateDateRange = (dateRange: any) => {
    let minDate = dateRange[0];
    let maxDate = dateRange[1];
    maxDate.setHours(23);
    maxDate.setMinutes(59);
    maxDate.setSeconds(59);
    maxDate.setMilliseconds(999);
    if (dateRange?.length == 2) {
      if (checkIfNumberOfDaysisMoreThan30(dateRange)) {
        toast({
          title: "Choose a Selection Period of up to 30 days",
          variant: "top-accent",
          status: "warning",
          position: "top-right",
          duration: 4000,
          isClosable: true,
        });
        return false;
      }

      minDate = dateRange[0].toISOString();
      maxDate = dateRange[1].toISOString();
    } else if (dateRange?.length == 1) {
      minDate = dateRange[0].toISOString();
      maxDate = dateRange[0].toISOString();
    } else {
      toast({
        title: "Invalid Selection Period.",
        status: "error",
        position: "top-right",
        duration: 3000,
        isClosable: true,
      });
      return false;
    }

    return true;
  };

  const checkIfNumberOfDaysisMoreThan30 = (dateRange: any) => {
    const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
    const diffDays = Math.round(Math.abs((dateRange[0] - dateRange[1]) / oneDay));
    return diffDays > 30;
  };

  return (
    <>
      {isHorizontalStack ? (
        <HStack>
          <RangeDatepicker aria-label="Date Picker" configs={configs} selectedDates={selectedDates} onDateChange={onDateChanges} minDate={minDate} maxDate={new Date()} propsConfigs={propsConfigs} />
          <Button w={"100px"} variant={"outline"} colorScheme={"brand"} isDisabled={!isDateRangeChanged} onClick={applySelectedDateRange}>
            Apply
          </Button>
        </HStack>
      ) : (
        <VStack align={"left"}>
          <RangeDatepicker configs={configs} selectedDates={selectedDates} onDateChange={onDateChanges} minDate={minDate} maxDate={new Date()} propsConfigs={propsConfigs} />
          <Button w={"100px"} variant={"outline"} size={"xs"} colorScheme={"brand"} isDisabled={!isDateRangeChanged} onClick={applySelectedDateRange}>
            Apply
          </Button>
        </VStack>
      )}
    </>
  );
};
