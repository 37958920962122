/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getUser = /* GraphQL */ `
  query GetUser($id: String!) {
    getUser(id: $id) {
      email
      name
    }
  }
`;
export const getSiteSettings = /* GraphQL */ `
  query GetSiteSettings($siteId: String!) {
    getSiteSettings(siteId: $siteId) {
      widget {
        primaryColor
        starColor
        widgetLayout
        cardColor
        reviewDate
        reviewDateFormat
        reviewTitle
        showVerifyBadge
        hideIfNoReview
        reviewsPerPage
        allowSorting
        defaultSoring
        removeBrandzwayBranding
        writeReviewButton
        buttonsBorderRadius
      }
      email {
        canvasColor
        panelColor
        textColor
        productNameColor
        logoImageEnabled
        logoImageDetails {
          imagePath
          imageThumbailPath
          lastUpdated
        }
        bannerImageEnabled
        bannerImageDetails {
          imagePath
          imageThumbailPath
          lastUpdated
        }
        subject
        body
        reviewButtonType
        reviewButtonStarColor
        reviewButtonText
        reviewButtonTextColor
        reviewButtonBackgroundColor
        reviewButtonBorderRadius
        footerEnabled
        footerText
        socialMediaLinkEnabled
        socialMediaText
        fbLink
        tiktokLink
        instagramLink
        twitterLink
      }
      settings {
        autoSendReviewEmails
        emailDelay
        emailTriggerAt
        autoPublishReview
        starRatingForAutoPublishReview
        notifyOn1Star
        notifyOn2Star
        notifyOn3Star
        notifyOn4Star
        notifyOn5Star
        notifyOnNewReview
        replyToEmail
        siteName
      }
    }
  }
`;
export const getProductReviewsForDashboard = /* GraphQL */ `
  query GetProductReviewsForDashboard(
    $siteId: String!
    $minDate: String!
    $maxDate: String!
  ) {
    getProductReviewsForDashboard(
      siteId: $siteId
      minDate: $minDate
      maxDate: $maxDate
    ) {
      submittedAt
      siteId
      productReviewId
      replies {
        replyType
        author
        dateTime
        message
      }
      orderId
      isVerified
      customerEmail
      customerFullName
      title
      message
      starRating
      customQuestions
      publishStatus
      productId
      productName
      productReviewImages {
        name
        path
      }
    }
  }
`;
export const getOrders = /* GraphQL */ `
  query GetOrders($siteId: String!, $minDate: String!, $maxDate: String!) {
    getOrders(siteId: $siteId, minDate: $minDate, maxDate: $maxDate) {
      orderId
      customerFullName
      orderDateTime
      orderReviewStatus
      totalPrice
      orderStatus
    }
  }
`;
export const getAccountInformation = /* GraphQL */ `
  query GetAccountInformation {
    getAccountInformation {
      firstName
      lastName
      sites {
        siteName
        siteId
      }
      email
      currentSubscription {
        tier
        monthlyLimit
        cost
        resumeSubscription
        billingCycle
        subscriptionChange {
          scheduledDate
          nextTier
          monthlyLimit
          cost
        }
      }
      usage {
        monthlyStartDate
        monthlyEndDate
        used
        total
      }
    }
  }
`;
export const getProAnalytics = /* GraphQL */ `
  query GetProAnalytics($siteId: String!) {
    getProAnalytics(siteId: $siteId) {
      lastOneDay {
        selectedRangeData {
          ordersReceived
          revenue
          sentReviewRequests
          averageStarRating
          oneStarRating
          fiveStarRating
          calculatedAt
        }
        comparedRangeData {
          ordersReceived
          revenue
          sentReviewRequests
          averageStarRating
          oneStarRating
          fiveStarRating
          calculatedAt
        }
      }
      lastTwoDays {
        selectedRangeData {
          ordersReceived
          revenue
          sentReviewRequests
          averageStarRating
          oneStarRating
          fiveStarRating
          calculatedAt
        }
        comparedRangeData {
          ordersReceived
          revenue
          sentReviewRequests
          averageStarRating
          oneStarRating
          fiveStarRating
          calculatedAt
        }
      }
      lastSevenDays {
        selectedRangeData {
          ordersReceived
          revenue
          sentReviewRequests
          averageStarRating
          oneStarRating
          fiveStarRating
          calculatedAt
        }
        comparedRangeData {
          ordersReceived
          revenue
          sentReviewRequests
          averageStarRating
          oneStarRating
          fiveStarRating
          calculatedAt
        }
      }
      scheduledReviewRequests
      reviewsPendingForApproval
      dynamicCalculatedAt
    }
  }
`;
export const getCurrentPlanStatus = /* GraphQL */ `
  query GetCurrentPlanStatus($id: String!) {
    getCurrentPlanStatus(id: $id) {
      byPlanId
    }
  }
`;
