import { StarIcon } from "@chakra-ui/icons";
import { Popover, PopoverBody, PopoverContent, PopoverTrigger, Tooltip, VStack, Text, Button, Box, PopoverArrow } from "@chakra-ui/react";
import { useState } from "react";

export const PremiumFeature = ({ isPremiumUser }: { isPremiumUser: boolean }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      {!isPremiumUser && (
        <Popover isOpen={isOpen} placement="bottom">
          <PopoverTrigger>
            <StarIcon aria-label="Premium Feature" role="button" color={"orange"} padding={"4px"} borderRadius={"5px"} background={"#ffe7af"} fontSize={"20px"} onMouseEnter={() => setIsOpen(true)} onMouseLeave={() => setIsOpen(false)} />
          </PopoverTrigger>
          <PopoverContent bg={"brand.600"} border={"0px"} borderRadius={"xl"} boxShadow={"xl"} m={2}>
            <PopoverArrow bg={"brand.600"} />
            <PopoverBody>
              <VStack spacing={3} justifyContent={"center"} p={1.5}>
                <Text px={5} py={1} bg={"brand.700"} rounded={"full"} fontSize={"sm"} color={"white"}>
                  Premium Feature
                </Text>
                <Text fontSize={"sm"} color={"white"} textAlign={"center"}>
                  Upgrade to Growth plan or above to access this feature and many more!
                </Text>

                {/* <Button size={"sm"} rounded={"full"} boxShadow={"lg"} px={8}>
                Upgrade
              </Button> */}
              </VStack>
            </PopoverBody>
          </PopoverContent>
        </Popover>
      )}
    </>
  );
};
