const HOSTED_URL = "https://app-dev.brandzway.com";

const configStage = {
  HOSTED_URL,
  MODE: "STAGE",
  REDIRECT_SIGN_IN: `${HOSTED_URL}/`,
  REDIRECT_SIGN_OUT: `${HOSTED_URL}/signout/`,
  REGION: "us-east-1",
  AUTHENTICATION_TYPE: "AWS_IAM" as const,
  USER_POOL_CLIENT_ID: "5sajbfq2a0f6544kvfrk9pv5en",
  USER_POOL_ID: "us-east-1_1enRRjSPJ",
  IDENTITY_POOL_ID: "us-east-1:cd79477c-7f2e-4bc2-b39a-4dd6a8b52870",
  GRAPHQL_ENDPOINT: "https://gql-stage.brandzway.com/graphql",
  MEDIA_BASE_URL: "https://media-stage.brandzway.com",
  API_ENDPOINT: "https://api-stage.brandzway.com",
  PAYPAL_CLIENT_ID: "AYATdyPCtaKhT0y5kLS7EtOEyBorMbOmWnUG8ay9PDbB0lMzpRzzmBsx3TiXeBASv3FLxdAUfbKRlqvD",
};

export default configStage;
