import { Auth } from "aws-amplify";
import axios from "axios";
import { config } from "../deployment/config";

export const getOrderReviewDetails = async (siteId: string, orderId: string): Promise<any> => {
  let endpoint = `${config.API_ENDPOINT}/reviews/order-review-details`;
  endpoint += `?siteId=${siteId}&orderId=${orderId}`;

  try {
    const response = await axios.get(endpoint);
    return response?.data;
  } catch (error: any) {
    console.error(`Error getting order and product details: ${error.message}`);
    return null;
  }
};
