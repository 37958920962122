import React, { useCallback, useEffect, useState } from "react";
import { Box, Center, HStack, Icon, Image, Progress, Text, Tooltip, useToast } from "@chakra-ui/react";
import { useDropzone } from "react-dropzone";
import Compressor from "compressorjs";
import { dashboardUploadFile } from "../hooks/dashboard-upload-file";
import { BsImage } from "react-icons/bs";
import { reviewsUploadFile } from "../hooks/reviews-upload-file";
import { v4 as uuidv4 } from "uuid";

export interface FileUploaderSettings {
  maxSizeInMB: number;
  showMaxWidthRecommendation: boolean;
  toolTipMessage: string;
  uploadType: UploadType;
  assetType?: AssetType;
  siteId?: string;
  productId?: string;
  productReviewId?: string;
  isDisabled?: boolean;
}

export enum UploadType {
  dashboardUploadFile,
  reviewsUploadFile,
}
export enum AssetType {
  logo = "Logo",
  banner = "Banner",
}

export const FileUploader = ({ fileUploaderSettings, setFileName, callbackSuccess }: { fileUploaderSettings: FileUploaderSettings; setFileName?: any; callbackSuccess?: any }) => {
  const toast = useToast();
  const [previewImage, setPreviewImage] = useState(null);
  const [showMaxSize, setShowMaxSize] = useState(true);
  const [isFileUploadDisabled, setFileUploadDisabled] = useState(fileUploaderSettings.isDisabled == true);
  const [isFileUploadAllowed, setFileUploadAllowed] = useState(true);
  const [isHEICFormat, setIsHEICFormat] = useState(false);
  const [compressedFile, setCompressedFile] = useState(null);

  const onDrop = useCallback((acceptedFiles: any[]) => {
    if (acceptedFiles.length == 0) {
      return;
    } else if (acceptedFiles.length > 1) {
      toast({
        title: "Drag & drop only one file.",
        status: "error",
        position: "bottom",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    const file = acceptedFiles[0];
    if (file.type.toLowerCase() == "image/heic") {
      setIsHEICFormat(true);
    }
    const reader: any = new FileReader();
    reader.onload = () => {
      setPreviewImage(reader.result);
      setShowMaxSize(false);
    };
    reader.readAsDataURL(file);
    handleCompressedUpload(file);
  }, []);

  const onDropRejected = useCallback((fileRejections: any) => {
    if (fileRejections.length == 0) {
      return;
    }

    const file = fileRejections[0];
    if (file.errors?.[0].code == "file-too-large") {
      toast({
        title: `File size should not exceed ${fileUploaderSettings.maxSizeInMB} MB.`,
        status: "error",
        position: "bottom",
        duration: 5000,
        isClosable: true,
      });
    } else if (file.errors?.[0].code == "file-invalid-type") {
      toast({
        title: "Invalid file format.",
        status: "error",
        position: "bottom",
        duration: 5000,
        isClosable: true,
      });
    } else {
      toast({
        title: "Something went wrong while processing the file.",
        status: "error",
        position: "bottom",
        duration: 5000,
        isClosable: true,
      });
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    onDropRejected,
    accept: {
      "image/png": [],
      "image/jpeg": [],
      "image/jpg": [],
    },
    disabled: !isFileUploadAllowed || isFileUploadDisabled,
    maxSize: 1024 * 1024 * fileUploaderSettings.maxSizeInMB,
  });

  const handleCompressedUpload = async (imageFile: any) => {
    setFileUploadAllowed(false);
    new Compressor(imageFile, {
      quality: 0.6,
      maxWidth: 512,
      success: async (compressedResult) => {
        await uploadFile(compressedResult as File);
      },
      error: () => {
        setFileUploadAllowed(true);
      },
    });
  };

  const uploadFile = async (compressedResult: any) => {
    if (fileUploaderSettings.uploadType == UploadType.dashboardUploadFile) {
      const siteId = fileUploaderSettings.siteId;
      const assetType = fileUploaderSettings.assetType;
      if (!(siteId && assetType)) {
        toast({
          title: "Something went wrong.",
          status: "error",
          position: "bottom",
          duration: 5000,
          isClosable: true,
        });
        return;
      }
      await dashboardUploadFile(compressedResult as File, siteId, assetType)
        .then(() => {
          toast({
            title: `${fileUploaderSettings.assetType?.toString()} uploaded successfully.`,
            status: "success",
            position: "bottom",
            duration: 5000,
            isClosable: true,
          });
          callbackSuccess();
        })
        .catch((error) => {
          toast({
            title: `Failed to upload ${fileUploaderSettings.assetType?.toString()}`,
            status: "error",
            position: "bottom",
            duration: 5000,
            isClosable: true,
          });
        })
        .finally(() => {
          setFileUploadAllowed(true);
        });
    } else if (fileUploaderSettings.uploadType == UploadType.reviewsUploadFile) {
      const siteId = fileUploaderSettings.siteId;
      const productId = fileUploaderSettings.productId;
      const productReviewId = fileUploaderSettings.productReviewId;
      if (!(siteId && productId && productReviewId)) {
        toast({
          title: "Something went wrong.",
          status: "error",
          position: "bottom",
          duration: 5000,
          isClosable: true,
        });
        return;
      }
      const fileName = uuidv4() + getImageExtension(compressedResult.type.toLowerCase());
      setFileName(fileName);
      await reviewsUploadFile([{ data: compressedResult as File, name: fileName }], siteId, productId, productReviewId)
        .then(() => {
          toast({
            title: `Photo uploaded successfully.`,
            status: "success",
            position: "bottom",
            duration: 5000,
            isClosable: true,
          });
        })
        .catch((error) => {
          toast({
            title: `Failed to upload ${fileUploaderSettings.assetType?.toString()}`,
            status: "error",
            position: "bottom",
            duration: 5000,
            isClosable: true,
          });
        })
        .finally(() => {
          setFileUploadAllowed(true);
        });
    }
  };

  const getImageExtension = (imageType: string): string => {
    switch (imageType) {
      case "image/jpeg":
        return ".jpg";
      case "image/png":
        return ".png";
      case "image/gif":
        return ".gif";
      case "image/webp":
        return ".webp";
      case "image/heic":
        return ".heic";
      default:
        return "";
    }
  };

  return (
    <>
      <Tooltip isDisabled={!isFileUploadAllowed || isFileUploadDisabled} label={fileUploaderSettings.toolTipMessage ? fileUploaderSettings.toolTipMessage : ""} fontSize="md">
        <Box opacity={!isFileUploadAllowed || isFileUploadDisabled ? "0.3" : "1.0"} style={{ cursor: !isFileUploadAllowed || isFileUploadDisabled ? "no-drop" : "pointer" }} {...getRootProps()} borderWidth={2} borderColor="brand.200" borderStyle="dashed" p={4} rounded="md" bg={isDragActive ? "gray.100" : "#e5eafb52"} _hover={{ bg: "gray.100" }} _focus={{ outline: "none", boxShadow: "outline" }}>
          <input {...getInputProps()} />
          {previewImage ? (
            <Image src={previewImage} alt="Preview" maxH="200px" />
          ) : (
            <Box textAlign="center">
              {isDragActive ? (
                <Center h={"105px"}>
                  <Text as="b">Drop the image here ...</Text>
                </Center>
              ) : (
                <>
                  <Icon fontSize="72" as={BsImage} color={"brand.500"} bg={"brand.50"} p={3} borderRadius={"3xl"} />
                  <HStack justifyContent={"center"} spacing={1} mt={2}>
                    <Text fontSize={"sm"} fontWeight={"bold"} color={"gray.700"}>
                      Drag & Drop or
                    </Text>
                    <Text fontSize={"sm"} fontWeight={"bold"} color={"brand.500"}>
                      Choose image
                    </Text>
                    <Text fontSize={"sm"} color={"brand.500"}>
                      to upload
                    </Text>
                  </HStack>
                </>
              )}
            </Box>
          )}
          <Text fontSize={"xs"} textAlign={"center"} display={showMaxSize ? "block" : "none"} mt={0} color={"gray.600"}>
            Max size {fileUploaderSettings.maxSizeInMB} MB.
          </Text>
          <Box h={2} pt={2}>
            <Progress size="xs" colorScheme={"brand"} isIndeterminate display={isFileUploadAllowed ? "none" : "visible"} />
          </Box>
        </Box>
      </Tooltip>
    </>
  );
};
