import { gql, useLazyQuery } from "@apollo/client";
import { useState } from "react";
import { SubscriptionCurrentPlan } from "../../appsync/API";
import { getCurrentPlanStatus } from "../../appsync/graphql/queries";

export const useGetCurrentPlanStatus = () => {
  const [call, { loading, error, data }] = useLazyQuery<{ getCurrentPlanStatus: SubscriptionCurrentPlan }>(gql(getCurrentPlanStatus), {
    fetchPolicy: "no-cache",
  });

  return {
    getCurrentPlanStatus: call,
    getCurrentPlanStatusData: data?.getCurrentPlanStatus,
    getCurrentPlanStatusLoading: loading,
    getCurrentPlanStatusError: error,
  };
};
