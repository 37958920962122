import { CheckIcon, ChevronDownIcon, CopyIcon, InfoIcon } from "@chakra-ui/icons";
import { Box, Text, Button, Card, CardBody, CardFooter, CardHeader, Flex, Heading, Icon, Menu, MenuButton, MenuItem, MenuList, Popover, PopoverArrow, PopoverBody, PopoverContent, PopoverTrigger, Portal, Stat, StatArrow, StatHelpText, StatLabel, StatNumber, VStack, SimpleGrid, HStack, Center, Code, Spacer, useToast, Alert, AlertIcon, AlertTitle, AlertDescription } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import Navbar from "../../components/navbar";
import { FiFilter } from "react-icons/fi";
import { BsFillCartCheckFill, BsCheckCircleFill, BsFillStarFill } from "react-icons/bs";
import { BiMoney } from "react-icons/bi";
import { MdScheduleSend } from "react-icons/md";
import { CgSearchLoading } from "react-icons/cg";
import { RiStarSmileFill } from "react-icons/ri";
import { AccountInformationSite } from "../../types/account";
import { PlanLimitReached } from "../../components/plan-limit-reached";

export default function HowToInstall({ accountInformation, selectedSite, switchSelectedSite }: { accountInformation: any; selectedSite: AccountInformationSite; switchSelectedSite: any }) {
  useEffect(() => {}, []);
  return <Navbar tabName="HowToInstall" children={<MainContainer accountInformation={accountInformation} selectedSite={selectedSite}></MainContainer>} accountInformation={accountInformation} selectedSite={selectedSite} switchSelectedSite={switchSelectedSite}></Navbar>;
}

const MainContainer = ({ accountInformation, selectedSite }: { accountInformation: any; selectedSite: AccountInformationSite }) => {
  return (
    <>
      <PlanLimitReached isUsageLimitReached={accountInformation && accountInformation.usage.used >= accountInformation.usage.total}></PlanLimitReached>
      <Box minH="100vh" p={10} w="100%" justifyContent="center">
        <VStack spacing={20}>
          <Step1 selectedSite={selectedSite}></Step1>
          <Step2 selectedSite={selectedSite}></Step2>
          <Step3 selectedSite={selectedSite}></Step3>
          <Box w={"6xl"} justifyContent={"center"}>
            <Alert borderRadius={"xl"} status="success" variant="subtle" flexDirection="column" alignItems="center" justifyContent="center" textAlign="center" height="200px">
              <AlertIcon boxSize="60px" mr={"15px"} my={"10px"} />
              <AlertTitle mt={4} mb={1} fontSize="2xl">
                Done
              </AlertTitle>
              <AlertDescription maxWidth="lg" mb={"10px"}>
                Don't forget to publish your changes.
              </AlertDescription>
            </Alert>
          </Box>
          <Flex id="demo-video" justify={"center"} py={10} bg={"gray.100"}>
            <iframe
              width="800"
              height="450"
              src="https://www.youtube.com/embed/GOLStYSnBdg?si=2qjMuLGACk2odpT3"
              title="Demo Video"
              style={{ border: "none" }}
              allow="accelerometer; 
            autoplay; 
            clipboard-write; 
            encrypted-media; 
            gyroscope; 
            picture-in-picture; 
            web-share"
              allowFullScreen
            ></iframe>
          </Flex>
        </VStack>
      </Box>
    </>
  );
};

const Step1 = ({ selectedSite }: { selectedSite: AccountInformationSite }) => {
  const code = `<!-- Brandzway Core Scripts -->\n<script\n src="https://widgets.brandzway.com/core/core.js"\n data-site-id="${selectedSite.siteId}">\n</script>\n<!-- End Brandzway Core Scripts -->`;
  const [isCopied, setIsCopied] = useState<boolean>(false);

  const handleCopyClick = async () => {
    try {
      await navigator.clipboard.writeText(code);
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 2000); // reset after 2 seconds
    } catch (err) {
      console.error("Failed to copy text: ", err);
    }
  };

  return (
    <Flex w={"6xl"} px={10} py={"70px"} bg={"white"} borderRadius={"xl"}>
      <Center w="200px">
        <Text fontSize={54} bg={"brand.600"} color={"white"} rounded={"full"} w={"80px"} textAlign={"center"} h={"80px"}>
          1
        </Text>
      </Center>
      <VStack alignItems={"left"} width={"auto"} flex={"1"} spacing={10}>
        <VStack spacing={5} align={"left"}>
          <Text>Open your Workspace.</Text>
          <Box>
            <Text as="b">
              Go to Site Settings {" -> "} Custom Code {" -> "} Head Code
            </Text>
            <Text>Add the following code & save.</Text>
          </Box>
        </VStack>
        <VStack spacing={2}>
          <Flex w={"100%"}>
            <Spacer />
            <Button onClick={handleCopyClick} leftIcon={isCopied ? <CheckIcon /> : <CopyIcon />} colorScheme={isCopied ? "green" : "brand"} variant="solid" size={"sm"}>
              {isCopied ? "copied" : "copy"}
            </Button>
          </Flex>
          <Code w={"100%"} variant={"subtle"} children={code} whiteSpace="pre-wrap" p={5} bg={"gray.300"} borderRadius={5} />
        </VStack>
      </VStack>
    </Flex>
  );
};

const Step2 = ({ selectedSite }: { selectedSite: AccountInformationSite }) => {
  const code = `<!-- Brandzway Star Widget -->\n<script\n src="https://widgets.brandzway.com/reviews/stars.js"\n data-site-id="${selectedSite.siteId}"\n data-product-id="{{wf {&quot;path&quot;:&quot;slug&quot;,&quot;type&quot;:&quot;PlainText&quot;\} }}">\n</script>\n<!-- End Brandzway Star Widget -->`;

  const [isCopied, setIsCopied] = useState<boolean>(false);

  const handleCopyClick = async () => {
    try {
      await navigator.clipboard.writeText(code);
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 2000); // reset after 2 seconds
    } catch (err) {
      console.error("Failed to copy text: ", err);
    }
  };
  return (
    <Flex w={"6xl"} px={10} py={"70px"} bg={"white"} borderRadius={"xl"}>
      <Center w="200px">
        <Text fontSize={54} bg={"brand.600"} color={"white"} rounded={"full"} w={"80px"} textAlign={"center"} h={"80px"}>
          2
        </Text>
      </Center>
      <VStack alignItems={"left"} width={"auto"} flex={"1"} spacing={10}>
        <VStack spacing={5} align={"left"}>
          <Text>Open Webflow Designer.</Text>
          <Text as="b">To add the Star Widget in Webflow, add the following code using the 'Embed' element. It's usually placed under the product title.</Text>
        </VStack>
        <VStack spacing={2}>
          <Flex w={"100%"}>
            <Spacer />
            <Button onClick={handleCopyClick} leftIcon={isCopied ? <CheckIcon /> : <CopyIcon />} colorScheme={isCopied ? "green" : "brand"} variant="solid" size={"sm"}>
              {isCopied ? "copied" : "copy"}
            </Button>
          </Flex>
          <Code variant={"subtle"} children={code} whiteSpace="pre-wrap" p={5} bg={"gray.300"} borderRadius={5} />
        </VStack>
      </VStack>
    </Flex>
  );
};

const Step3 = ({ selectedSite }: { selectedSite: AccountInformationSite }) => {
  const code = `<!-- Brandzway Reviews Widget -->\n<script\n src="https://widgets.brandzway.com/reviews/reviews.js"\n data-site-id="${selectedSite.siteId}"\n data-product-id="{{wf {&quot;path&quot;:&quot;slug&quot;,&quot;type&quot;:&quot;PlainText&quot;\} }}">\n</script>\n<!-- End Brandzway Reviews Widget -->`;

  const [isCopied, setIsCopied] = useState<boolean>(false);

  const handleCopyClick = async () => {
    try {
      await navigator.clipboard.writeText(code);
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 2000); // reset after 2 seconds
    } catch (err) {
      console.error("Failed to copy text: ", err);
    }
  };
  return (
    <Flex w={"6xl"} px={10} py={"70px"} bg={"white"} borderRadius={"xl"}>
      <Center w="200px">
        <Text fontSize={54} bg={"brand.600"} color={"white"} rounded={"full"} w={"80px"} textAlign={"center"} h={"80px"}>
          3
        </Text>
      </Center>
      <VStack alignItems={"left"} width={"auto"} flex={"1"} spacing={10}>
        <VStack spacing={5} align={"left"}>
          <Text>Open Webflow Designer.</Text>
          <Text as="b">To add the Reviews Widget in Webflow, add the following code using the 'Embed' element. It's usually placed at the end of the product page.</Text>
        </VStack>
        <VStack spacing={2}>
          <Flex w={"100%"}>
            <Spacer />
            <Button onClick={handleCopyClick} leftIcon={isCopied ? <CheckIcon /> : <CopyIcon />} colorScheme={isCopied ? "green" : "brand"} variant="solid" size={"sm"}>
              {isCopied ? "copied" : "copy"}
            </Button>
          </Flex>
          <Code variant={"subtle"} children={code} whiteSpace="pre-wrap" p={5} bg={"gray.300"} borderRadius={5} />
        </VStack>
      </VStack>
    </Flex>
  );
};
