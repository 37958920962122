import { gql, useMutation } from "@apollo/client";
import { createSite } from "../appsync/graphql/mutations";

export const useCreateSite = () => {
  const [call, { loading, error, data }] = useMutation<{ createSite: any }>(gql(createSite), {
    fetchPolicy: "no-cache",
  });

  return {
    createSite: call,
    createSiteData: data?.createSite,
    createSiteLoading: loading,
    createSiteError: error,
  };
};
