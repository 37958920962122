import { Alert, AlertIcon, AlertTitle, Center, FormErrorMessage, Heading } from "@chakra-ui/react";
import { Button, FormControl, Flex, Input, Stack, useColorModeValue, HStack } from "@chakra-ui/react";
import { Auth } from "aws-amplify";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PulseLoader } from "react-spinners";

export default function UserVerification(): JSX.Element {
  const [failedVerifyErrorMessage, setFailedVerifyErrorMessage] = useState("");
  const [code, setCode] = useState("");
  const username = localStorage.getItem("username") || "";
  const [seconds, setSeconds] = useState(0);
  const [isVerifyingCode, setVerifyingCode] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        clearInterval(interval);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds]);

  async function verifyEmailValidationCode(code: string) {
    setVerifyingCode(true);
    await Auth.confirmSignUp(username, code)
      .then(() => {
        localStorage.removeItem("username");
      })
      .catch((e) => {
        handleVerifyEmailErrorException(e);
        setVerifyingCode(false);
      })
      .finally(() => {
        //setVerifyingCode(false);
        localStorage.setItem("ftsNotDone", "true");
      });
  }

  const handleVerifyEmailErrorException = (error: any) => {
    if (error?.code == "CodeMismatchException") {
      setFailedVerifyErrorMessage("Code did'nt match.");
    } else if (error?.code == "ExpiredCodeException") {
      setFailedVerifyErrorMessage("Code Expired.");
    } else if (error?.code == "TooManyFailedAttemptsException") {
      setFailedVerifyErrorMessage("Too many attempts.");
    } else if (error?.code == "NotAuthorizedException") {
      navigate("/login", { replace: true });
    } else {
      setFailedVerifyErrorMessage("Something went wrong.");
    }
    //Log
  };

  const setVerifyCode = (event: any) => {
    setCode(event.target.value);
    setFailedVerifyErrorMessage("");
  };

  async function resendConfirmationCode() {
    try {
      setSeconds(15);
      await Auth.resendSignUp(username);
      console.log("code resent successfully");
    } catch (err) {
      handleResendConfirmationCodeErrorException(err);
    }
  }

  const handleResendConfirmationCodeErrorException = (error: any) => {
    if (error?.code == "CodeDeliveryFailureException") {
      setFailedVerifyErrorMessage("Code failed to deliver.");
    } else if (error?.code == "TooManyFailedAttemptsException") {
      setFailedVerifyErrorMessage("Too many attempts.");
    } else {
      setFailedVerifyErrorMessage("Something went wrong.");
    }
    //Log
  };

  let resendCodeTimer = seconds > 0 && "( in " + seconds + " seconds)";

  return (
    <Flex minH={"100vh"} align={"center"} justify={"center"} bg={useColorModeValue("gray.100", "gray.800")}>
      <Stack spacing={4} w={"full"} maxW={"sm"} bg={useColorModeValue("white", "gray.700")} rounded={"xl"} boxShadow={"lg"} p={6} my={10}>
        <Center>
          <Heading lineHeight={2} fontSize={{ base: "4xl", md: "5xl" }}>
            Almost there,
          </Heading>
        </Center>
        <Center>
          <Heading lineHeight={1.1} fontSize={{ base: "2xl", md: "3xl" }}>
            Verify your Email
          </Heading>
        </Center>
        <Center fontSize={{ base: "sm", sm: "md" }} color={useColorModeValue("gray.800", "gray.400")}>
          We have sent code to your email
        </Center>
        <Center fontSize={{ base: "md", sm: "md" }} fontWeight="bold" color={useColorModeValue("gray.800", "gray.400")}>
          {username}
        </Center>
        <FormControl pt={15} pb={5} isInvalid={true}>
          <Center>
            <HStack>
              <Input variant="flushed" placeholder="Enter OTP" _placeholder={{ textAlign: "center", letterSpacing: 1, fontSize: "1.2rem" }} fontSize={"1.5rem"} letterSpacing={5} textAlign={"center"} type={"number"} onChange={setVerifyCode} />
            </HStack>
          </Center>
          <Center>
            {failedVerifyErrorMessage != "" && (
              <Alert status="error" bg="white" textAlign={"center"} justifyContent={"center"}>
                <AlertIcon />
                <AlertTitle>{failedVerifyErrorMessage}</AlertTitle>
              </Alert>
            )}
          </Center>
        </FormControl>
        <Stack spacing={6}>
          <Button
            onClick={() => verifyEmailValidationCode(code)}
            isLoading={isVerifyingCode}
            spinner={<PulseLoader size={10} color="white" />}
            bg={"brand.500"}
            color={"white"}
            _hover={{
              bg: "brand.600",
            }}
          >
            Verify
          </Button>
          <Button color={seconds > 0 ? "brand.1000" : "brand.600"} variant="link" isDisabled={seconds > 0} fontSize={"0.9rem"} onClick={() => resendConfirmationCode()}>
            Resend Code {resendCodeTimer}
          </Button>
        </Stack>
      </Stack>
    </Flex>
  );
}
