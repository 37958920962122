import { Box, Center, useColorModeValue, Heading, Text, Stack, Image, Input, VStack, Textarea, RadioGroup, Radio, Button, Progress, AlertIcon, Alert, AlertTitle, AlertDescription, VisuallyHidden, HStack, Spinner, Flex } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useRef, useState } from "react";
import { PulseLoader } from "react-spinners";
import { FileUploader, FileUploaderSettings, UploadType } from "../../components/file-uploader";
import { getOrderReviewDetails } from "../../hooks/get-order-review-details";
import { submitReview } from "../../hooks/submit-review";
import { ProductReviewSubmissionDetails } from "../../types/submit-product-review";

const IMAGE = "https://images.unsplash.com/photo-1518051870910-a46e30d9db16?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=1350&q=80";

export default function SubmitReview() {
  let [isCalled, setIsCalled] = useState(false);
  const [orderAndProductDetails, setOrderAndProductDetails] = useState<any>("");
  const makeOrderAndProductReviewDetailsCall = async () => {
    const { pathname, search } = window.location;
    const searchParams = new URLSearchParams(search);
    const siteId = searchParams.get("siteId");
    const orderId = searchParams.get("orderId");
    if (siteId && orderId) {
      const response = await getOrderReviewDetails(siteId, orderId);
      setOrderAndProductDetails(response);
    } else {
      setOrderAndProductDetails({ orderExist: false });
    }
  };

  useEffect(() => {
    document.title = "Submit Review";
  }, []);

  useEffect(() => {
    if (!isCalled) {
      isCalled = true;
      setIsCalled(true);
      makeOrderAndProductReviewDetailsCall();
    }
  }, [orderAndProductDetails]);
  return <MainContainer orderAndProductDetails={orderAndProductDetails}></MainContainer>;
}

const MainContainer = ({ orderAndProductDetails }: { orderAndProductDetails: any }) => {
  const focusRef = React.useRef<HTMLInputElement>(null);
  const productReviewDetails = orderAndProductDetails?.productReviewDetails;
  const isPhotosAllowed = orderAndProductDetails?.isPhotosAllowed;
  const [currentReviewIndex, setCurrentReviewIndex] = useState(0);
  const [progress, setProgress] = useState(2);
  const [showRequiredFieldWarning, setShowRequiredFieldWarning] = useState(false);

  const populateNextReview = () => {
    setShowRequiredFieldWarning(false);
    setCurrentReviewIndex(currentReviewIndex + 1);
    setProgress(((currentReviewIndex + 1) / productReviewDetails.length) * 100);
    focusRef?.current?.focus();
  };

  useEffect(() => {}, [progress]);

  const submitAndShowNextReview = (reviewResult: any, idx: number) => {
    if (reviewResult.starRating < 1 || reviewResult.starRating > 5) {
      // show error
      setShowRequiredFieldWarning(true);
      focusRef?.current?.focus();
    } else {
      let finalReview: ProductReviewSubmissionDetails = {
        siteId: orderAndProductDetails.siteId,
        productId: orderAndProductDetails?.productReviewDetails?.[idx].productId,
        starRating: reviewResult.starRating,
        customerFullName: orderAndProductDetails.customerFullName,
        customerEmail: orderAndProductDetails.customerEmail,
        orderId: orderAndProductDetails.orderId,
        orderSubmissionTime: orderAndProductDetails.orderSubmissionTime,
        productReviewId: orderAndProductDetails?.productReviewDetails?.[idx].productReviewId,
      };
      if (reviewResult.title != "") {
        finalReview.title = reviewResult.title;
      }
      if (reviewResult.review != "") {
        finalReview.message = reviewResult.review;
      }
      if (reviewResult.photoName != "") {
        finalReview.photosAttached = [reviewResult.photoName];
      }
      submitReview(finalReview);
      populateNextReview();
    }
  };
  return (
    <>
      {orderAndProductDetails == "" && (
        <Flex minH={"100vh"} align={"center"} justify={"center"}>
          <VStack spacing={2}>
            <Spinner thickness="4px" speed="0.5s" emptyColor="gray.200" color="brand.500" size="lg" />
            <Text>Getting order details</Text>
          </VStack>
        </Flex>
      )}
      {orderAndProductDetails?.orderExist == false && <OrderDetailsNotFound />}
      {orderAndProductDetails?.productReviewDetails?.length == 0 && <ReviewAlreadySubmitted />}
      {orderAndProductDetails?.productReviewDetails?.length > 0 && (
        <Flex minH={"100vh"} align={"center"} justify={"center"}>
          <Box role={"group"} py={3} px={6} maxW={"800px"} w={"full"} bg={"white"} boxShadow={"2xl"} rounded={"lg"} pos={"relative"} alignItems={"center"} alignContent={"center"} zIndex={1}>
            {currentReviewIndex >= productReviewDetails.length ? (
              <ThanksForSubmittingReview />
            ) : (
              <>
                <VisuallyHidden>
                  <Input ref={focusRef} isReadOnly />
                </VisuallyHidden>
                {productReviewDetails.length >= 2 ? <Progress hasStripe value={progress} mb="5%" mx="5%" isAnimated colorScheme={"brand"} size={"md"} rounded={"full"} h={"10px"}></Progress> : ""}
                {productReviewDetails.map((productReviewDetail: any, idx: any) => {
                  if (currentReviewIndex == idx) {
                    return <SingleProductReview isPhotosAllowed={isPhotosAllowed} productReviewIndex={idx} siteId={orderAndProductDetails.siteId} productReviewDetail={productReviewDetail} submitAndShowNextReview={(reviewResult: any) => submitAndShowNextReview(reviewResult, idx)} showRequiredFieldWarning={showRequiredFieldWarning}></SingleProductReview>;
                  }
                })}
              </>
            )}
          </Box>
        </Flex>
      )}
      {orderAndProductDetails == null && <SomethingWentWrong />}
    </>
  );
};

const SingleProductReview = ({ isPhotosAllowed, productReviewIndex, siteId, productReviewDetail, submitAndShowNextReview, showRequiredFieldWarning }: { isPhotosAllowed: boolean; productReviewIndex: number; siteId: string; productReviewDetail: any; submitAndShowNextReview: any; showRequiredFieldWarning: boolean }) => {
  const [reviewResult, setReviewResult] = useState({
    title: "",
    review: "",
    starRating: 0,
    photoName: "",
  });

  const [photoName, setPhotoName] = useState("");

  const prepareAndSubmitReview = () => {
    const newReviewResult = { ...reviewResult, photoName: photoName };
    setReviewResult(newReviewResult);
    submitAndShowNextReview(newReviewResult);
  };

  const fileUploaderSettings: FileUploaderSettings = {
    maxSizeInMB: 6,
    showMaxWidthRecommendation: false,
    toolTipMessage: "",
    uploadType: UploadType.reviewsUploadFile,
    siteId,
    productId: productReviewDetail.productId,
    productReviewId: productReviewDetail.productReviewId,
  };

  return (
    <>
      <Box rounded={"lg"} pos={"relative"} height={"150px"}>
        <Center>
          <Image rounded={"lg"} height={150} width={150} objectFit={"cover"} src={productReviewDetail.productImageUrl} />
        </Center>
      </Box>
      <VStack pt={5} align={"center"} spacing={7}>
        <Heading color={"gray.500"} fontSize={"sm"} textTransform={"uppercase"} fontWeight={500}>
          {productReviewDetail.productName}
        </Heading>
        <OverallRating
          showRequiredFieldWarning={showRequiredFieldWarning}
          selectStarRatings={(val: number) => {
            const newReviewResult = { ...reviewResult, starRating: val };
            setReviewResult(newReviewResult);
          }}
        />
        <QuestionAndText
          question="Review title"
          setValue={(val: string) => {
            const newReviewResult = { ...reviewResult, title: val };
            setReviewResult(newReviewResult);
          }}
        />
        {isPhotosAllowed && (
          <Box alignContent={"left"} width={"full"}>
            <Text fontSize={"sm"} fontFamily={"body"} fontWeight={800} ml={2} mb={2}>
              Add Photo
            </Text>
            <FileUploader
              fileUploaderSettings={fileUploaderSettings}
              setFileName={(val: string) => {
                setPhotoName(val);
              }}
            ></FileUploader>
          </Box>
        )}
        <QuestionAndTextArea
          question="Product Review"
          setValue={(val: string) => {
            const newReviewResult = { ...reviewResult, review: val };
            setReviewResult(newReviewResult);
          }}
        />
        <Button isLoading={false} px={8} py={6} colorScheme="brand" rounded={"3xl"} onClick={() => prepareAndSubmitReview()} spinner={<PulseLoader size={10} color="white" />}>
          Submit Review
        </Button>
      </VStack>
    </>
  );
};

const SomethingWentWrong = () => {
  return (
    <Flex minH={"100vh"} align={"center"} justify={"center"}>
      <Box role={"group"} py={3} px={6} maxW={"800px"} w={"full"} bg={"white"} boxShadow={"2xl"} rounded={"lg"} pos={"relative"} alignItems={"center"} alignContent={"center"} zIndex={1}>
        <Alert status="error" variant="subtle" flexDirection="column" alignItems="center" justifyContent="center" textAlign="center" height="200px">
          <AlertIcon boxSize="40px" mr={0} />
          <AlertTitle mt={4} mb={3} fontSize="xl">
            Something went wrong, Please try again after sometime
          </AlertTitle>
        </Alert>
      </Box>
    </Flex>
  );
};

const OrderDetailsNotFound = () => {
  return (
    <Flex minH={"100vh"} align={"center"} justify={"center"}>
      <Box role={"group"} py={3} px={6} maxW={"800px"} w={"full"} bg={"white"} boxShadow={"2xl"} rounded={"lg"} pos={"relative"} alignItems={"center"} alignContent={"center"} zIndex={1}>
        <Alert status="error" variant="subtle" flexDirection="column" alignItems="center" justifyContent="center" textAlign="center" height="200px">
          <AlertIcon boxSize="40px" mr={0} />
          <AlertTitle mt={4} mb={3} fontSize="xl">
            Order details not found.
          </AlertTitle>
        </Alert>
      </Box>
    </Flex>
  );
};

const ReviewAlreadySubmitted = () => {
  return (
    <Flex minH={"100vh"} align={"center"} justify={"center"}>
      <Box role={"group"} py={3} px={6} maxW={"800px"} w={"full"} bg={"white"} boxShadow={"2xl"} rounded={"lg"} pos={"relative"} alignItems={"center"} alignContent={"center"} zIndex={1}>
        <Alert status="success" variant="subtle" flexDirection="column" alignItems="center" justifyContent="center" textAlign="center" height="200px">
          <AlertIcon boxSize="40px" mr={0} />
          <AlertTitle mt={4} mb={3} fontSize="xl">
            This review has been submitted already.
          </AlertTitle>
        </Alert>
      </Box>
    </Flex>
  );
};

const ThanksForSubmittingReview = () => {
  return (
    <Alert status="success" variant="subtle" flexDirection="column" alignItems="center" justifyContent="center" textAlign="center" height="200px">
      <AlertIcon boxSize="40px" mr={0} />
      <AlertTitle mt={4} mb={3} fontSize="2xl">
        Thank You!
      </AlertTitle>
      <AlertDescription maxWidth="sm">Your review was submitted.</AlertDescription>
    </Alert>
  );
};

const OverallRating = ({ selectStarRatings, showRequiredFieldWarning }: { selectStarRatings: any; showRequiredFieldWarning: boolean }) => {
  const [starRatingSelected, setStarRatingSelected] = useState(0);
  const [starsIsFilled, setStarsIsFilled] = useState([false, false, false, false, false]);
  const [currentStarHoverIndex, setCurrentStarHoverIndex] = useState(-1);
  const updateStarColorOnHover = (currentStarIndex: number) => {
    const newStarsIsFilled = [false, false, false, false, false];
    for (let idx = 0; idx <= currentStarIndex; idx++) {
      newStarsIsFilled[idx] = true;
    }
    setStarsIsFilled(newStarsIsFilled);
  };

  const setStarRatingOnClick = (currentStarIndex: number) => {
    setStarRatingSelected(currentStarIndex + 1);
    selectStarRatings(currentStarIndex + 1);
  };

  const setStarRatingsOnBlur = () => {
    updateStarColorOnHover(starRatingSelected - 1);
  };

  return (
    <Box onMouseLeave={() => setStarRatingsOnBlur()}>
      <VStack>
        <HStack>
          <Text fontSize={"xl"} fontFamily={"body"} fontWeight={800}>
            Overall Rating
          </Text>
          <Text color={"red"}>*</Text>
        </HStack>
        <Text fontSize="sm" color={"red"} display={showRequiredFieldWarning ? "" : "none"}>
          Required field
        </Text>
        <HStack>
          <Star isFilled={starsIsFilled[0]} onHoverOnStar={() => updateStarColorOnHover(0)} onClickedOnStar={() => setStarRatingOnClick(0)}></Star>
          <Star isFilled={starsIsFilled[1]} onHoverOnStar={() => updateStarColorOnHover(1)} onClickedOnStar={() => setStarRatingOnClick(1)}></Star>
          <Star isFilled={starsIsFilled[2]} onHoverOnStar={() => updateStarColorOnHover(2)} onClickedOnStar={() => setStarRatingOnClick(2)}></Star>
          <Star isFilled={starsIsFilled[3]} onHoverOnStar={() => updateStarColorOnHover(3)} onClickedOnStar={() => setStarRatingOnClick(3)}></Star>
          <Star isFilled={starsIsFilled[4]} onHoverOnStar={() => updateStarColorOnHover(4)} onClickedOnStar={() => setStarRatingOnClick(4)}></Star>
        </HStack>
      </VStack>
    </Box>
  );
};

const Star = ({ isFilled, onHoverOnStar, onClickedOnStar }: { isFilled: boolean; onHoverOnStar: any; onClickedOnStar: any }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 24 24" onMouseEnter={() => onHoverOnStar()} onClick={() => onClickedOnStar()} cursor={"pointer"}>
      <path
        fill="transparent"
        stroke="#f5cb16"
        d="M15.9199,11.8203 C15.6599,12.0703 15.5399,12.4393 15.6009,12.7903 L16.4899,17.7103 C16.5609,18.1303 16.3909,18.5493 16.0399,18.7903 C15.6999,19.0403 15.2499,19.0703 14.8699,18.8703 L10.4409,16.5603 C10.2799,16.4803 10.1099,16.4293 9.9409,16.4293 L9.6699,16.4293 C9.5699,16.4393 9.4809,16.4803 9.3999,16.5193 L4.9699,18.8403 C4.7499,18.9503 4.4999,18.9903 4.2599,18.9503 C3.6599,18.8303 3.2709,18.2693 3.3699,17.6793 L4.2599,12.7593 C4.3199,12.4003 4.1999,12.0403 3.9409,11.7803 L0.3299,8.2803 C0.0299,7.9803 -0.0801,7.5493 0.0609,7.1503 C0.1909,6.7593 0.5299,6.4693 0.9499,6.4003 L5.9199,5.6793 C6.2999,5.6393 6.6299,5.4103 6.7999,5.0703 L8.9899,0.5803 C9.0399,0.4803 9.1099,0.3893 9.1909,0.3103 L9.2799,0.2403 C9.3199,0.1893 9.3799,0.1503 9.4409,0.1103 L9.5499,0.0703 L9.7199,0.0003 L10.1409,0.0003 C10.5209,0.0403 10.8509,0.2693 11.0209,0.5993 L13.2399,5.0703 C13.3999,5.4003 13.7099,5.6203 14.0699,5.6793 L19.0399,6.4003 C19.4599,6.4603 19.8109,6.7503 19.9499,7.1503 C20.0799,7.5493 19.9699,7.9903 19.6599,8.2803 L15.9199,11.8203 Z"
        transform="translate(2 2.5)"
      />
      {isFilled ? (
        <path
          fill="#f5cb16"
          d="M15.9199,11.8203 C15.6599,12.0703 15.5399,12.4393 15.6009,12.7903 L16.4899,17.7103 C16.5609,18.1303 16.3909,18.5493 16.0399,18.7903 C15.6999,19.0403 15.2499,19.0703 14.8699,18.8703 L10.4409,16.5603 C10.2799,16.4803 10.1099,16.4293 9.9409,16.4293 L9.6699,16.4293 C9.5699,16.4393 9.4809,16.4803 9.3999,16.5193 L4.9699,18.8403 C4.7499,18.9503 4.4999,18.9903 4.2599,18.9503 C3.6599,18.8303 3.2709,18.2693 3.3699,17.6793 L4.2599,12.7593 C4.3199,12.4003 4.1999,12.0403 3.9409,11.7803 L0.3299,8.2803 C0.0299,7.9803 -0.0801,7.5493 0.0609,7.1503 C0.1909,6.7593 0.5299,6.4693 0.9499,6.4003 L5.9199,5.6793 C6.2999,5.6393 6.6299,5.4103 6.7999,5.0703 L8.9899,0.5803 C9.0399,0.4803 9.1099,0.3893 9.1909,0.3103 L9.2799,0.2403 C9.3199,0.1893 9.3799,0.1503 9.4409,0.1103 L9.5499,0.0703 L9.7199,0.0003 L10.1409,0.0003 C10.5209,0.0403 10.8509,0.2693 11.0209,0.5993 L13.2399,5.0703 C13.3999,5.4003 13.7099,5.6203 14.0699,5.6793 L19.0399,6.4003 C19.4599,6.4603 19.8109,6.7503 19.9499,7.1503 C20.0799,7.5493 19.9699,7.9903 19.6599,8.2803 L15.9199,11.8203 Z"
          transform="translate(2 2.5)"
        />
      ) : (
        ""
      )}
    </svg>
  );
};

const QuestionAndText = ({ question, setValue }: { question: any; setValue: any }) => {
  const [textValue, setTextValue] = useState("");
  return (
    <Box alignContent={"left"} width={"full"}>
      <Text fontSize={"sm"} fontFamily={"body"} fontWeight={800} ml={2} mb={2}>
        {question}
      </Text>
      <Input
        type={"text"}
        size={"md"}
        placeholder={"Enter the title for review"}
        color={"gray.800"}
        rounded={"md"}
        borderColor={useColorModeValue("gray.300", "gray.300")}
        px={"5"}
        value={textValue}
        onChange={(event) => {
          setTextValue(event?.target.value);
          setValue(event?.target.value);
        }}
      />
    </Box>
  );
};

const QuestionAndTextArea = ({ question, setValue }: { question: any; setValue: any }) => {
  const [textValue, setTextValue] = useState("");
  return (
    <Box alignContent={"left"} width={"full"}>
      <Text fontSize={"sm"} fontFamily={"body"} fontWeight={800} ml={2} mb={2}>
        {question}
      </Text>
      <Textarea
        size={"xl"}
        minH={"150"}
        placeholder={"Enter the message for review"}
        color={"gray.800"}
        rounded={"md"}
        borderColor={useColorModeValue("gray.300", "gray.300")}
        px={"5"}
        resize={"block"}
        value={textValue}
        onChange={(event) => {
          setTextValue(event?.target.value);
          setValue(event?.target.value);
        }}
      />
    </Box>
  );
};

const QuestionAndRadioButton = (props: any) => {
  return (
    <Box alignContent={"left"} width={"full"}>
      <Text fontSize={"sm"} fontFamily={"body"} fontWeight={800} ml={2} mb={2}>
        {props.question}
      </Text>
      <RadioGroup value={"1"} ml={2} colorScheme={"brand"}>
        {/* //onChange={} */}
        <Stack direction="column">
          {props.options?.map((optionItem: String, idx: number) => (
            <Radio value={idx + ""}>{optionItem}</Radio>
          ))}
        </Stack>
      </RadioGroup>
    </Box>
  );
};
