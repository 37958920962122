const HOSTED_URL = "https://app.brandzway.com";

const configProd = {
  HOSTED_URL,
  MODE: "PROD",
  REDIRECT_SIGN_IN: `${HOSTED_URL}/`,
  REDIRECT_SIGN_OUT: `${HOSTED_URL}/signout/`,
  REGION: "us-east-1",
  AUTHENTICATION_TYPE: "AWS_IAM" as const,
  USER_POOL_CLIENT_ID: "64i714aidtmbvtcrpbr70kvbqu",
  USER_POOL_ID: "us-east-1_t029viwOR",
  IDENTITY_POOL_ID: "us-east-1:5e969872-e5a2-4a0a-bccb-c412f7440f06",
  GRAPHQL_ENDPOINT: "https://gql.brandzway.com/graphql",
  MEDIA_BASE_URL: "https://media.brandzway.com",
  API_ENDPOINT: "https://api.brandzway.com",
  PAYPAL_CLIENT_ID: "AdDaSDssTA4uoJVuyEVdgoySON16BREHf8rOwbqqGNO_x99li5enCebEIJCroxNCIR3AKNCvYDqZ29-F",
};

export default configProd;
