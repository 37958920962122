import axios from "axios";
import { forEach } from "lodash";
import { config } from "../deployment/config";

export interface FileDataAndName {
  data: File;
  name: string;
}

export const reviewsUploadFile = async (files: Array<FileDataAndName>, siteId: string, productId: string, productReviewId: string): Promise<void> => {
  const endpoint = `${config.API_ENDPOINT}/reviews/upload-file?siteId=${siteId}&productId=${productId}&productReviewId=${productReviewId}`;

  const formData = new FormData();
  forEach(files, (file, index) => {
    formData.append(`file-${index}`, file.data, file.name);
  });

  try {
    const response = await axios.post(endpoint, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    console.log(`File uploaded successfully with response status: ${response.status}`);
  } catch (error: any) {
    console.error(`Error uploading file: ${error.message}`);
    throw error;
  }
};
