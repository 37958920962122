import { gql, useMutation } from "@apollo/client";
import { firstTimeSetup } from "../appsync/graphql/mutations";

export const useFirstTimeSetup = () => {
  const [call, { loading, error, data }] = useMutation<{ success: String }>(gql(firstTimeSetup), {
    fetchPolicy: "no-cache",
  });

  return {
    firstTimeSetup: call,
    firstTimeSetupData: data?.success,
    firstTimeSetupLoading: loading,
    firstTimeSetupError: error,
  };
};
