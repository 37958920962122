import { Flex, Box, FormControl, FormLabel, Input, InputGroup, HStack, InputRightElement, Stack, Button, Heading, Text, useColorModeValue, Link, Checkbox, useToast, Alert, AlertIcon, AlertTitle, AlertDescription } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import { useForm } from "react-hook-form";
import { Hub, Auth } from "aws-amplify";
import { useLocation, useNavigate } from "react-router-dom";
import { BeatLoader, PulseLoader } from "react-spinners";

export default function Login({ fetchAccountInformation }: { fetchAccountInformation: any }) {
  const [showPassword, setShowPassword] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [isLogining, setLogining] = useState(false);
  const navigate = useNavigate();
  const toast = useToast();
  const location = useLocation();

  useEffect(() => {
    checkIfUserLoggedIn();
  }, []);

  const checkIfUserLoggedIn = () => {
    Auth.currentAuthenticatedUser({
      bypassCache: false, // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    })
      .then(() => {
        navigate("/orders", { replace: true });
      })
      .catch((error) => {
        // Log
      });
  };

  function login(values: any) {
    setLogining(true);
    signIn(values["email"], values["password"]);
  }

  async function signIn(username: string, password: string) {
    const user = await Auth.signIn(username, password).catch((error: any) => {
      handlesignInCodeErrorException(username, error);
    });
    if (user) {
      fetchAccountInformation();
      navigate("/orders", { replace: true });
    }
  }

  const handlesignInCodeErrorException = (username: string, error: any) => {
    if (error.code == "UserNotConfirmedException") {
      localStorage.setItem("username", username);
      navigate("/verify", { replace: true });
    } else if (error.code == "NotAuthorizedException") {
      toast({
        title: "Incorrect email or password",
        status: "error",
        position: "top-left",
        duration: 6000,
        isClosable: true,
      });
      setLogining(false);
    } else if (error.code == "UserNotFoundException") {
      toast({
        title: "Email is not registered.",
        status: "error",
        position: "top-left",
        duration: 6000,
        isClosable: true,
      });
      setLogining(false);
    } else {
      toast({
        title: "Something went wrong.",
        status: "error",
        position: "top-left",
        duration: 4000,
        isClosable: true,
      });
      setLogining(false);
    }
    //LOG
  };

  return (
    <Stack minH={"100vh"} direction={{ base: "column", md: "row" }} bg={useColorModeValue("gray.50", "gray.800")}>
      <Flex p={8} flex={1} align={"center"} justify={"center"}>
        <Stack spacing={8} mx={"auto"} width={["lg"]} maxW={"lg"} py={12} px={6}>
          <Stack align={"center"}>
            {location?.state?.stateChangeReason == "password-reset" ? (
              <Alert status="success" variant="subtle" borderRadius={"xl"} flexDirection="column" alignItems="center" justifyContent="center" textAlign="center" py={4}>
                <AlertIcon boxSize="40px" mr={0} />
                <AlertTitle mt={2} mb={1} fontSize="lg">
                  Password reset successful
                </AlertTitle>
                <AlertDescription maxWidth="sm">Please login with new details</AlertDescription>
              </Alert>
            ) : (
              <>
                <Heading fontSize={"4xl"} textAlign={"center"}>
                  Hi, Welcome Back 👋
                </Heading>
                <Text fontSize={"lg"} color={"gray.600"}>
                  Please enter your details.
                </Text>
              </>
            )}
          </Stack>
          <Box rounded={"lg"} bg={useColorModeValue("white", "gray.700")} boxShadow={"lg"} p={8}>
            <Stack spacing={4}>
              <form onSubmit={handleSubmit(login)}>
                <FormControl id="email" isRequired isDisabled={isLogining}>
                  <FormLabel>Email address</FormLabel>
                  <Input
                    type="email"
                    {...register("email", {
                      required: "Please enter your email.",
                      minLength: { value: 2, message: "Invalid email address." },
                    })}
                  />
                </FormControl>
                <FormControl id="password" isRequired isDisabled={isLogining} mt={4}>
                  <FormLabel>Password</FormLabel>
                  <InputGroup>
                    <Input
                      type={showPassword ? "text" : "password"}
                      {...register("password", {
                        required: "Please enter the password.",
                      })}
                    />
                    <InputRightElement h={"full"}>
                      <Button variant={"ghost"} onClick={() => setShowPassword((showPassword) => !showPassword)}>
                        {showPassword ? <ViewIcon /> : <ViewOffIcon />}
                      </Button>
                    </InputRightElement>
                  </InputGroup>
                </FormControl>
                <Stack spacing={10} pt={2}>
                  <Stack direction={{ base: "column", sm: "row" }} justify={"right"}>
                    <Link color={"brand.600"} onClick={() => navigate("/forgot-password", { replace: true })}>
                      Forgot password?
                    </Link>
                  </Stack>
                  <Button
                    type={"submit"}
                    isLoading={isLogining}
                    spinner={<PulseLoader size={10} color="white" />}
                    loadingText=""
                    size="lg"
                    bg={"brand.500"}
                    color={"white"}
                    _hover={{
                      bg: "brand.600",
                    }}
                  >
                    Log in
                  </Button>
                </Stack>
              </form>
              <Stack pt={6}>
                <Text align={"center"}>
                  Don't have an account?{" "}
                  <Link color={"brand.600"} onClick={() => navigate("/signup", { replace: true })}>
                    Signup
                  </Link>
                </Text>
              </Stack>
            </Stack>
          </Box>
        </Stack>
      </Flex>
      <Flex flex={1} maxH={"100vh"}>
        <img alt={"Login Image"} src={"./signup-cover.jpg"} className={"signup-cover"} />
        <Text position={"absolute"} marginLeft={"-115px"} top={"50px"} background={"brand.600"} color="white" fontFamily={"logoFont"} borderRadius="40px" fontSize={"2rem"} py={"10px"} px={"20px"} fontWeight={"bold"}>
          brandzway
        </Text>
      </Flex>
    </Stack>
  );
}
