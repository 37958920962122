import ReactGA from "react-ga4";

// Change here for use your own Google Analytics Tracking ID
const trackingId = "G-13NHLZ6T8Q";

export const initGA = () => {
  ReactGA.initialize([
    {
      trackingId,
    },
  ]);
};

export const logPageView = (page: string, title: string) => {
  ReactGA.send({ hitType: "pageview", page, title });
};

export const logEvent = (category: string, action: string, label?: string, value?: number) => {
  ReactGA.event({
    category,
    action,
    label,
    value,
  });
};
