import { ReactNode, useState } from "react";
import { Button, Center, Menu, MenuButton, MenuItem, MenuList, Spinner, VStack } from "@chakra-ui/react";
import { IconButton, Box, Flex, Icon, useColorModeValue, Link, Drawer, DrawerContent, Text, useDisclosure, BoxProps, FlexProps } from "@chakra-ui/react";
import { FiShoppingBag, FiStar, FiMenu, FiHelpCircle, FiEdit, FiLogOut } from "react-icons/fi";
import { IoAnalyticsSharp } from "react-icons/io5";
import { IconType } from "react-icons";
import { ReactText } from "react";
import { Auth } from "aws-amplify";
import { useNavigate } from "react-router-dom";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { PulseLoader } from "react-spinners";
import { redirectToWebflowForAuth } from "../utils/helpers";
import { AccountInformation, AccountInformationSite } from "../types/account";
import { PlanUsage } from "./plan-usage";
import { BsPatchQuestionFill } from "react-icons/bs";
import { logEvent } from "../utils/google-analytics-lib/analytics";

interface LinkItemProps {
  name: string;
  icon: IconType;
  callback: any;
  isSelected?: boolean;
}

export default function Navbar({ tabName, children, accountInformation, selectedSite, switchSelectedSite }: { tabName: string; children: ReactNode; accountInformation: AccountInformation; selectedSite: AccountInformationSite; switchSelectedSite: any }) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Box minH="100vh" bg={useColorModeValue("gray.100", "gray.900")}>
      <SidebarContent onClose={() => onClose} display={{ base: "none", md: "block" }} initialTab={tabName} accountInformation={accountInformation} selectedSite={selectedSite} switchSelectedSite={switchSelectedSite} />
      <Drawer autoFocus={false} isOpen={isOpen} placement="left" onClose={onClose} returnFocusOnClose={false} onOverlayClick={onClose} size="full">
        <DrawerContent>
          <SidebarContent initialTab={tabName} onClose={onClose} accountInformation={accountInformation} selectedSite={selectedSite} switchSelectedSite={switchSelectedSite} />
        </DrawerContent>
      </Drawer>
      {/* mobilenav */}
      <MobileNav display={{ base: "flex", md: "none" }} onOpen={onOpen} />
      {!accountInformation && (
        <Box ml={{ base: 0, md: 60 }}>
          <Flex minH={"100vh"} align={"center"} justify={"center"}>
            <VStack>
              <Spinner color="brand.400" size={"xl"} thickness="4px" emptyColor="brand.100" />
              <Text fontSize={"md"} color={"brand.400"} p={5} visibility={"hidden"}>
                ...
              </Text>
            </VStack>
          </Flex>
        </Box>
      )}
      {accountInformation && <Box ml={{ base: 0, md: 60 }}>{children}</Box>}
    </Box>
  );
}

interface SidebarProps extends BoxProps {
  initialTab: string;
  accountInformation: AccountInformation;
  selectedSite: AccountInformationSite;
  switchSelectedSite: any;
  onClose: () => void;
}

const SidebarContent = ({ initialTab, accountInformation, selectedSite, switchSelectedSite, onClose, ...rest }: SidebarProps) => {
  const [selectedTab, selectTab] = useState(initialTab);
  const navigate = useNavigate();

  const handleSupportContactClick = () => {
    window.location.href = "mailto:support@brandzway.com";
  };

  const LinkItems: Array<LinkItemProps> = [
    {
      name: "Orders",
      icon: FiShoppingBag,
      callback: () => {
        navigate("/orders");
        selectTab("Orders");
      },
      isSelected: selectedTab == "Orders",
    },
    {
      name: "Reviews",
      icon: FiStar,
      callback: () => {
        navigate("/reviews");
        selectTab("reviews");
      },
      isSelected: selectedTab == "Reviews",
    },
    {
      name: "Customize",
      icon: FiEdit,
      callback: () => {
        navigate("/customize");
        selectTab("Customize");
      },
      isSelected: selectedTab == "Customize",
    },
    {
      name: "Analytics",
      icon: IoAnalyticsSharp,
      callback: () => {
        navigate("/analytics");
        selectTab("Analytics");
      },
      isSelected: selectedTab == "Analytics",
    },
    // { name: 'Settings', icon: FiSettings },
    {
      name: "How to Install",
      icon: FiHelpCircle,
      callback: () => {
        navigate("/how-to-install");
        selectTab("HowToInstall");
      },
      isSelected: selectedTab == "HowToInstall",
    },
    { name: "Log out", icon: FiLogOut, callback: () => logOut() },
  ];
  const logOut = async () => {
    try {
      await Auth.signOut();
      window.location.reload();
    } catch (error) {
      console.log("error signing out: ", error);
    }
  };

  const [openPlanUsage, setPlanUsage] = useState(false);
  const closePlanUsage = () => {
    setPlanUsage(false);
  };

  return (
    <Box bg={useColorModeValue("white", "gray.900")} w={{ base: "full", md: 60 }} pos="fixed" h="full" {...rest} zIndex={10}>
      <Flex py={"3"} background={"brand.600"} borderRadius={"0 0 50px 0"}>
        <Center width={"100%"}>
          <Text fontSize="2xl" fontWeight="700" color="white" fontFamily={"logoFont"}>
            brandzway
          </Text>
        </Center>
      </Flex>
      <Flex py={"5"}>
        <Center width={"100%"}>
          <Box h={"40px"}>
            {!accountInformation && <PulseLoader size={"10"} color={"black"} />}
            {accountInformation && (
              <Menu>
                <MenuButton as={Button} color="brand.800" background={"brand.100"} rightIcon={<ChevronDownIcon />} w={"200px"} cursor={"pointer"}>
                  <Flex alignItems="center" paddingRight="20px" textOverflow="ellipsis" whiteSpace="nowrap" overflow="hidden">
                    <span>{selectedSite.siteName}</span>
                  </Flex>
                </MenuButton>
                <MenuList maxHeight="550px" overflowY="auto">
                  {accountInformation.sites?.map((item: any) => {
                    return (
                      <MenuItem minH="42px" onClick={() => switchSelectedSite(item)}>
                        <Text px={"2.5"} py={"1"} bg={"brand.200"} borderRadius={"full"} fontSize={"xs"} color={"brand.600"} fontWeight={"bold"} textTransform="uppercase">
                          {item.siteName[0]}
                        </Text>
                        <Text ml={"10px"}>{item.siteName}</Text>
                      </MenuItem>
                    );
                  })}
                  <MenuItem fontWeight={"semibold"} onClick={redirectToWebflowForAuth} minH="42px">
                    + Add Site
                  </MenuItem>
                </MenuList>
              </Menu>
            )}
          </Box>
        </Center>
      </Flex>
      {LinkItems.map((link) => (
        <NavItem key={link.name} icon={link.icon} onClick={link.callback} isSelected={link.isSelected || false} linkCallBack={link.callback}>
          {link.name}
        </NavItem>
      ))}
      <Box position={"fixed"} bottom="0" left="0" p={3} bg="white" w={{ base: "full", md: 60 }}>
        <VStack alignContent={"left"} spacing={5}>
          <VStack>
            <Button
              size={"sm"}
              rounded={"full"}
              py={5}
              px={8}
              boxShadow={"xl"}
              bgGradient="linear(to-r, purple.400, purple.600)"
              color={"white"}
              _hover={{
                bgGradient: "linear(to-r, purple.600, purple.800)",
              }}
              onClick={() => {
                navigate("/change-plan");
                logEvent("Navbar", "Click", "Upgrade Plan");
              }}
            >
              Upgrade Plan
            </Button>
            <Text fontSize={"xs"} textDecoration={"underline"}>
              <Link
                as="button"
                textDecoration={"underline"}
                onClick={() => {
                  setPlanUsage(true);
                  logEvent("Navbar", "Click", "Check Plan Usage");
                }}
              >
                Check Plan Usage
              </Link>
            </Text>
            <Box pt={6}>
              <Button
                size={"xs"}
                leftIcon={<BsPatchQuestionFill />}
                onClick={() => {
                  handleSupportContactClick();
                  logEvent("Navbar", "Click", "Support");
                }}
                colorScheme="gray"
                variant="outline"
              >
                Support
              </Button>
            </Box>
          </VStack>
          <Flex alignItems="center" w={"100%"} py={3} px={0} borderTop={"1px"} borderColor={"gray.300"}>
            {accountInformation && (
              <Box>
                <Text fontWeight="bold" fontSize={"sm"}>
                  {accountInformation.firstName + " " + accountInformation.lastName}
                </Text>
                <Text fontSize="sm">{accountInformation.email}</Text>
              </Box>
            )}
          </Flex>
          <PlanUsage isOpen={openPlanUsage} onClose={closePlanUsage} accountInformation={accountInformation}></PlanUsage>
        </VStack>
      </Box>
    </Box>
  );
};

interface NavItemProps extends FlexProps {
  icon: IconType;
  children: ReactText;
  isSelected: boolean;
  linkCallBack: any;
}
const NavItem = ({ icon, children, isSelected, linkCallBack, ...rest }: NavItemProps) => {
  return (
    <Link
      // href="#"
      as="button"
      width={"100%"}
      aria-selected={isSelected}
      style={{ textDecoration: "none" }}
      tabIndex={0}
      onClick={(e) => {
        e.preventDefault();
        linkCallBack && linkCallBack();
      }}
    >
      <Flex
        align="center"
        p="2"
        mx="4"
        my="0.5"
        borderRightWidth={isSelected ? "8px" : "0px"}
        borderRightColor="brand.400"
        cursor="pointer"
        fontWeight={isSelected ? "bold" : ""}
        fontSize={"md"}
        bg={isSelected ? "brand.50" : ""}
        borderRadius="lg"
        _hover={{
          bg: "brand.50",
        }}
      >
        {icon && <Icon mr="4" fontSize="18" as={icon} />}
        {children}
      </Flex>
    </Link>
  );
};

interface MobileProps extends FlexProps {
  onOpen: () => void;
}
const MobileNav = ({ onOpen, ...rest }: MobileProps) => {
  return (
    <Flex ml={{ base: 0, md: 60 }} px={{ base: 4, md: 24 }} height="20" alignItems="center" bg={useColorModeValue("white", "gray.900")} borderBottomWidth="1px" borderBottomColor={useColorModeValue("gray.200", "gray.700")} justifyContent="flex-start" {...rest}>
      <IconButton variant="outline" onClick={onOpen} aria-label="open menu" icon={<FiMenu />} />

      <Text fontSize="2xl" ml="8" fontFamily="monospace" fontWeight="bold">
        brandzway
      </Text>
    </Flex>
  );
};
