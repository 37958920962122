import { ArrowLeftIcon, ArrowRightIcon, ChevronDownIcon, ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { Box, VStack, Text, Spacer, Switch, Flex, Button, Badge, Avatar, Menu, MenuButton, MenuList, MenuItem, Popover, PopoverTrigger, PopoverContent, PopoverHeader, PopoverArrow, PopoverCloseButton, PopoverBody, RadioGroup, Radio, Textarea, ButtonGroup, HStack, Checkbox, Center, Table, Thead, Tr, Th, Td, Tbody, Tooltip, IconButton, Select, Spinner, useToast, TagLabel, Tag, Icon, Image, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, useDisclosure } from "@chakra-ui/react";
import _ from "lodash";
import { off } from "process";
import React, { useState } from "react";
import { useEffect } from "react";
import { usePagination, useSortBy, useTable } from "react-table";
import Navbar from "../../components/navbar";
import { useGetProductReviewsForDashboard } from "../../hooks/get-reviews-for-dashboard";
import { useUpdateProductReviewsForDashboard } from "../../hooks/update-product-reviews-for-dashboard";
import { RiShoppingBag3Fill } from "react-icons/ri";
import { DateRangePicker } from "../../components/date-range-picker";
import { AccountInformationSite, AccountInformation } from "../../types/account";
import { allowPremiumFeatures, geLast30DaysDateRange } from "../../utils/helpers";
import { PlanLimitReached } from "../../components/plan-limit-reached";
import { NoResultFound } from "../../components/no-result-found";
import { LoadingSpinnerAndText } from "../../components/loading-spinner-and-text";
import { config } from "./../../deployment/config";
import { ErrorOccurred } from "../../components/error-occurred";
import { PremiumFeature } from "../../components/premium-feature";

export default function ManageReviews({ accountInformation, selectedSite, switchSelectedSite }: { accountInformation: any; selectedSite: AccountInformationSite; switchSelectedSite: any }) {
  return <Navbar tabName="Reviews" children={<MainContainer selectedSite={selectedSite} accountInformation={accountInformation}></MainContainer>} accountInformation={accountInformation} selectedSite={selectedSite} switchSelectedSite={switchSelectedSite}></Navbar>;
}

const MainContainer = ({ selectedSite, accountInformation }: { selectedSite: AccountInformationSite; accountInformation: AccountInformation }) => {
  const { getProductReviewsForDashboard, getProductReviewsForDashboardData, getProductReviewsForDashboardLoading, getProductReviewsForDashboardError } = useGetProductReviewsForDashboard();
  const [dateRangeinISO, setDateRangeinISO] = useState(geLast30DaysDateRange());
  const [sortByFilters, setSortByFilters] = useState("Newest");
  useEffect(() => {
    if (selectedSite) {
      getProductReviewsForDashboard({ variables: { siteId: selectedSite.siteId, minDate: dateRangeinISO[0], maxDate: dateRangeinISO[1] } });
    }
  }, [selectedSite]);

  const setDateRange = (dateRange: any) => {
    let minDate = dateRange[0];
    let maxDate = dateRange[1];
    setDateRangeinISO([minDate, maxDate]);
    getProductReviewsForDashboard({ variables: { siteId: selectedSite.siteId, minDate: minDate, maxDate: maxDate } });
  };

  const [statusFilters, setStatusFilters] = useState({
    autoPublished: true,
    manualPublished: true,
    hidden: true,
    pendingForReview: true,
  });
  const [startRatingFilters, setStartRatingFilters] = useState({
    five: true,
    four: true,
    three: true,
    two: true,
    one: true,
  });

  const [buyerFilters, setBuyerFilters] = useState({
    isVerified: true,
    isUnVerified: true,
  });

  return (
    <>
      <PlanLimitReached isUsageLimitReached={accountInformation && accountInformation.usage.used >= accountInformation.usage.total}></PlanLimitReached>
      <Box minH="100vh" p={4} bg="#edf2f7" w="100%">
        <Flex align={"right"}>
          {getProductReviewsForDashboardLoading && (
            <Flex minH={"100vh"} align={"center"} justify={"center"} flex="1">
              <LoadingSpinnerAndText textToShow={"Fetching Reviews..."}></LoadingSpinnerAndText>
            </Flex>
          )}
          {getProductReviewsForDashboardError && (
            <Flex minH={"100vh"} justify={"center"} flex="1">
              <ErrorOccurred></ErrorOccurred>
            </Flex>
          )}
          {getProductReviewsForDashboardData && (
            <Box bg={"#edf2f7"} flex="1">
              <VStack align={"stretch"} gap={"5"}>
                <ReviewsTable productReviewsData={getProductReviewsForDashboardData} statusFilters={statusFilters} startRatingFilters={startRatingFilters} buyerFilters={buyerFilters} sortByFilters={sortByFilters} currentSubscription={accountInformation.currentSubscription}></ReviewsTable>
              </VStack>
            </Box>
          )}

          <Box width={"300px"} bg={"white"} borderRadius={"3xl"} ml={5} py={8} px={5} pointerEvents={getProductReviewsForDashboardLoading ? "none" : undefined} opacity={getProductReviewsForDashboardLoading ? 0.4 : 1}>
            <FilterContainer
              sortBy={sortByFilters}
              setSortBy={setSortByFilters}
              statusFilters={statusFilters}
              setStatusFilters={setStatusFilters}
              startRatingFilters={startRatingFilters}
              setStartRatingFilters={setStartRatingFilters}
              buyerFilters={buyerFilters}
              setBuyerFilters={setBuyerFilters}
              setDateRange={(dateRange: any) => {
                setDateRange(dateRange);
              }}
            ></FilterContainer>
          </Box>
        </Flex>
      </Box>
    </>
  );
};

const ReviewsTable = ({ productReviewsData, statusFilters, startRatingFilters, buyerFilters, sortByFilters, currentSubscription }: { productReviewsData: any; statusFilters: any; startRatingFilters: any; buyerFilters: any; sortByFilters: string; currentSubscription: any }) => {
  const columns = React.useMemo(
    () => [
      {
        Header: "Product Review Request Id",
        accessor: "productReviewId",
      },
    ],
    []
  );

  const checkIfFallsInFilter = (publishStatus: string, starRating: number, isVerified: boolean) => {
    if (publishStatus == "Auto Published" && statusFilters.autoPublished) {
    } else if (publishStatus == "Manual Published" && statusFilters.manualPublished) {
    } else if (publishStatus == "Hidden" && statusFilters.hidden) {
    } else if (publishStatus == "Pending For Review" && statusFilters.pendingForReview) {
    } else {
      return false;
    }

    if (starRating == 5 && startRatingFilters.five) {
    } else if (starRating == 4 && startRatingFilters.four) {
    } else if (starRating == 3 && startRatingFilters.three) {
    } else if (starRating == 2 && startRatingFilters.two) {
    } else if (starRating == 1 && startRatingFilters.one) {
    } else {
      return false;
    }

    if (isVerified && buyerFilters.isVerified) {
    } else if (isVerified == false && buyerFilters.isUnVerified) {
    } else {
      return false;
    }

    return true;
  };

  let data = productReviewsData;
  data = _.filter(data, (row) => {
    return checkIfFallsInFilter(row.publishStatus, row.starRating, row.isVerified);
  });

  if (sortByFilters == "Oldest") {
    data = _.orderBy(data, (review) => new Date(review.submittedAt), "asc");
  }

  const updateProductReviewsModerationDetails = (moderationDetails: any) => {
    _.each(data, (row) => {
      if (row.productReviewId == moderationDetails.productReviewId) {
        if (_.has(row, "publishStatus") && moderationDetails.publishStatus) {
          row.publishStatus = moderationDetails.publishStatus;
        }
        if (_.has(row, "replies") && moderationDetails.replyType) {
          row.replies = [
            {
              replyType: moderationDetails.replyType,
              message: moderationDetails.message,
            },
          ];
        }
      }
    });
  };

  return data && (data.length == 0 ? <NoResultFound></NoResultFound> : <ReviewRows columns={columns} data={data} updateProductReviewsModerationDetails={updateProductReviewsModerationDetails} currentSubscription={currentSubscription}></ReviewRows>);
};

const ReviewRows = ({ columns, data, updateProductReviewsModerationDetails, currentSubscription }: { columns: any; data: any; updateProductReviewsModerationDetails: any; currentSubscription: any }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0,
      },
      autoResetSortBy: false,
      autoResetFilters: false,
    },
    useSortBy,
    usePagination
  );

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [attachmentUrl, setAttachmentUrl] = useState("");

  return (
    <Box>
      <Table {...getTableProps()} variant={"unstyled"} size={"sm"}>
        <Tbody {...getTableBodyProps()}>
          {page.map((row: any, i: any) => {
            prepareRow(row);
            return (
              <Tr {...row.getRowProps()}>
                {row.cells.map((cell: any, idx: number) => {
                  if (idx == 0) {
                    return (
                      <Td {...cell.getCellProps()}>
                        <IndividualReview
                          data={row}
                          updateProductReviewsModerationDetails={updateProductReviewsModerationDetails}
                          currentSubscription={currentSubscription}
                          openImage={(imageUrl: string) => {
                            setAttachmentUrl(imageUrl);
                            onOpen();
                          }}
                        ></IndividualReview>
                      </Td>
                    );
                    //return <Td {...cell.getCellProps()}>{cell.render("Cell")}</Td>;
                  }
                })}
              </Tr>
            );
          })}
        </Tbody>
      </Table>
      <PagniationBar canPreviousPage={canPreviousPage} previousPage={previousPage} gotoPage={gotoPage} pageIndex={pageIndex} pageOptions={pageOptions} nextPage={nextPage} canNextPage={canNextPage} pageCount={pageCount} pageSize={pageSize} setPageSize={setPageSize}></PagniationBar>
      <Modal onClose={onClose} isOpen={isOpen} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody>
            <Image objectFit="cover" src={attachmentUrl} boxSize="512px" />
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

const FilterContainer = ({ sortBy, setSortBy, statusFilters, setStatusFilters, startRatingFilters, setStartRatingFilters, buyerFilters, setBuyerFilters, setDateRange }: { sortBy: any; setSortBy: any; statusFilters: any; setStatusFilters: any; startRatingFilters: any; setStartRatingFilters: any; buyerFilters: any; setBuyerFilters: any; setDateRange: any }) => {
  return (
    <VStack alignItems={"left"}>
      {/* Future Todo */}
      {/* <Checkbox mr={5} width={"100px"} colorScheme="brand">
        <Text fontSize={"sm"}>Select all</Text>
      </Checkbox> */}

      <Text fontSize={"sm"} pt={"5"} as="b">
        Selection Period:
      </Text>
      <DateRangePicker
        isHorizontalStack={false}
        selectedDateRange={(dateRange: any) => {
          setDateRange(dateRange);
        }}
      ></DateRangePicker>

      <Text fontSize={"sm"} pt={"5"} as="b">
        Sort By:
      </Text>
      <Menu>
        <MenuButton as={Button} rightIcon={<ChevronDownIcon />} size={"sm"} width={"200px"}>
          {sortBy}
        </MenuButton>
        <MenuList>
          <MenuItem
            onClick={() => {
              setSortBy("Newest");
            }}
          >
            Newset
          </MenuItem>
          <MenuItem
            onClick={() => {
              setSortBy("Oldest");
            }}
          >
            Oldest
          </MenuItem>
        </MenuList>
      </Menu>

      <Text fontSize={"sm"} pt={"5"} as="b">
        Status:
      </Text>
      <Checkbox
        mr={5}
        width={"200px"}
        colorScheme="brand"
        isChecked={statusFilters.autoPublished}
        onChange={(e) => {
          setStatusFilters({ ...statusFilters, autoPublished: e.target.checked });
        }}
      >
        <Text fontSize={"sm"}>Auto Published</Text>
      </Checkbox>
      <Checkbox
        mr={5}
        width={"100px"}
        colorScheme="brand"
        isChecked={statusFilters.manualPublished}
        onChange={(e) => {
          setStatusFilters({ ...statusFilters, manualPublished: e.target.checked });
        }}
      >
        <Text fontSize={"sm"}>Manual Published</Text>
      </Checkbox>
      <Checkbox
        mr={5}
        width={"100px"}
        colorScheme="brand"
        isChecked={statusFilters.hidden}
        onChange={(e) => {
          setStatusFilters({ ...statusFilters, hidden: e.target.checked });
        }}
      >
        <Text fontSize={"sm"}>Hidden</Text>
      </Checkbox>
      <Checkbox
        mr={5}
        width={"200px"}
        colorScheme="brand"
        isChecked={statusFilters.pendingForReview}
        onChange={(e) => {
          setStatusFilters({ ...statusFilters, pendingForReview: e.target.checked });
        }}
      >
        <Text fontSize={"sm"}>Pending for Review</Text>
      </Checkbox>

      <Text fontSize={"sm"} pt={"5"} as="b">
        Star Ratings:
      </Text>
      <Checkbox
        aria-label="5 star ratings"
        mr={5}
        width={"200px"}
        colorScheme="brand"
        isChecked={startRatingFilters.five}
        onChange={(e) => {
          setStartRatingFilters({ ...startRatingFilters, five: e.target.checked });
        }}
      >
        <StarSet starRating={5}></StarSet>
      </Checkbox>
      <Checkbox
        aria-label="4 star ratings"
        mr={5}
        width={"200px"}
        colorScheme="brand"
        isChecked={startRatingFilters.four}
        onChange={(e) => {
          setStartRatingFilters({ ...startRatingFilters, four: e.target.checked });
        }}
      >
        <StarSet starRating={4}></StarSet>
      </Checkbox>
      <Checkbox
        aria-label="3 star ratings"
        mr={5}
        width={"200px"}
        colorScheme="brand"
        isChecked={startRatingFilters.three}
        onChange={(e) => {
          setStartRatingFilters({ ...startRatingFilters, three: e.target.checked });
        }}
      >
        <StarSet starRating={3}></StarSet>
      </Checkbox>
      <Checkbox
        aria-label="2 star ratings"
        mr={5}
        width={"200px"}
        colorScheme="brand"
        isChecked={startRatingFilters.two}
        onChange={(e) => {
          setStartRatingFilters({ ...startRatingFilters, two: e.target.checked });
        }}
      >
        <StarSet starRating={2}></StarSet>
      </Checkbox>
      <Checkbox
        aria-label="1 star ratings"
        mr={5}
        width={"200px"}
        colorScheme="brand"
        isChecked={startRatingFilters.one}
        onChange={(e) => {
          setStartRatingFilters({ ...startRatingFilters, one: e.target.checked });
        }}
      >
        <StarSet starRating={1}></StarSet>
      </Checkbox>

      <Text fontSize={"sm"} pt={"5"} as="b">
        Buyer:
      </Text>
      <Checkbox
        mr={5}
        width={"200px"}
        colorScheme="brand"
        isChecked={buyerFilters.isVerified}
        onChange={(e) => {
          setBuyerFilters({ ...buyerFilters, isVerified: e.target.checked });
        }}
      >
        <Text fontSize={"sm"}>Verified Buyer</Text>
      </Checkbox>
      <Checkbox
        mr={5}
        width={"200px"}
        colorScheme="brand"
        isChecked={buyerFilters.isUnVerified}
        onChange={(e) => {
          setBuyerFilters({ ...buyerFilters, isUnVerified: e.target.checked });
        }}
      >
        <Text fontSize={"sm"}>UnVerified Buyer</Text>
      </Checkbox>

      {/* Future Todo */}
      {/* <Text fontSize={"sm"} pt={"5"} as="b">
        Action on Selected:
      </Text>
      <StatusButton showReply={false}></StatusButton> */}
    </VStack>
  );
};

const IndividualReview = ({ data, updateProductReviewsModerationDetails, openImage, currentSubscription }: { data: any; updateProductReviewsModerationDetails: any; openImage: any; currentSubscription: any }) => {
  const { updateProductReviewsForDashboard, updateProductReviewsForDashboardData, updateProductReviewsForDashboardLoading, updateProductReviewsForDashboardError } = useUpdateProductReviewsForDashboard();
  const toast = useToast();

  const changePublishStatus = (newPublishStatus: string) => {
    const input = {
      siteId: data.original.siteId,
      productId: data.original.productId,
      productReviewId: data.original.productReviewId,
      submittedAt: data.original.submittedAt,
      publishStatus: newPublishStatus,
    };

    updateProductReviewsForDashboard({ variables: { productReviewRequestModerationDetails: input } })
      .then(() => {
        updateProductReviewsModerationDetails(input);
        toast({
          title: "Changes Saved",
          status: "success",
          position: "bottom",
          duration: 1000,
          isClosable: true,
        });
      })
      .catch(() => {
        toast({
          title: "Failed to save the changes",
          status: "error",
          position: "bottom",
          duration: 1000,
          isClosable: true,
        });
      });
  };

  const setReply = (newReply: any) => {
    const input = {
      siteId: data.original.siteId,
      productId: data.original.productId,
      productReviewId: data.original.productReviewId,
      submittedAt: data.original.submittedAt,
      replyType: newReply.replyType,
      message: newReply.message,
      productName: data.original.productName,
      originalReviewDetails: {
        title: data.original.title,
        message: data.original.message,
        starRating: data.original.starRating,
        customerEmail: data.original.customerEmail,
        customerFullName: data.original.customerFullName,
      },
    };

    updateProductReviewsForDashboard({ variables: { productReviewRequestModerationDetails: input } })
      .then(() => {
        updateProductReviewsModerationDetails(input);
        toast({
          title: "Changes Saved",
          status: "success",
          position: "bottom",
          duration: 1000,
          isClosable: true,
        });
      })
      .catch(() => {
        toast({
          title: "Failed to save the changes",
          status: "error",
          position: "bottom",
          duration: 1000,
          isClosable: true,
        });
      });
  };

  return (
    <Flex bg={"white"} p={8} borderRadius={"3xl"} direction={"column"}>
      <Flex align={"left"}>
        <Box w={"48px"} height={"48px"} rounded={"full"} bg={"brand.200"} pt={"16px"} pl={"18px"}>
          <Text color={"white"} fontWeight={"semibold"} fontSize={"xl"}>
            {data.original.customerFullName[0]}
          </Text>
        </Box>
        <VStack align={"left"} spacing={1} justifyContent={"center"} pl={2}>
          <HStack>
            <Text fontWeight="bold">{data.original.customerFullName}</Text>
            {data.original.isVerified ? <Badge colorScheme="green">Verified Buyer</Badge> : ""}
          </HStack>
          <StarSet starRating={data.original.starRating}></StarSet>
        </VStack>
        <Spacer />
        <Text>{new Date(data.original.submittedAt).toLocaleDateString() + ""}</Text>
      </Flex>
      <Box py={5}>
        {data.original.title != "" && <Text as="b">{data.original.title}</Text>}
        <Text pt={"15px"}>{data.original.message}</Text>
        {data.original.productReviewImages.length > 0 && <Image alt="review photo" cursor={"pointer"} onClick={() => openImage(`${config.MEDIA_BASE_URL}/${data.original.productReviewImages[0].path}/${data.original.productReviewImages[0].name}`)} objectFit="cover" src={`${config.MEDIA_BASE_URL}/${data.original.productReviewImages[0].path}/${data.original.productReviewImages[0].name}`} boxSize="75px" mt={8} />}
      </Box>
      <StatusButton currentStatus={data.original.publishStatus} changePublishStatus={(newPublishStatus: string) => changePublishStatus(newPublishStatus)} replies={data.original.replies} setReply={(newReply: any) => setReply(newReply)} updateProductReviewsForDashboardLoading={updateProductReviewsForDashboardLoading} productName={data.original.productName} currentSubscription={currentSubscription}></StatusButton>
    </Flex>
  );
};

const StarSet = ({ starRating }: { starRating: number }) => {
  return (
    <HStack spacing={"0"}>
      retun (
      {[1, 2, 3, 4, 5].map((item) => {
        if (item <= starRating) {
          return <StarSvg starColor="#e7bc1f"></StarSvg>;
        } else {
          return <StarSvg starColor="#f7e9b1"></StarSvg>;
        }
      })}
      )
    </HStack>
  );
};

const StarSvg = ({ starColor }: { starColor: string }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" style={{ marginLeft: "0" }}>
      <path
        fill={starColor}
        d="M15.9199,11.8203 C15.6599,12.0703 15.5399,12.4393 15.6009,12.7903 L16.4899,17.7103 C16.5609,18.1303 16.3909,18.5493 16.0399,18.7903 C15.6999,19.0403 15.2499,19.0703 14.8699,18.8703 L10.4409,16.5603 C10.2799,16.4803 10.1099,16.4293 9.9409,16.4293 L9.6699,16.4293 C9.5699,16.4393 9.4809,16.4803 9.3999,16.5193 L4.9699,18.8403 C4.7499,18.9503 4.4999,18.9903 4.2599,18.9503 C3.6599,18.8303 3.2709,18.2693 3.3699,17.6793 L4.2599,12.7593 C4.3199,12.4003 4.1999,12.0403 3.9409,11.7803 L0.3299,8.2803 C0.0299,7.9803 -0.0801,7.5493 0.0609,7.1503 C0.1909,6.7593 0.5299,6.4693 0.9499,6.4003 L5.9199,5.6793 C6.2999,5.6393 6.6299,5.4103 6.7999,5.0703 L8.9899,0.5803 C9.0399,0.4803 9.1099,0.3893 9.1909,0.3103 L9.2799,0.2403 C9.3199,0.1893 9.3799,0.1503 9.4409,0.1103 L9.5499,0.0703 L9.7199,0.0003 L10.1409,0.0003 C10.5209,0.0403 10.8509,0.2693 11.0209,0.5993 L13.2399,5.0703 C13.3999,5.4003 13.7099,5.6203 14.0699,5.6793 L19.0399,6.4003 C19.4599,6.4603 19.8109,6.7503 19.9499,7.1503 C20.0799,7.5493 19.9699,7.9903 19.6599,8.2803 L15.9199,11.8203 Z"
        transform="translate(2 2.5)"
      />
    </svg>
  );
};

const StatusButton = ({ currentStatus, changePublishStatus, replies, setReply, updateProductReviewsForDashboardLoading, productName, currentSubscription }: { currentStatus: string; changePublishStatus: any; replies: any; setReply: any; updateProductReviewsForDashboardLoading: boolean; productName: string; currentSubscription: any }) => {
  return (
    <Flex>
      {updateProductReviewsForDashboardLoading ? (
        <Button isLoading colorScheme={GetColorSchemeForStatusButton(currentStatus)} size="sm">
          Hidden
        </Button>
      ) : (
        <Menu>
          <MenuButton as={Button} colorScheme={GetColorSchemeForStatusButton(currentStatus)} rightIcon={<ChevronDownIcon />} size={"sm"}>
            {currentStatus}
          </MenuButton>
          <MenuList>
            <MenuItem onClick={() => changePublishStatus("Hidden")}>Hide</MenuItem>
            <MenuItem onClick={() => changePublishStatus("Manual Published")}>Publish</MenuItem>
          </MenuList>
        </Menu>
      )}

      <ReplyContainer replies={replies} setReply={setReply} updateProductReviewsForDashboardLoading={updateProductReviewsForDashboardLoading} currentSubscription={currentSubscription}></ReplyContainer>
      <Spacer />
      <Center>
        <Tag size="md" colorScheme="brand" borderRadius="full">
          <Icon as={RiShoppingBag3Fill}></Icon>
          <TagLabel px={3}>{productName}</TagLabel>
        </Tag>
      </Center>
    </Flex>
  );
};

const GetColorSchemeForStatusButton = (status: string) => {
  if (status == "Auto Published" || status == "Manual Published") {
    return "green";
  } else if (status == "Hidden") {
    return "blackAlpha";
  } else {
    return "yellow";
  }
};

const ReplyContainer = ({ replies, setReply, updateProductReviewsForDashboardLoading, currentSubscription }: { replies: any; setReply: any; updateProductReviewsForDashboardLoading: any; currentSubscription: any }) => {
  const [replyType, setReplyType] = useState("Public");
  const [message, setMessage] = useState("");

  useEffect(() => {
    if (replies?.length > 0) {
      setReplyType(replies[0].replyType);
      setMessage(replies[0].message);
    }
  }, [replies]);

  return (
    <Popover isLazy placement="bottom-start">
      {({ isOpen, onClose }: { isOpen: any; onClose: any }) => (
        <>
          <PopoverTrigger>
            <Button ml={5} size="sm" width="100px" variant="outline" colorScheme="brand" isLoading={updateProductReviewsForDashboardLoading}>
              {replies?.length > 0 ? "Replied" : "Reply"}
            </Button>
          </PopoverTrigger>
          <PopoverContent width={"500px"}>
            <PopoverArrow />
            <PopoverCloseButton />
            <PopoverBody>
              <Textarea isDisabled={replies?.length > 0} my={5} placeholder="Write a reply here..." size="sm" resize={"none"} value={message} onChange={(e) => setMessage(e.target.value)} />
              <HStack>
                <RadioGroup
                  value={replyType}
                  colorScheme="brand"
                  isDisabled={replies?.length > 0}
                  onChange={(val) => {
                    setReplyType(val);
                  }}
                >
                  <HStack>
                    <Tooltip label="Reply will be displayed as a nested comment under the customer's review on the product page." hasArrow>
                      <Radio size="md" value="Public">
                        Public
                      </Radio>
                    </Tooltip>
                    <Tooltip label="The reply will be sent to the customer via email." hasArrow>
                      <>
                        <Radio size="md" value="Private" isDisabled={!allowPremiumFeatures(currentSubscription)}>
                          Private
                        </Radio>
                        <PremiumFeature isPremiumUser={allowPremiumFeatures(currentSubscription)}></PremiumFeature>
                      </>
                    </Tooltip>
                  </HStack>
                </RadioGroup>
                <Spacer />
                <ButtonGroup>
                  <Button variant="outline" size={"sm"} colorScheme="brand" onClick={onClose}>
                    Cancel
                  </Button>
                  <Button
                    colorScheme="brand"
                    size={"sm"}
                    onClick={() => {
                      setReply({ replyType: replyType, message: message });
                      onClose();
                    }}
                    isDisabled={replies?.length > 0}
                  >
                    Post
                  </Button>
                </ButtonGroup>
              </HStack>
            </PopoverBody>
          </PopoverContent>
        </>
      )}
    </Popover>
  );
};

const PagniationBar = ({ canPreviousPage, previousPage, gotoPage, pageIndex, pageOptions, nextPage, canNextPage, pageCount, pageSize, setPageSize }: { canPreviousPage: any; previousPage: any; gotoPage: any; pageIndex: any; pageOptions: any; nextPage: any; canNextPage: any; pageCount: any; pageSize: any; setPageSize: any }) => {
  return (
    <Flex justifyContent="space-between" m={4} alignItems="center" bg="white" p={3} borderRadius={"2xl"}>
      <Flex>
        <Tooltip label="First Page">
          <IconButton aria-label="Go to page 1" onClick={() => gotoPage(0)} isDisabled={!canPreviousPage} icon={<ArrowLeftIcon h={3} w={3} />} mr={3} />
        </Tooltip>
        <Tooltip label="Last Page">
          <IconButton aria-label="Go to last page" onClick={() => gotoPage(pageCount - 1)} isDisabled={!canNextPage} icon={<ArrowRightIcon h={3} w={3} />} ml={3} />
        </Tooltip>
        <Select
          aria-label="Number of items to show"
          fontSize={"sm"}
          w={32}
          value={pageSize}
          ml={4}
          onChange={(e) => {
            setPageSize(Number(e.target.value));
          }}
        >
          {[10, 20, 30].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </Select>
      </Flex>

      <Flex>
        <Center>
          <Text flexShrink="0" mr={8} fontSize={"sm"}>
            Page{" "}
            <Text fontWeight="bold" as="span">
              {pageIndex + 1}
            </Text>{" "}
            of{" "}
            <Text fontWeight="bold" as="span">
              {pageOptions.length}
            </Text>
          </Text>
        </Center>
        <Tooltip label="Previous Page">
          <IconButton onClick={previousPage} isDisabled={!canPreviousPage} icon={<ChevronLeftIcon h={6} w={6} />} aria-label={"Go to previous page"} />
        </Tooltip>
        <Tooltip label="Next Page">
          <IconButton onClick={nextPage} isDisabled={!canNextPage} icon={<ChevronRightIcon h={6} w={6} />} ml={4} aria-label={"Go to next page"} />
        </Tooltip>
      </Flex>
    </Flex>
  );
};
