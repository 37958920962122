import { gql, useLazyQuery, useMutation } from "@apollo/client";
import { useState } from "react";
import { SubscriptionDetails } from "../../appsync/API";
import { registerSubscription } from "../../appsync/graphql/mutations";

export const useCreateSubscription = () => {
  const [isCalled, setIsCalled] = useState(false);
  const [call, { loading, error, data }] = useMutation<{ registerSubscription: SubscriptionDetails }>(gql(registerSubscription), {
    fetchPolicy: "no-cache",
  });

  const makeCall = (args?: any) => {
    call(args);
    setIsCalled(true);
  };

  return {
    registerSubscription: call,
    registerSubscriptionData: data?.registerSubscription,
    registerSubscriptionLoading: loading,
    registerSubscriptionError: error,
    isCalled: isCalled,
  };
};
