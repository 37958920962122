import { Box, Button, Flex, Link, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Progress, Spacer, Spinner, Text, VStack } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { logEvent } from "../utils/google-analytics-lib/analytics";

export const PlanUsage = ({ isOpen, onClose, accountInformation }: { isOpen: any; onClose: any; accountInformation: any }) => {
  const [currentPlanToShow, setCurrentPlanToShow] = useState("");

  const handleBillingContactClick = () => {
    window.location.href = "mailto:billing@brandzway.com";
  };

  useEffect(() => {
    if (accountInformation) {
      if (accountInformation?.currentSubscription?.subscriptionChange) {
        setCurrentPlanToShow(accountInformation?.currentSubscription?.subscriptionChange?.nextTier as string);
      } else {
        setCurrentPlanToShow(accountInformation?.currentSubscription?.tier || "");
      }
    }
  });

  const getFormatDate = (dateTime: Date) => {
    const date = new Date(dateTime);
    const formattedDate = date.toLocaleDateString("en-US", { month: "short", day: "2-digit", year: "numeric" });
    return formattedDate;
  };
  const navigate = useNavigate();
  return (
    <Modal isOpen={isOpen} onClose={onClose} size={"lg"}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Plan Usage</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {!accountInformation ? (
            <Flex alignItems={"center"} justifyContent={"center"} minH={"200px"}>
              <Spinner thickness="4px" speed="0.5s" emptyColor="gray.200" color="brand.500" size="xl" />
            </Flex>
          ) : (
            <VStack width={"100%"} justifyContent={"left"} alignItems={"left"} pb={10} spacing={8}>
              <Flex p={5} bg={"gray.100"} borderRadius={"xl"}>
                <VStack align={"left"}>
                  <Text color={"gray.600"}>Plan</Text>
                  <Text color={"gray.800"} as="b">
                    {accountInformation?.currentSubscription?.subscriptionChange ? accountInformation.currentSubscription.subscriptionChange.nextTier : accountInformation.currentSubscription.tier}
                  </Text>
                </VStack>
                <Spacer />
                <VStack align={"left"}>
                  <Text color={"gray.600"}>Monthly Limit</Text>
                  <Text color={"gray.800"} as="b">
                    {accountInformation.currentSubscription.subscriptionChange ? accountInformation.currentSubscription.subscriptionChange.monthlyLimit : accountInformation.currentSubscription.monthlyLimit} Orders
                  </Text>
                </VStack>
                <Spacer />
                <VStack align={"left"}>
                  <Text color={"gray.600"}>Cost</Text>
                  <Text color={"gray.800"} as="b">
                    {accountInformation.currentSubscription.subscriptionChange ? accountInformation.currentSubscription.subscriptionChange.cost : accountInformation.currentSubscription.cost}
                  </Text>
                </VStack>
                <Spacer />
                <VStack align={"left"}>
                  <Text color={"gray.600"}>Billing Cycle</Text>
                  <Text color={"gray.800"} as="b">
                    {accountInformation.currentSubscription.billingCycle}
                  </Text>
                </VStack>
              </Flex>
              <Box>
                <VStack align={"left"} spacing={5} mt={-3} mb={10}>
                  <Text fontSize={"sm"} color={"gray.500"} px={"10px"}>
                    Usage resets on {getFormatDate(accountInformation.usage.monthlyEndDate)}
                  </Text>
                  {accountInformation.currentSubscription.subscriptionChange && (
                    <Text bg={"orange.200"} fontSize={"xs"} rounded={"full"} px={"25px"} py={"10px"} w={"100%"}>
                      Your {accountInformation?.currentSubscription?.tier} Plan benefits are active until {getFormatDate(accountInformation.currentSubscription.subscriptionChange.scheduledDate)}
                    </Text>
                  )}
                </VStack>
                <Flex color={"gray.700"} mt={4} mb={3}>
                  <Text as="b" fontSize={"sm"}>
                    Monthly Orders
                  </Text>
                  <Spacer />
                  <Text as="b" fontSize={"sm"} color={accountInformation.usage.used >= accountInformation.usage.total ? "red.600" : ""}>
                    {accountInformation.usage.used}/{accountInformation.usage.total}
                  </Text>
                </Flex>
                <Progress colorScheme={accountInformation.usage.used >= accountInformation.usage.total ? "red" : "brand"} size="md" value={(accountInformation.usage.used / accountInformation.usage.total) * 100} h={"12px"} w={"100%"} rounded={"full"} />
                <Text as="b" fontSize={"sm"} color={"red.600"} display={accountInformation.usage.used >= accountInformation.usage.total ? "" : "none"}>
                  Limit reached
                </Text>
              </Box>
              <Button
                onClick={() => {
                  navigate("/change-plan");
                  logEvent("Plan Usage", "Click", "Change Plan");
                }}
                colorScheme={"brand"}
                variant={"outline"}
                width={"140px"}
                size={"sm"}
                rounded={"full"}
              >
                Change Plan
              </Button>
              <Box>
                <Text fontSize={"sm"} color={"gray.500"}>
                  For billing inquiries or further assistance, please reach out to
                </Text>
                <Link
                  fontSize={"sm"}
                  textDecoration={"underline"}
                  color={"gray.500"}
                  onClick={() => {
                    handleBillingContactClick();
                    logEvent("Plan Usage", "Click", "Billing Mail");
                  }}
                  _hover={{
                    color: "black",
                  }}
                >
                  billing@brandzway.com
                </Link>
              </Box>
            </VStack>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
