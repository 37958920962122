import { gql, useLazyQuery } from "@apollo/client";
import { ProductReviewDetails } from "../appsync/API";
import { getProductReviewsForDashboard } from "../appsync/graphql/queries";

export const useGetProductReviewsForDashboard = () => {
  const [call, { loading, error, data }] = useLazyQuery<{ getProductReviewsForDashboard: ProductReviewDetails }>(gql(getProductReviewsForDashboard), {
    fetchPolicy: "no-cache",
  });

  return {
    getProductReviewsForDashboard: call,
    getProductReviewsForDashboardData: data?.getProductReviewsForDashboard,
    getProductReviewsForDashboardLoading: loading,
    getProductReviewsForDashboardError: error,
  };
};
