import { Text, VStack, Spinner } from "@chakra-ui/react";

export const LoadingSpinnerAndText = ({ textToShow }: { textToShow: string }) => {
  return (
    <VStack pt={"40px"} px={"20px"} pb={"20px"} bg={"white"} borderRadius={"3xl"}>
      <Spinner color="brand.400" size={"xl"} thickness="4px" emptyColor="brand.100" />
      <Text fontSize={"md"} color={"brand.400"} p={5} fontWeight={"500"}>
        {textToShow}
      </Text>
    </VStack>
  );
};
