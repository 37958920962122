import { useEffect, useState } from "react";
import "../styles/App.css";
import { useToast } from "@chakra-ui/react";
import { Routes, Route } from "react-router-dom";
import Signup from "./users/signup";
import Login from "./users/login";
import Orders from "./tabs/orders";
import Customize from "./tabs/customize";
import UserVerification from "./users/user-verification";
import { useNavigate } from "react-router-dom";

import amplifyConfig from "../deployment/amplify-config";
import { Amplify, Auth, Hub } from "aws-amplify";
import SubmitReview from "./submit-review/submit-review";
import ManageReviews from "./tabs/dashboard-reviews";
import ForgotPassword from "./users/forgot-password";
import ResetPassword from "./users/reset-password";
import Analytics from "./tabs/analytics";
import { AddSite } from "./one-time-pages/add-site";
import { NewUserSetup } from "./one-time-pages/new-user-setup";
import { CreateSite } from "./one-time-pages/create-site";
import { useGetAccountInformation } from "../hooks/get-account-information";
import { AccountInformationSite } from "../types/account";
import { FourOFour } from "./404";
import { FirstTimeSetup } from "./one-time-pages/first-time-setup";
import HowToInstall from "./tabs/how-to-install";
import Checkout from "./subscription/checkout";
import HomePricing from "./home/home-pricing";
Amplify.configure(amplifyConfig);

function App() {
  const navigate = useNavigate();
  const toast = useToast();
  const { getAccountInformation, getAccountInformationData, getAccountInformationLoading, getAccountInformationError, isCalled } = useGetAccountInformation();
  const [accountInformation, setAccountInformation] = useState<any>(null);
  const [currentSelectedSite, setCurrentSelectedSite] = useState<any>(null);

  const handleFetchAccountInformation = async () => {
    if (!isCalled) {
      getAccountInformation();
    }
  };

  const switchSelectedSite = (val: AccountInformationSite) => {
    setCurrentSelectedSite(val);
  };

  useEffect(() => {
    if (getAccountInformationData) {
      setAccountInformation(getAccountInformationData);
      if (getAccountInformationData.sites?.length == 0) {
        if (!isOnCreateSitePage()) {
          navigate("/add-site");
        }
        return;
      }

      if (getAccountInformationData.sites?.length) {
        switchSelectedSite(getAccountInformationData.sites[0] as AccountInformationSite);
      }
    }
    if (getAccountInformationError) {
      const id = "account-information-error-toast";
      if (toast.isActive(id)) {
        return;
      }
      toast({
        id,
        title: "Hey there! We've encountered a technical hiccup, but don't worry - our team is already on it. Please try again later, and thanks for bearing with us!",
        status: "error",
        position: "top",
        duration: null,
        isClosable: true,
      });
    }
  }, [getAccountInformationData, getAccountInformationLoading, getAccountInformationError]);

  const redirectsForLoggedInUser = () => {
    if (window.location.pathname == "/" || window.location.pathname == "/signup" || window.location.pathname == "/forgot-password" || window.location.pathname == "/reset-password" || window.location.pathname == "/verify") {
      const code = getCode();
      if (code) {
        localStorage.setItem("phrase", code);
        navigate("/create-site", { replace: true });
      } else {
        navigate("/how-to-install", { replace: true });
      }
    }

    handleFetchAccountInformation();
  };

  const redirectsForPublicUser = () => {
    if (window.location.pathname == "/signup" || window.location.pathname == "/submit-review") {
      const code = getCode();
      if (code) {
        localStorage.setItem("phrase", code);
      }
    } else {
      navigate("/login", { replace: true });
    }
  };

  const getCode = (): string | null => {
    const { pathname, search } = window.location;
    if (pathname !== "/signup") {
      return null;
    }
    const urlSearchParams = new URLSearchParams(search);
    const code = urlSearchParams.get("bCode");
    return code || null;
  };

  const isOnCreateSitePage = () => {
    return window.location.pathname == "/create-site";
  };

  const getUserSession = async () => {
    try {
      await Auth.currentSession();
      redirectsForLoggedInUser();
    } catch (error) {
      redirectsForPublicUser();
    }
  };

  function hubListener() {
    Hub.listen("auth", ({ payload }) => {
      let { event } = payload;
      switch (event) {
        case "autoSignIn":
          navigate("/create-site", { replace: true });
          break;
        case "autoSignIn_failure":
          navigate("/login", { replace: true });
          break;
      }
    });
  }

  useEffect(() => {
    getUserSession();
    hubListener();
  }, []);

  return (
    <Routes>
      <Route path="/" element={<></>} />
      <Route path="/signup" element={<Signup />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/reset-password" element={<ResetPassword />} />
      <Route path="/login" element={<Login fetchAccountInformation={handleFetchAccountInformation} />} />
      <Route path="/verify" element={<UserVerification />} />
      <Route path="/create-site" element={<CreateSite getFreshAccountInformation={() => getAccountInformation()} />} />
      <Route path="/add-site" element={<AddSite />} />
      <Route path="/new-user-setup" element={<NewUserSetup />} />
      <Route path="/first-time-setup" element={<FirstTimeSetup />} />
      <Route path="/orders" element={<Orders accountInformation={accountInformation} selectedSite={currentSelectedSite} switchSelectedSite={switchSelectedSite} />} />
      <Route path="/reviews" element={<ManageReviews accountInformation={accountInformation} selectedSite={currentSelectedSite} switchSelectedSite={switchSelectedSite} />} />
      <Route path="/analytics" element={<Analytics accountInformation={accountInformation} selectedSite={currentSelectedSite} switchSelectedSite={switchSelectedSite} />} />
      <Route path="/customize" element={<Customize accountInformation={accountInformation} selectedSite={currentSelectedSite} switchSelectedSite={switchSelectedSite} />} />
      <Route path="/how-to-install" element={<HowToInstall accountInformation={accountInformation} selectedSite={currentSelectedSite} switchSelectedSite={switchSelectedSite} />} />
      <Route path="/submit-review" element={<SubmitReview />} />
      <Route path="/change-plan" element={<HomePricing isChangePlan={true} accountInformation={accountInformation} />} />
      <Route path="/checkout" element={<Checkout fetchAccountInformation={handleFetchAccountInformation} />} />
      <Route path="*" element={<FourOFour />} />
    </Routes>
  );
}

export default App;
