const HOSTED_URL = "http://localhost:3000";

const configDev = {
  HOSTED_URL,
  MODE: "LOCAL",
  REDIRECT_SIGN_IN: `${HOSTED_URL}/`,
  REDIRECT_SIGN_OUT: `${HOSTED_URL}/signout/`,
  REGION: "us-east-1",
  AUTHENTICATION_TYPE: "AWS_IAM" as const,
  USER_POOL_CLIENT_ID: "5f6hpgbbajnalrquu46hd7f40g",
  USER_POOL_ID: "us-east-1_zzYQMijFt",
  IDENTITY_POOL_ID: "us-east-1:7b8273a7-7368-4fb8-a063-154559ff8fb5",
  GRAPHQL_ENDPOINT: "https://gql-dev.brandzway.com/graphql",
  MEDIA_BASE_URL: "https://media-dev.brandzway.com",
  API_ENDPOINT: "https://api-dev.brandzway.com",
  PAYPAL_CLIENT_ID: "AYATdyPCtaKhT0y5kLS7EtOEyBorMbOmWnUG8ay9PDbB0lMzpRzzmBsx3TiXeBASv3FLxdAUfbKRlqvD",
};

export default configDev;
