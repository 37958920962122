import { Flex, Box, FormControl, FormLabel, Input, InputGroup, HStack, InputRightElement, Stack, Button, Heading, Text, useColorModeValue, Link, FormErrorMessage, Popover, PopoverTrigger, PopoverContent, PopoverArrow, PopoverBody, Icon } from "@chakra-ui/react";
import { Auth } from "aws-amplify";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import passwordValidator from "password-validator";
import { useForm } from "react-hook-form";
import { TiTick } from "react-icons/ti";
import { useToast } from "@chakra-ui/react";
import { FiXCircle } from "react-icons/fi";
import React from "react";

export default function ResetPassword(): JSX.Element {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [passwordPolicy, setPasswordPolicy] = useState([false, false, false, false, false]);
  const [isPasswordValid, setPasswordValid] = useState(false);
  const [firstPassword, setFirstPassword] = useState("");
  const [isPasswordMatch, setPasswordMatch] = useState(false);
  const passwordRef = React.useRef<HTMLInputElement>(null);
  const confirmPasswordRef = React.useRef<HTMLInputElement>(null);
  const [isPasswordPopOverOpen, setPasswordPopOverOpen] = useState(false);
  const [isConfirmPasswordPopOverOpen, setConfirmPasswordPopOverOpen] = useState(false);
  const [isResetPasswordInProgress, setIsResetPasswordInProgress] = useState(false);

  const username = localStorage.getItem("username") || "";
  const navigate = useNavigate();
  const toast = useToast();

  // create a password schema
  const schema = new passwordValidator();
  schema.is().min(8).has().uppercase().has().lowercase().has().digits().has().symbols();

  const valdiateFirstPassword = (event: any) => {
    let tempfirstPassword = event.target.value;
    setFirstPassword(tempfirstPassword);
    const validationRulesErrors = schema.validate(tempfirstPassword, { list: true });
    handlePasswordValidateError(validationRulesErrors);
  };

  const valdiateSecondPassword = (event: any) => {
    const secondPassword = event.target.value;
    if (firstPassword == secondPassword) {
      setPasswordMatch(true);
    } else {
      setPasswordMatch(false);
    }
  };

  const { register, handleSubmit } = useForm();

  const handlePasswordValidateError = (errors: any) => {
    let newPasswordPolicy: Array<boolean> = [true, true, true, true, true];
    for (let i = 0; i < errors.length; i++) {
      if (errors[i] === "min") {
        newPasswordPolicy[0] = false;
      } else if (errors[i] === "lowercase") {
        newPasswordPolicy[1] = false;
      } else if (errors[i] === "uppercase") {
        newPasswordPolicy[2] = false;
      } else if (errors[i] === "digits") {
        newPasswordPolicy[3] = false;
      } else if (errors[i] === "symbols") {
        newPasswordPolicy[4] = false;
      }
    }

    let checker: boolean = newPasswordPolicy.every((v) => v === true);
    setPasswordValid(checker);
    setPasswordPolicy(newPasswordPolicy);
  };

  const handleException = (error: any) => {
    let message = "Something went wrong.";

    if (error?.code == "CodeMismatchException") {
      message = "Code did'nt match.";
    } else if (error?.code == "ExpiredCodeException") {
      message = "Code Expired.";
    } else if (error?.code == "TooManyFailedAttemptsException") {
      message = "Too many attempts.";
    }

    toast({
      title: message,
      status: "error",
      position: "bottom",
      duration: 5000,
      isClosable: true,
    });
  };

  async function onSubmit(values: any) {
    const password = values["password"];
    const confirmPassword = values["confirmPassword"];
    const code = values["code"];

    if (!isPasswordValid) {
      setPasswordPopOverOpen(true);
    }
    if (password !== confirmPassword) {
      setConfirmPasswordPopOverOpen(true);
      setPasswordMatch(false);
    } else {
      setPasswordMatch(true);
    }

    if (!(isPasswordValid && isPasswordMatch)) {
      return;
    }

    setIsResetPasswordInProgress(true);
    await Auth.forgotPasswordSubmit(username, code, password)
      .then(() => {
        localStorage.removeItem("username");
        navigate("/login", { replace: true, state: { stateChangeReason: "password-reset" } });
      })
      .catch((error) => {
        console.log(error.code);
        handleException(error);
      })
      .finally(() => {
        setIsResetPasswordInProgress(false);
      });
  }
  return (
    <Flex minH={"100vh"} align={"center"} justify={"center"} bg={useColorModeValue("gray.50", "gray.800")}>
      <Stack spacing={4} w={"full"} maxW={"md"} bg={useColorModeValue("white", "gray.700")} rounded={"xl"} boxShadow={"lg"} p={6} my={12}>
        <Heading lineHeight={1.1} fontSize={{ base: "2xl", md: "3xl" }}>
          Enter new password
        </Heading>
        <Stack spacing={4}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormControl id="verification-otp" isRequired py={3}>
              <FormLabel>Verification OTP</FormLabel>
              <Input
                type="number"
                isDisabled={isResetPasswordInProgress}
                {...register("code", {
                  required: "Please enter verification OTP.",
                })}
              />
            </FormControl>
            <FormControl id="password" isRequired isDisabled={isResetPasswordInProgress} py={3}>
              <FormLabel>Password</FormLabel>
              <Popover placement="right" initialFocusRef={passwordRef} isOpen={isPasswordPopOverOpen}>
                <PopoverTrigger>
                  <InputGroup ref={passwordRef}>
                    <Input
                      type={showPassword ? "text" : "password"}
                      onFocus={() => setPasswordPopOverOpen(true)}
                      {...register("password", {
                        required: "Please enter the password.",
                        onChange: valdiateFirstPassword,
                        onBlur: () => isPasswordValid && setPasswordPopOverOpen(false),
                        minLength: { value: 8, message: "Invalid Password" },
                      })}
                    />
                    <InputRightElement h={"full"}>
                      <Button variant={"ghost"} onClick={() => setShowPassword((showPassword) => !showPassword)}>
                        {showPassword ? <ViewIcon /> : <ViewOffIcon />}
                      </Button>
                    </InputRightElement>
                  </InputGroup>
                </PopoverTrigger>
                <PopoverContent maxW={260}>
                  <PopoverArrow />
                  <PopoverBody fontSize={"0.9rem"}>
                    <PasswordPolicyItem message="Be at least 8 Characters" policyStatus={passwordPolicy[0]} />
                    <PasswordPolicyItem message="At least one Lowercase letter" policyStatus={passwordPolicy[1]} />
                    <PasswordPolicyItem message="At least one Uppercase letter" policyStatus={passwordPolicy[2]} />
                    <PasswordPolicyItem message="At least one digit(0-9) letter" policyStatus={passwordPolicy[3]} />
                    <PasswordPolicyItem message="At least one special character" policyStatus={passwordPolicy[4]} />
                  </PopoverBody>
                </PopoverContent>
              </Popover>
            </FormControl>
            <FormControl id="confirmPassword" isRequired isDisabled={isResetPasswordInProgress} py={3}>
              <FormLabel>Confirm Password</FormLabel>
              <Popover placement="right" initialFocusRef={confirmPasswordRef} isOpen={!isPasswordMatch && isConfirmPasswordPopOverOpen}>
                <PopoverTrigger>
                  <InputGroup ref={confirmPasswordRef}>
                    <Input
                      type={showConfirmPassword ? "text" : "password"}
                      onFocus={() => setConfirmPasswordPopOverOpen(true)}
                      {...register("confirmPassword", {
                        required: "Please enter the password.",
                        minLength: { value: 8, message: "Invalid Password" },
                        onChange: valdiateSecondPassword,
                        onBlur: () => isPasswordMatch && setConfirmPasswordPopOverOpen(false),
                      })}
                    />
                    <InputRightElement h={"full"}>
                      <Button variant={"ghost"} onClick={() => setShowConfirmPassword((showConfirmPassword) => !showConfirmPassword)}>
                        {showConfirmPassword ? <ViewIcon /> : <ViewOffIcon />}
                      </Button>
                    </InputRightElement>
                  </InputGroup>
                </PopoverTrigger>
                <PopoverContent maxW={200}>
                  <PopoverArrow />
                  <PopoverBody fontSize={"0.8rem"}>
                    <PasswordPolicyItem message="Password do not match." policyStatus={false} />
                  </PopoverBody>
                </PopoverContent>
              </Popover>
            </FormControl>
            <Stack spacing={6} mt={8}>
              <Button
                type={"submit"}
                bg={"brand.500"}
                color={"white"}
                _hover={{
                  bg: "brand.600",
                }}
                isLoading={isResetPasswordInProgress}
              >
                Submit
              </Button>
            </Stack>
          </form>
        </Stack>
      </Stack>
    </Flex>
  );
}

const PasswordPolicyItem = ({ message, policyStatus, children, ...rest }: any) => {
  return (
    <p style={{ color: policyStatus ? "green" : "red" }}>
      {!policyStatus && <Icon mr="2" fontSize="12" as={FiXCircle} color={"red"} />}
      {policyStatus && <Icon mr="1" fontSize="14" as={TiTick} color={"green"} />}
      {message}
    </p>
  );
};
