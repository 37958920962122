import {
  Alert,
  AlertIcon,
  Badge,
  Box,
  Button,
  Checkbox,
  Collapse,
  Divider,
  Flex,
  FormErrorMessage,
  Heading,
  Highlight,
  HStack,
  Icon,
  Input,
  InputGroup,
  InputLeftAddon,
  InputLeftElement,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Radio,
  RadioGroup,
  Skeleton,
  Slider,
  SliderFilledTrack,
  SliderMark,
  SliderThumb,
  SliderTrack,
  Spacer,
  Spinner,
  Stack,
  Switch,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tag,
  Text,
  Textarea,
  useDisclosure,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { MdOutlineWidgets, MdOutlineSettings, MdOutlineMailOutline } from "react-icons/md";
import Navbar from "../../components/navbar";
import { FaStar, FaFacebookF, FaInstagram, FaTwitter, FaTiktok } from "react-icons/fa";
import { ChromePicker } from "react-color";
import { ChevronDownIcon, DownloadIcon } from "@chakra-ui/icons";
import { FaMobileAlt } from "react-icons/fa";
import { IoDesktopOutline } from "react-icons/io5";
import { useGetSiteSettings } from "../../hooks/get-site";
import { Email, EmailTriggerAt, ReviewButtonType, Settings, Widget, WidgetLayout } from "../../types/site";
import { isEqual, pickBy, omit, isEmpty, assign, map } from "lodash";
import { useUpdateSiteSettings } from "../../hooks/update-site-settings";
import { PremiumFeature } from "../../components/premium-feature";
import { AssetType, FileUploader, FileUploaderSettings, UploadType } from "../../components/file-uploader";
import React from "react";
import { AccountInformationSite } from "../../types/account";
import { PlanLimitReached } from "../../components/plan-limit-reached";
import { productReviewEmailTemplate } from "../../utils/preview-emails/product-review";
import { LoadingSpinnerAndText } from "../../components/loading-spinner-and-text";
import { config } from "../../deployment/config";
import { allowPremiumFeatures } from "../../utils/helpers";
import { ErrorOccurred } from "../../components/error-occurred";

export default function Customize({ accountInformation, selectedSite, switchSelectedSite }: { accountInformation: any; selectedSite: AccountInformationSite; switchSelectedSite: any }) {
  const { getSite, getSiteSettingsData, getSiteSettingsLoading, getSiteSettingsError, isCalled } = useGetSiteSettings();

  useEffect(() => {
    if (selectedSite) {
      getSite({ variables: { siteId: selectedSite.siteId } });
    }
  }, [selectedSite]);

  return (
    <Navbar
      tabName="Customize"
      children={
        isCalled && (
          <>
            {getSiteSettingsLoading && (
              <Flex minH={"100vh"} align={"center"} justify={"center"}>
                <LoadingSpinnerAndText textToShow={"Fetching settings..."}></LoadingSpinnerAndText>
              </Flex>
            )}
            {getSiteSettingsError && (
              <Flex minH={"100vh"} justify={"center"}>
                <ErrorOccurred></ErrorOccurred>
              </Flex>
            )}
            {getSiteSettingsData && <MainContainer getSiteSettingsData={getSiteSettingsData} isLoadingSiteSettings={getSiteSettingsLoading} isCalled={isCalled} selectedSite={selectedSite} accountInformation={accountInformation}></MainContainer>}
          </>
        )
      }
      accountInformation={accountInformation}
      selectedSite={selectedSite}
      switchSelectedSite={switchSelectedSite}
    ></Navbar>
  );
}

const MainContainer = ({ getSiteSettingsData, isLoadingSiteSettings, isCalled, selectedSite, accountInformation }: { getSiteSettingsData: any; isLoadingSiteSettings: boolean; isCalled: boolean; selectedSite: any; accountInformation: any }) => {
  const [siteSettingsOriginal, setSiteSettingsOriginal] = useState(getSiteSettingsData);
  const [siteSettings, setSiteSettings] = useState(getSiteSettingsData);
  const { updateSiteSettings, updateSiteSettingsData, updateSiteSettingsLoading, updateSiteSettingsError } = useUpdateSiteSettings();
  const toast = useToast();
  const [forceRefresh, setForceRefresh] = useState(true);
  const [tabIndex, setTabIndex] = useState(0);

  useEffect(() => {}, [siteSettings]);

  const saveChanges = () => {
    const inputSettings = pickBy(siteSettings.settings, (v, k) => !isEqual(siteSettingsOriginal.settings[k], v));
    const inputEmail = pickBy(siteSettings.email, (v, k) => !isEqual(siteSettingsOriginal.email[k], v));
    const inputWidget = pickBy(siteSettings.widget, (v, k) => !isEqual(siteSettingsOriginal.widget[k], v));

    const input: any = {};
    if (!isEmpty(inputSettings)) {
      input.settings = omit(inputSettings, "__typename");
    }
    if (!isEmpty(inputEmail)) {
      input.email = omit(inputEmail, "__typename");
    }
    if (!isEmpty(inputWidget)) {
      input.widget = omit(inputWidget, "__typename");
    }

    const siteSettingCopy = assign({}, siteSettings);
    updateSiteSettings({ variables: { siteId: selectedSite.siteId, siteSettings: input } })
      .then(() => {
        setSiteSettingsOriginal(siteSettingCopy);
        toast({
          title: "Changes Saved",
          status: "success",
          position: "bottom",
          duration: 1000,
          isClosable: true,
        });
      })
      .catch(() => {
        toast({
          title: "Failed to save the changes",
          status: "error",
          position: "bottom",
          duration: 1000,
          isClosable: true,
        });
      });
  };

  const ResetChanges = () => {
    setSiteSettings(siteSettingsOriginal);
    const oldTabIndex = tabIndex;
    setForceRefresh(false);
    setTimeout(() => {
      setForceRefresh(true);
      setTabIndex(oldTabIndex);
    }, 800);
  };

  return (
    <>
      {isCalled && forceRefresh && !isLoadingSiteSettings ? (
        <>
          <PlanLimitReached isUsageLimitReached={accountInformation && accountInformation.usage.used >= accountInformation.usage.total}></PlanLimitReached>
          <Box minH="100vh" p={4} bg="#edf2f7" w="100%">
            <Box bg={"white"} border="1px" borderRadius={"lg"} borderColor={"#e5e5e5"} p={4}>
              <Heading size="md" m={5} ml={1}>
                Customize
              </Heading>
              <Tabs size="sm" variant="soft-rounded" colorScheme="brand" isLazy index={tabIndex} onChange={(index) => setTabIndex(index)}>
                <TabList>
                  <Tab py={1} mr={3} borderRadius={"lg"}>
                    <Box as="span" ml="1" mr={2} mt={1}>
                      <Icon as={MdOutlineWidgets}></Icon>
                    </Box>
                    Widget
                  </Tab>
                  <Tab py={1} mr={3} borderRadius={"lg"}>
                    <Box as="span" ml="1" mr={2} mt={1}>
                      <Icon as={MdOutlineMailOutline}></Icon>
                    </Box>
                    Email
                  </Tab>
                  <Tab py={1} mr={3} borderRadius={"lg"}>
                    <Box as="span" ml="1" mr={2} mt={1}>
                      <Icon as={MdOutlineSettings}></Icon>
                    </Box>
                    Settings
                  </Tab>
                </TabList>
                <Divider orientation="horizontal" my={5} />

                <TabPanels>
                  <TabPanel>
                    <p>
                      <WidgetTab
                        currentSubscription={accountInformation.currentSubscription}
                        widget={siteSettings.widget}
                        setWidget={(newWidget: any) => {
                          const widget = { ...siteSettings.widget, [Object.keys(newWidget)[0]]: Object.values(newWidget)[0] };
                          setSiteSettings({
                            ...siteSettings,
                            widget,
                          });
                        }}
                      />
                    </p>
                  </TabPanel>
                  <TabPanel>
                    <p>
                      <EmailTab
                        currentSubscription={accountInformation.currentSubscription}
                        siteId={selectedSite.siteId}
                        email={siteSettings.email}
                        removeBrandzwayBranding={siteSettings.widget.removeBrandzwayBranding}
                        setEmail={(newEmail: any) => {
                          const email = { ...siteSettings.email, [Object.keys(newEmail)[0]]: Object.values(newEmail)[0] };
                          setSiteSettings({
                            ...siteSettings,
                            email,
                          });
                        }}
                      />
                    </p>
                  </TabPanel>
                  <TabPanel>
                    <p>
                      <SettingsTab
                        currentSubscription={accountInformation.currentSubscription}
                        settings={siteSettings.settings}
                        setSettings={(newSettings: any) => {
                          const settings = { ...siteSettings.settings, [Object.keys(newSettings)[0]]: Object.values(newSettings)[0] };
                          setSiteSettings({
                            ...siteSettings,
                            settings,
                          });
                        }}
                      />
                    </p>
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </Box>
            <HStack py={5}>
              <Button colorScheme={"brand"} isDisabled={isEqual(siteSettingsOriginal, siteSettings) || updateSiteSettingsLoading} onClick={saveChanges} isLoading={updateSiteSettingsLoading}>
                Save changes
              </Button>
              <Button colorScheme={"brand"} variant="outline" isDisabled={updateSiteSettingsLoading} onClick={ResetChanges}>
                Reset
              </Button>
            </HStack>
          </Box>
        </>
      ) : (
        <Box h={"100%"} p={20}>
          <Stack>
            <Skeleton height="40px" />
            <Skeleton height="40px" />
            <Skeleton height="40px" />
          </Stack>
        </Box>
      )}
    </>
  );
};

const SettingsTab = ({ currentSubscription, settings, setSettings }: { currentSubscription: any; settings: Settings; setSettings: any }) => {
  const [autoSendReviewEmails, setAutoSendReviewEmails] = useState(settings.autoSendReviewEmails);
  const [emailDelay, setEmailDelay] = useState(settings.emailDelay);
  const [emailTriggerAt, setEmailTriggerAt] = useState(settings.emailTriggerAt);

  const [autoPublishReview, setAutoPublishReview] = useState(settings.autoPublishReview);
  const [starRatingForAutoPublishReview, setStarRatingForAutoPublishReview] = useState(settings.starRatingForAutoPublishReview);

  const [notifyOnNewReview, setNotifyOnNewReview] = useState(settings.notifyOnNewReview);
  const [notifyOn1Star, setNotifyOn1Star] = useState(settings.notifyOn1Star);
  const [notifyOn2Star, setNotifyOn2Star] = useState(settings.notifyOn2Star);
  const [notifyOn3Star, setNotifyOn3Star] = useState(settings.notifyOn3Star);
  const [notifyOn4Star, setNotifyOn4Star] = useState(settings.notifyOn4Star);
  const [notifyOn5Star, setNotifyOn5Star] = useState(settings.notifyOn5Star);

  const [replyToEmail, setReplyToEmail] = useState(settings.replyToEmail);
  const [siteName, setSiteName] = useState(settings.siteName);

  const updateSettingsInParent = (val: any) => {
    setSettings(val);
  };

  return (
    <>
      <Heading size="md" mt={5}>
        <Highlight query="Send Reviews" styles={{ px: "4", py: "2", rounded: "lg", bg: "red.100", fontSize: "lg" }}>
          Send Reviews
        </Highlight>
      </Heading>
      <VStack align={"stretch"} w={"50%"} divider={<hr />}>
        <Flex gap="2" my={8}>
          <Box p="2">
            <VStack align={"left"}>
              <Text as="b">Automatically send review emails</Text>
              <Text>We'll send review emails automatically. Turn this off if you want to send review emails manually.</Text>
            </VStack>
          </Box>
          <Spacer />
          <Switch
            aria-label="Automatically send review emails"
            size="lg"
            isDisabled={!allowPremiumFeatures(currentSubscription)}
            isChecked={autoSendReviewEmails}
            onChange={() => {
              setAutoSendReviewEmails(!autoSendReviewEmails);
              updateSettingsInParent({ autoSendReviewEmails: !autoSendReviewEmails });
            }}
            colorScheme="brand"
          />
          <PremiumFeature isPremiumUser={allowPremiumFeatures(currentSubscription)}></PremiumFeature>
        </Flex>
        <Flex gap="2" my={8} color={autoSendReviewEmails ? "" : "gray.400"}>
          <Box p="2">
            <VStack align={"left"}>
              <Text as="b">Review email delay</Text>
              <Text>Number of days after order has been placed the review email will be send.</Text>
              <Text fontSize={"sm"}>( Max limit 60 days ).</Text>
            </VStack>
          </Box>
          <Spacer />
          <Box>
            <VStack spacing={20} alignItems={"flex-end"}>
              <NumberInput
                aria-label="Email delay count"
                size="md"
                maxW={24}
                defaultValue={emailDelay}
                min={1}
                max={60}
                isDisabled={!autoSendReviewEmails}
                onChange={(value) => {
                  setEmailDelay(Number(value));
                  updateSettingsInParent({ emailDelay: value });
                }}
              >
                <NumberInputField />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
              <HStack spacing={5}>
                <Text as="b">After: </Text>
                <Menu>
                  <MenuButton as={Button} color="brand.800" rightIcon={<ChevronDownIcon />} isDisabled={!autoSendReviewEmails}>
                    {emailTriggerAt}
                  </MenuButton>
                  <MenuList>
                    <MenuItem
                      onClick={() => {
                        setEmailTriggerAt(EmailTriggerAt.placed);
                        updateSettingsInParent({ emailTriggerAt: EmailTriggerAt.placed });
                      }}
                    >
                      Placed
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        setEmailTriggerAt(EmailTriggerAt.fulfilled);
                        updateSettingsInParent({ emailTriggerAt: EmailTriggerAt.fulfilled });
                      }}
                    >
                      Fulfilled
                    </MenuItem>
                  </MenuList>
                </Menu>
              </HStack>
            </VStack>
          </Box>
        </Flex>
      </VStack>
      <Text color={"gray.500"} fontWeight={500}>
        It may take up to 15 minutes for any changes made to this setting to be reflected.
      </Text>
      <Divider orientation="horizontal" my={10} />

      <Heading size="md" mt={5}>
        <Highlight query="Publish Reviews" styles={{ px: "4", py: "2", rounded: "lg", bg: "red.100", fontSize: "lg" }}>
          Publish Reviews
        </Highlight>
      </Heading>
      <VStack align={"stretch"} w={"50%"} divider={<hr />}>
        <Flex gap="2" my={8}>
          <Box p="2">
            <VStack align={"left"}>
              <Text as="b">Publish reviews automatically</Text>
              <Text>Automatically publish reviews with following settings. Currently set to {starRatingForAutoPublishReview} stars & up</Text>
              <Slider
                aria-label="Slider head for publish reviews automatically"
                defaultValue={4}
                min={1}
                max={5}
                step={1}
                h={100}
                onChange={(val) => {
                  setStarRatingForAutoPublishReview(val);
                  updateSettingsInParent({ starRatingForAutoPublishReview: val });
                }}
                isDisabled={!autoPublishReview}
              >
                <SliderMark value={starRatingForAutoPublishReview} textAlign="center" bg="orange.100" color="white" mt="-2" ml="-45" w="20">
                  {[1, 2, 3, 4, 5].map((idx) => {
                    return idx <= starRatingForAutoPublishReview && <Icon fontSize="16" as={FaStar} color={"orange"} />;
                  })}
                </SliderMark>
                <SliderTrack bg="brand.100">
                  <Box position="relative" right={10} />
                  <SliderFilledTrack bg="brand.400" />
                </SliderTrack>
                <SliderThumb boxSize={6}></SliderThumb>
              </Slider>
            </VStack>
          </Box>
          <Spacer />
          <Switch
            aria-label="Automatically Publish Reviews"
            colorScheme="brand"
            size="lg"
            isChecked={autoPublishReview}
            onChange={() => {
              setAutoPublishReview(!autoPublishReview);
              updateSettingsInParent({ autoPublishReview: !autoPublishReview });
            }}
          />
        </Flex>
      </VStack>
      <Divider orientation="horizontal" my={10} />

      {/* Future todo v2 */}
      {/* <Heading size="md" mt={5}>
        <Highlight query="Notifications" styles={{ px: "4", py: "2", rounded: "lg", bg: "red.100", fontSize: "lg" }}>
          Notifications
        </Highlight>
      </Heading>
      <VStack align={"stretch"} w={"50%"} divider={<hr />}>
        <Flex gap="2" my={8}>
          <Box p="2">
            <VStack align={"left"}>
              <HStack alignItems={"center"}>
                <Text as="b">Notify on new reviews</Text>
                <PremiumFeature isPremiumUser={allowPremiumFeatures(currentSubscription)}></PremiumFeature>
              </HStack>
              <Text>Notify me with an email on receiving reviews with following star ratings.</Text>
              <Checkbox
                size="md"
                colorScheme="brand"
                isDisabled={!notifyOnNewReview}
                isChecked={notifyOn1Star}
                onChange={(e) => {
                  setNotifyOn1Star(e.target.checked);
                  updateSettingsInParent({ setNotifyOn1Star: e.target.checked });
                }}
              >
                <Icon fontSize="16" as={FaStar} color={"orange"} />
              </Checkbox>
              <Checkbox
                size="md"
                colorScheme="brand"
                isDisabled={!notifyOnNewReview}
                isChecked={notifyOn2Star}
                onChange={(e) => {
                  setNotifyOn2Star(e.target.checked);
                  updateSettingsInParent({ setNotifyOn2Star: e.target.checked });
                }}
              >
                <Icon fontSize="16" as={FaStar} color={"orange"} />
                <Icon fontSize="16" as={FaStar} color={"orange"} />
              </Checkbox>
              <Checkbox
                size="md"
                colorScheme="brand"
                isDisabled={!notifyOnNewReview}
                isChecked={notifyOn3Star}
                onChange={(e) => {
                  setNotifyOn3Star(e.target.checked);
                  updateSettingsInParent({ setNotifyOn3Star: e.target.checked });
                }}
              >
                <Icon fontSize="16" as={FaStar} color={"orange"} />
                <Icon fontSize="16" as={FaStar} color={"orange"} />
                <Icon fontSize="16" as={FaStar} color={"orange"} />
              </Checkbox>
              <Checkbox
                size="md"
                colorScheme="brand"
                isDisabled={!notifyOnNewReview}
                isChecked={notifyOn4Star}
                onChange={(e) => {
                  setNotifyOn4Star(e.target.checked);
                  updateSettingsInParent({ setNotifyOn4Star: e.target.checked });
                }}
              >
                <Icon fontSize="16" as={FaStar} color={"orange"} />
                <Icon fontSize="16" as={FaStar} color={"orange"} />
                <Icon fontSize="16" as={FaStar} color={"orange"} />
                <Icon fontSize="16" as={FaStar} color={"orange"} />
              </Checkbox>
              <Checkbox
                size="md"
                colorScheme="brand"
                isDisabled={!notifyOnNewReview}
                isChecked={notifyOn5Star}
                onChange={(e) => {
                  setNotifyOn5Star(e.target.checked);
                  updateSettingsInParent({ setNotifyOn5Star: e.target.checked });
                }}
              >
                <Icon fontSize="16" as={FaStar} color={"orange"} />
                <Icon fontSize="16" as={FaStar} color={"orange"} />
                <Icon fontSize="16" as={FaStar} color={"orange"} />
                <Icon fontSize="16" as={FaStar} color={"orange"} />
                <Icon fontSize="16" as={FaStar} color={"orange"} />
              </Checkbox>
            </VStack>
          </Box>
          <Spacer />
          <Switch
            colorScheme="brand"
            size="lg"
            isChecked={notifyOnNewReview}
            onChange={() => {
              setNotifyOnNewReview(!notifyOnNewReview);
              updateSettingsInParent({ notifyOnNewReview: !notifyOnNewReview });
            }}
          />
        </Flex>
      </VStack>
      <Divider orientation="horizontal" my={10} /> */}

      <Heading size="md" mt={5}>
        <Highlight query="Engage with customers" styles={{ px: "4", py: "2", rounded: "lg", bg: "red.100", fontSize: "lg" }}>
          Engage with customers
        </Highlight>
      </Heading>
      <VStack align={"stretch"} w={"50%"} divider={<hr />}>
        <Flex gap="2" my={8}>
          <Box p="2">
            <VStack align={"left"}>
              <Text as="b">Reply to email address to engage with customers</Text>
              <Text>Customers can reply to following email to engage with your brand. Default is your login email.</Text>
              <InputGroup py={5}>
                <InputLeftAddon children="replyto:" />
                <Input
                  type="email"
                  placeholder="enter the email address"
                  value={replyToEmail}
                  onChange={(e) => {
                    setReplyToEmail(e.target.value);
                    updateSettingsInParent({ replyToEmail: e.target.value });
                  }}
                />
              </InputGroup>
            </VStack>
          </Box>
          <Spacer />
        </Flex>
      </VStack>
      <Divider orientation="horizontal" my={10} />

      <VStack align={"left"}>
        <Text as="b">Site Name</Text>
        <Text pb={4}>This serves as a value for the [site_name] placeholder in the review email.</Text>
        <Input
          placeholder="Enter site name"
          maxW={"250px"}
          fontSize={"md"}
          value={siteName}
          onChange={(e) => {
            setSiteName(e.target.value);
            updateSettingsInParent({ siteName: e.target.value });
          }}
        />
      </VStack>
    </>
  );
};

const EmailTab = ({ siteId, email, setEmail, removeBrandzwayBranding, currentSubscription }: { siteId: string; email: Email; setEmail: any; removeBrandzwayBranding: boolean; currentSubscription: any }) => {
  const [canvasColor, setCanvasColor] = useState(email.canvasColor);
  const [panelColor, setPanelColor] = useState(email.panelColor);
  const [textColor, setTextColor] = useState(email.textColor);
  const [productNameColor, setProductNameColor] = useState(email.productNameColor);
  const [logoImageEnabled, setLogoImageEnabled] = useState(email.logoImageEnabled);
  const [logoImageDetails, setLogoImageDetails] = useState(email.logoImageDetails);
  const [bannerImageEnabled, setBannerImageEnabled] = useState(email.bannerImageEnabled);
  const [bannerImageDetails, setBannerImageDetails] = useState(email.bannerImageDetails);
  const [subject, setSubject] = useState(email.subject);
  const [body, setBody] = useState(email.body);
  const [reviewButtonType, setReviewButtonType] = useState(email.reviewButtonType);
  const [reviewButtonStarColor, setReviewButtonStarColor] = useState(email.reviewButtonStarColor);
  const [reviewButtonText, setReviewButtonText] = useState(email.reviewButtonText);
  const [reviewButtonTextColor, setReviewButtonTextColor] = useState(email.reviewButtonTextColor);
  const [reviewButtonBackgroundColor, setReviewButtonBackgroundColor] = useState(email.reviewButtonBackgroundColor);
  const [reviewButtonBorderRadius, setReviewButtonBorderRadius] = useState(email.reviewButtonBorderRadius);
  const [footerEnabled, setFooterEnabled] = useState(email.footerEnabled);
  const [footerText, setFooterText] = useState(email.footerText);
  const [socialMediaLinkEnabled, setSocialMediaLinkEnabled] = useState(email.socialMediaLinkEnabled);
  const [socialMediaText, setSocialMediaText] = useState(email.socialMediaText);
  const [fbLink, setFbLink] = useState(email.fbLink);
  const [tiktokLink, setTiktokLink] = useState(email.tiktokLink);
  const [instagramLink, setInstagramLink] = useState(email.instagramLink);
  const [twitterLink, setTwitterLink] = useState(email.twitterLink);

  const updateEmailInParent = (val: any) => {
    setEmail(val);
  };

  const fileUploaderSettings: FileUploaderSettings = {
    maxSizeInMB: 4,
    showMaxWidthRecommendation: true,
    toolTipMessage: "Drop a new image to replace existing one.",
    uploadType: UploadType.dashboardUploadFile,
    siteId,
  };

  const [forceUpdateEmailViewer, setForceUpdateEmailViewer] = useState(Date.now().toString());
  const { isOpen, onOpen, onClose } = useDisclosure();
  const sampleEmailJson: Email = {
    canvasColor: "#f6f6f6",
    panelColor: "#fff1da",
    textColor: "#242424",
    productNameColor: "#000000",
    logoImageEnabled: true,
    logoImageDetails: {
      imagePath: isOpen ? `assets/samples/emails/sample-email-logo.png` : "",
      imageThumbailPath: "",
      lastUpdated: "2023-05-07T14:03:56.700Z",
    },
    bannerImageEnabled: true,
    bannerImageDetails: {
      imagePath: isOpen ? `assets/samples/emails/sample-email-banner.jpg` : "",
      imageThumbailPath: "",
      lastUpdated: "2023-05-07T14:04:02.007Z",
    },
    subject: "Order # 1009, did you love your purchase? Tell us about it with a review!",
    body: "Dear [Customer_Name],\n\nWe hope you're enjoying your recent purchase from [Your Company Name]. We'd love to hear about your experience with the product!\n\nWe would greatly appreciate it if you could take a few moments to leave a review of your recent purchase. Your feedback can help other customers make informed decisions and discover our products",
    reviewButtonType: ReviewButtonType.button,
    reviewButtonStarColor: "#ffd10a",
    reviewButtonText: "Write a review",
    reviewButtonTextColor: "#ffffff",
    reviewButtonBackgroundColor: "#000000",
    reviewButtonBorderRadius: 25,
    footerEnabled: true,
    footerText: "Thank you for shopping with us! We appreciate your business and hope to see you again! ",
    socialMediaLinkEnabled: true,
    socialMediaText: " Connect with us on social media for exclusive perks!",
    fbLink: "null",
    tiktokLink: "null",
    instagramLink: "null",
    twitterLink: "null",
  };

  return (
    <>
      <Flex>
        <Box w={"50%"} pr={5}>
          <VStack align={"stretch"} w={"100%"} spacing={10}>
            <Box>
              <Heading size="sm" my={4}>
                Canvas Background
              </Heading>
              <HStack>
                <ColorPicker
                  currentColor={canvasColor}
                  updateCurrentColor={(val: string) => {
                    setCanvasColor(val);
                    updateEmailInParent({ canvasColor: val });
                  }}
                  colorUpdateComplete={(val: string) => {}}
                ></ColorPicker>
              </HStack>
            </Box>

            <Box>
              <Heading size="sm" my={4}>
                Panel Background
              </Heading>
              <HStack>
                <ColorPicker
                  currentColor={panelColor}
                  updateCurrentColor={(val: string) => {
                    setPanelColor(val);
                    updateEmailInParent({ panelColor: val });
                  }}
                  colorUpdateComplete={(val: string) => {}}
                ></ColorPicker>
              </HStack>
            </Box>

            <Box>
              <Heading size="sm" my={4}>
                Text Color
              </Heading>
              <HStack>
                <ColorPicker
                  currentColor={textColor}
                  updateCurrentColor={(val: string) => {
                    setTextColor(val);
                    updateEmailInParent({ textColor: val });
                  }}
                  colorUpdateComplete={(val: string) => {}}
                ></ColorPicker>
              </HStack>
            </Box>

            <Box>
              <Heading size="sm" my={4}>
                Product Name Color
              </Heading>
              <HStack>
                <ColorPicker
                  currentColor={productNameColor}
                  updateCurrentColor={(val: string) => {
                    setProductNameColor(val);
                    updateEmailInParent({ productNameColor: val });
                  }}
                  colorUpdateComplete={(val: string) => {}}
                ></ColorPicker>
              </HStack>
            </Box>

            <Divider orientation="horizontal" />

            <Box>
              <HStack spacing={2}>
                <Heading size="sm" ml={0}>
                  Logo Image
                </Heading>
                <Switch
                  aria-label="Logo Image"
                  colorScheme="brand"
                  size="md"
                  ml={"3"}
                  mt={"0"}
                  mr={"2"}
                  isDisabled={!allowPremiumFeatures(currentSubscription)}
                  isChecked={logoImageEnabled ?? false}
                  onChange={() => {
                    setLogoImageEnabled(!logoImageEnabled);
                    updateEmailInParent({ logoImageEnabled: !logoImageEnabled });
                  }}
                />
                <PremiumFeature isPremiumUser={allowPremiumFeatures(currentSubscription)}></PremiumFeature>
              </HStack>
              <VStack py={2} width={"60%"} spacing={4} alignItems={"left"}>
                <Link href={`${config.MEDIA_BASE_URL}/` + logoImageDetails?.imagePath} isExternal display={!logoImageDetails || logoImageDetails?.imagePath === "" ? "none" : "visible"}>
                  Download <DownloadIcon mx="2px" />
                </Link>
                <Badge display={!logoImageDetails || logoImageDetails?.imagePath === "" ? "none" : "visible"} colorScheme="green">
                  Last Uploaded {formatDate(logoImageDetails?.lastUpdated)}
                </Badge>
                <FileUploader fileUploaderSettings={{ ...fileUploaderSettings, assetType: AssetType.logo, isDisabled: !allowPremiumFeatures(currentSubscription) }} callbackSuccess={() => setForceUpdateEmailViewer(Date.now().toString())}></FileUploader>
                <VStack alignItems={"left"}>
                  <Text fontSize={"xs"} color={"gray.400"}>
                    Logo updates automatically upon successful upload, no need to save.
                  </Text>
                  <Text fontSize={"xs"} color={"gray.400"}>
                    Recommended max width 512px.
                  </Text>
                </VStack>
              </VStack>
            </Box>

            <Divider orientation="horizontal" />

            <Box>
              <HStack spacing={2}>
                <Heading size="sm" ml={0}>
                  Banner Image
                </Heading>
                <Switch
                  aria-label=" Banner Image"
                  colorScheme="brand"
                  size="md"
                  ml={"3"}
                  mt={"0"}
                  mr={"2"}
                  isDisabled={!allowPremiumFeatures(currentSubscription)}
                  isChecked={bannerImageEnabled ?? false}
                  onChange={() => {
                    setBannerImageEnabled(!bannerImageEnabled);
                    updateEmailInParent({ bannerImageEnabled: !bannerImageEnabled });
                  }}
                />
                <PremiumFeature isPremiumUser={allowPremiumFeatures(currentSubscription)}></PremiumFeature>
              </HStack>
              <VStack py={2} width={"60%"} spacing={4} alignItems={"left"}>
                <Link href={`${config.MEDIA_BASE_URL}/` + bannerImageDetails?.imagePath} isExternal display={!bannerImageDetails || bannerImageDetails?.imagePath === "" ? "none" : "visible"}>
                  Download <DownloadIcon mx="2px" />
                </Link>
                <Badge display={!bannerImageDetails || bannerImageDetails?.imagePath === "" ? "none" : "visible"} colorScheme="green">
                  Last Uploaded {formatDate(bannerImageDetails?.lastUpdated)}
                </Badge>
                <FileUploader fileUploaderSettings={{ ...fileUploaderSettings, assetType: AssetType.banner, isDisabled: !allowPremiumFeatures(currentSubscription) }}></FileUploader>
                <VStack alignItems={"left"}>
                  <Text fontSize={"xs"} color={"gray.400"}>
                    Banner updates automatically upon successful upload, no need to save.
                  </Text>
                  <Text fontSize={"xs"} color={"gray.400"}>
                    Recommended max width 512px.
                  </Text>
                </VStack>
              </VStack>
            </Box>

            <Divider orientation="horizontal" />

            <Box>
              <Heading size="sm" my={4}>
                Subject
              </Heading>
              <Input
                placeholder="Enter the title"
                fontSize={"md"}
                maxLength={100}
                value={subject}
                onChange={(e) => {
                  setSubject(e.target.value);
                  updateEmailInParent({ subject: e.target.value });
                }}
              />
              <Text fontSize={"xs"} color={"gray.500"} pl={1} pt={1}>
                Maximum 100 characters.
              </Text>
            </Box>

            <Box>
              <Heading size="sm" my={4}>
                Body
              </Heading>
              <Textarea
                placeholder="Enter the body"
                size={"lg"}
                minH={300}
                fontSize={"md"}
                value={body}
                onChange={(e) => {
                  setBody(e.target.value);
                  updateEmailInParent({ body: e.target.value });
                }}
              />
            </Box>

            <Box py={5}>
              <Text pb={3}>Use following placeholders to personalise order email for the users:</Text>
              <HStack spacing={3}>
                <Tag p={2}>[Order_Id]</Tag>
                <Tag>[Customer_Name]</Tag>
                <Tag>[Site_Name]</Tag>
              </HStack>
            </Box>

            <Divider orientation="horizontal" />

            <Box>
              <Heading size="sm" my={4}>
                Review button type
              </Heading>
              <RadioGroup
                onChange={(val: ReviewButtonType) => {
                  setReviewButtonType(val);
                  updateEmailInParent({ reviewButtonType: val });
                }}
                value={reviewButtonType}
              >
                <HStack spacing={10}>
                  <Radio value={ReviewButtonType.button} colorScheme="brand">
                    <Box bg="black" h="100%" w="100%" py={2} px={4} color="white" aria-label="Write a Review Button">
                      Write a Review
                    </Box>
                  </Radio>
                  <Radio value={ReviewButtonType.stars} mr={10} colorScheme="brand" aria-label="Star Review Button">
                    <Icon aria-label="star" fontSize="24" as={FaStar} color={"orange"} />
                    <Icon aria-label="star" fontSize="24" as={FaStar} color={"orange"} />
                    <Icon aria-label="star" fontSize="24" as={FaStar} color={"orange"} />
                    <Icon aria-label="star" fontSize="24" as={FaStar} color={"orange"} />
                    <Icon aria-label="star" fontSize="24" as={FaStar} color={"orange"} />
                  </Radio>
                </HStack>
              </RadioGroup>
              <Collapse in={reviewButtonType == ReviewButtonType.stars} animateOpacity>
                <VStack alignItems="left" py={10} px={4}>
                  <Heading size="sm" ml={1}>
                    Star Color
                  </Heading>
                  <HStack pb={60}>
                    <ColorPicker
                      currentColor={reviewButtonStarColor}
                      updateCurrentColor={(val: string) => {
                        setReviewButtonStarColor(val);
                        updateEmailInParent({ reviewButtonStarColor: val });
                      }}
                      colorUpdateComplete={(val: string) => {}}
                    ></ColorPicker>
                  </HStack>
                </VStack>
              </Collapse>

              <Collapse in={reviewButtonType == ReviewButtonType.button} animateOpacity>
                <VStack alignItems={"left"} justifyContent={"left"} p={3} borderColor={"brand.500"} spacing={10} py={10}>
                  <VStack alignItems="left" spacing={3}>
                    <Heading size="sm" ml={1}>
                      Button text Color
                    </Heading>
                    <HStack>
                      <ColorPicker
                        currentColor={reviewButtonTextColor}
                        updateCurrentColor={(val: string) => {
                          setReviewButtonTextColor(val);
                          updateEmailInParent({ reviewButtonTextColor: val });
                        }}
                        colorUpdateComplete={(val: string) => {}}
                      ></ColorPicker>
                    </HStack>
                  </VStack>

                  <VStack alignItems="left" spacing={3}>
                    <Heading size="sm" ml={1}>
                      Button background Color
                    </Heading>
                    <HStack>
                      <ColorPicker
                        currentColor={reviewButtonBackgroundColor}
                        updateCurrentColor={(val: string) => {
                          setReviewButtonBackgroundColor(val);
                          updateEmailInParent({ reviewButtonBackgroundColor: val });
                        }}
                        colorUpdateComplete={(val: string) => {}}
                      ></ColorPicker>
                    </HStack>
                  </VStack>

                  <VStack alignItems="left" spacing={3} w={200}>
                    <Heading size="sm" ml={1}>
                      Button Text
                    </Heading>
                    <Input
                      w={100}
                      placeholder="Review Button text"
                      htmlSize={15}
                      width="auto"
                      fontSize={"md"}
                      maxLength={20}
                      value={reviewButtonText}
                      onChange={(e) => {
                        setReviewButtonText(e.target.value);
                        updateEmailInParent({ reviewButtonText: e.target.value });
                      }}
                    />
                    <Text fontSize={"xs"} color={"gray.500"} pl={1} mt={-1}>
                      Maximum 20 characters.
                    </Text>
                  </VStack>

                  <HStack spacing={4}>
                    <Heading size="sm" ml={1}>
                      Border Radius:
                    </Heading>
                    <Menu>
                      <MenuButton as={Button} w={100} color="brand.800" rightIcon={<ChevronDownIcon />}>
                        {reviewButtonBorderRadius}
                      </MenuButton>
                      <MenuList>
                        <MenuItem
                          onClick={() => {
                            setReviewButtonBorderRadius(0);
                            updateEmailInParent({ reviewButtonBorderRadius: 0 });
                          }}
                        >
                          0
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            setReviewButtonBorderRadius(5);
                            updateEmailInParent({ reviewButtonBorderRadius: 5 });
                          }}
                        >
                          5
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            setReviewButtonBorderRadius(10);
                            updateEmailInParent({ reviewButtonBorderRadius: 10 });
                          }}
                        >
                          10
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            setReviewButtonBorderRadius(25);
                            updateEmailInParent({ reviewButtonBorderRadius: 25 });
                          }}
                        >
                          25
                        </MenuItem>
                      </MenuList>
                    </Menu>
                  </HStack>
                </VStack>
              </Collapse>
            </Box>

            <Divider orientation="horizontal" />

            <Box>
              <Heading size="sm" my={4}>
                Footer
                <Switch
                  aria-label="Footer"
                  colorScheme="brand"
                  size="md"
                  ml={"3"}
                  mt={"-1"}
                  isChecked={footerEnabled}
                  onChange={() => {
                    setFooterEnabled(!footerEnabled);
                    updateEmailInParent({ footerEnabled: !footerEnabled });
                  }}
                />
              </Heading>
              <Textarea
                placeholder="Enter the body"
                size={"lg"}
                minH={100}
                fontSize={"md"}
                isDisabled={!footerEnabled}
                value={footerText}
                onChange={(e) => {
                  setFooterText(e.target.value);
                  updateEmailInParent({ footerText: e.target.value });
                }}
              />
            </Box>

            <Divider orientation="horizontal" />

            <Heading size="sm" ml={2} alignItems={"center"}>
              Social Media Links
              <Switch
                aria-label="Social Media Links"
                colorScheme="brand"
                size="md"
                ml={"3"}
                mt={"0"}
                mr={"2"}
                isDisabled={!allowPremiumFeatures(currentSubscription)}
                isChecked={socialMediaLinkEnabled}
                onChange={() => {
                  setSocialMediaLinkEnabled(!socialMediaLinkEnabled);
                  updateEmailInParent({ socialMediaLinkEnabled: !socialMediaLinkEnabled });
                }}
              />
              <PremiumFeature isPremiumUser={allowPremiumFeatures(currentSubscription)}></PremiumFeature>
            </Heading>
            <VStack>
              <Input
                placeholder="Enter the heading here"
                mb={5}
                isDisabled={!socialMediaLinkEnabled || !allowPremiumFeatures(currentSubscription)}
                value={socialMediaText}
                onChange={(e) => {
                  setSocialMediaText(e.target.value);
                  updateEmailInParent({ socialMediaText: e.target.value });
                }}
              />

              <InputGroup my={2}>
                <InputLeftElement pointerEvents="none" children={<Icon fontSize="16" as={FaFacebookF} color="gray.500" />} />
                <Input
                  type="url"
                  placeholder="Enter your FB link"
                  isDisabled={!socialMediaLinkEnabled || !allowPremiumFeatures(currentSubscription)}
                  value={fbLink}
                  onChange={(e) => {
                    setFbLink(e.target.value);
                    updateEmailInParent({ fbLink: e.target.value });
                  }}
                />
              </InputGroup>

              <InputGroup m={2}>
                <InputLeftElement pointerEvents="none" children={<Icon fontSize="16" as={FaTiktok} color="gray.500" />} />
                <Input
                  type="url"
                  placeholder="Enter your Tiktok link"
                  isDisabled={!socialMediaLinkEnabled || !allowPremiumFeatures(currentSubscription)}
                  value={tiktokLink}
                  onChange={(e) => {
                    setTiktokLink(e.target.value);
                    updateEmailInParent({ tiktokLink: e.target.value });
                  }}
                />
              </InputGroup>

              <InputGroup m={2}>
                <InputLeftElement pointerEvents="none" children={<Icon fontSize="16" as={FaInstagram} color="gray.500" />} />
                <Input
                  type="url"
                  placeholder="Enter your Instagram link"
                  isDisabled={!socialMediaLinkEnabled || !allowPremiumFeatures(currentSubscription)}
                  value={instagramLink}
                  onChange={(e) => {
                    setInstagramLink(e.target.value);
                    updateEmailInParent({ instagramLink: e.target.value });
                  }}
                />
              </InputGroup>

              <InputGroup m={2}>
                <InputLeftElement pointerEvents="none" children={<Icon fontSize="16" as={FaTwitter} color="gray.500" />} />
                <Input
                  type="url"
                  placeholder="Enter your Twitter link"
                  isDisabled={!socialMediaLinkEnabled || !allowPremiumFeatures(currentSubscription)}
                  value={twitterLink}
                  onChange={(e) => {
                    setTwitterLink(e.target.value);
                    updateEmailInParent({ twitterLink: e.target.value });
                  }}
                />
              </InputGroup>
            </VStack>
            <Text color="gray.500" textAlign={"left"} fontSize={"sm"}>
              If a link is not provided, the corresponding icon will be hidden.
            </Text>
          </VStack>
        </Box>
        <Box flex="1" px={5}>
          <Button mb={10} variant="outline" colorScheme={"brand"} size="xs" onClick={onOpen}>
            View Full Features Sample Email
          </Button>
          <Box
            dangerouslySetInnerHTML={{
              __html: productReviewEmailTemplate(
                {
                  productsMetadata: [
                    {
                      imageUrl: `${config.MEDIA_BASE_URL}/assets/samples/emails/sample-email-product.jpg`,
                      name: "Moisturising cream for dry skin",
                      writeReviewButtonUrl: "https://",
                    },
                  ],
                },
                email,
                removeBrandzwayBranding,
                forceUpdateEmailViewer
              ),
            }}
          ></Box>
        </Box>
      </Flex>
      <Modal isOpen={isOpen} onClose={onClose} size={"full"}>
        <ModalOverlay />
        <ModalContent maxW={"1200px"}>
          <ModalCloseButton />
          <ModalBody>
            <Box
              dangerouslySetInnerHTML={{
                __html: productReviewEmailTemplate(
                  {
                    productsMetadata: [
                      {
                        imageUrl: `${config.MEDIA_BASE_URL}/assets/samples/emails/sample-email-product.jpg`,
                        name: "Moisturising cream for dry skin",
                        writeReviewButtonUrl: "https://",
                      },
                    ],
                  },
                  sampleEmailJson,
                  true,
                  forceUpdateEmailViewer
                ),
              }}
            ></Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

const WidgetTab = ({ widget, setWidget, currentSubscription }: { widget: Widget; setWidget: any; currentSubscription: any }) => {
  const [primaryColor, setPrimaryColor] = useState(widget.primaryColor);
  const [starColor, setStarColor] = useState(widget.starColor);
  const [widgetLayout, setWidgetLayout] = useState<any>(widget.widgetLayout);
  const [cardColor, setCardColor] = useState(widget.cardColor);
  const [reviewDate, setReviewDate] = useState(widget.reviewDate);
  const [reviewDateFormat, setReviewDateFormat] = useState(widget.reviewDateFormat);
  const [reviewTitle, setReviewTitle] = useState(widget.reviewTitle);
  const [showVerifyBadge, setShowVerifyBadge] = useState(widget.showVerifyBadge);
  const [hideIfNoReview, setHideIfNoReview] = useState(widget.hideIfNoReview);
  const [reviewsPerPage, setReviewsPerPage] = useState(widget.reviewsPerPage);
  const [allowSorting, setAllowSorting] = useState(widget.allowSorting);
  const [defaultSoring, setDefaultSoring] = useState(widget.defaultSoring);
  const [removeBrandzwayBranding, setRemoveBrandzwayBranding] = useState(widget.removeBrandzwayBranding);
  const [writeReviewButton, setWriteReviewButton] = useState(widget.writeReviewButton);
  const [buttonsBorderRadius, setButtonsBorderRadius] = useState(widget.buttonsBorderRadius);
  const [previewSample, setPreviewSample] = useState("Desktop");
  const iframeRef = React.useRef<any>(null);

  const updateWidgetInParent = (val: any, skipUpdatePreview: boolean = false) => {
    setWidget(val);
    if (!skipUpdatePreview) {
      updatePreviewData(val);
    }
  };

  const updatePreviewData = (val: any) => {
    let ratingAndSettingsForPreview: any = {
      settings: {
        siteId: "brandzway-0000",
        siteName: "MellowMist",
        showVerifyBadge,
        starColor,
        widgetLayout,
        cardColor,
        removeBrandzwayBranding,
        defaultSoring,
        primaryColor,
        allowSorting,
        hideIfNoReview,
        writeReviewButton,
        reviewsPerPage,
        buttonsBorderRadius,
        reviewDateFormat,
        reviewDate,
        reviewTitle,
      },
      ratings: {
        reviewsCount: 22,
        averageStarRating: 4.8,
        reviewsTotal1Stars: 0,
        reviewsTotal2Stars: 0,
        reviewsTotal3Stars: 0,
        reviewsTotal4Stars: 4,
        reviewsTotal5Stars: 18,
      },
    };
    map(val, (value, key) => {
      ratingAndSettingsForPreview.settings[key] = value;
    });
    iframeRef?.current?.contentDocument?.getElementById("by-script")?.setAttribute("data-sample-settings", JSON.stringify(ratingAndSettingsForPreview.settings));
  };

  useEffect(() => {
    updatePreviewData({ starColor: starColor });
  }, [previewSample]);

  useEffect(() => {
    const timerId = setTimeout(() => {
      updatePreviewData({ starColor: starColor });
    }, 500);

    // Cleanup function to clear the timer in case the component is unmounted before the timer expires
    return () => clearTimeout(timerId);
  }, []);

  return (
    <>
      <Flex>
        <Box w={"35%"} pr={5}>
          <Heading size="md" mt={2}>
            <Highlight query="Display Reviews" styles={{ px: "4", py: "2", rounded: "lg", bg: "red.100", fontSize: "lg" }}>
              Display Reviews
            </Highlight>
          </Heading>
          <VStack align={"stretch"} w={"100%"} my={8}>
            <Heading size="sm" ml={0}>
              Primary Color
            </Heading>
            <HStack>
              <ColorPicker
                currentColor={primaryColor}
                updateCurrentColor={(val: string) => {
                  setPrimaryColor(val);
                  updateWidgetInParent({ primaryColor: val }, true);
                }}
                colorUpdateComplete={(val: string) => {
                  updateWidgetInParent({ primaryColor: val });
                }}
              ></ColorPicker>
            </HStack>

            <br />

            <Heading size="sm" ml={0}>
              Star Color
            </Heading>
            <HStack>
              <ColorPicker
                currentColor={starColor}
                updateCurrentColor={(val: string) => {
                  setStarColor(val);
                  updateWidgetInParent({ starColor: val });
                }}
                colorUpdateComplete={(val: string) => {
                  updateWidgetInParent({ starColor: val });
                }}
              ></ColorPicker>
            </HStack>

            <VStack align={"stretch"} divider={<hr />}>
              <Flex gap="2" my={8}>
                <VStack align={"left"}>
                  <Heading size="sm" my={5} alignContent={"left"}>
                    Widget Layout
                  </Heading>
                  <HStack>
                    <RadioGroup
                      onChange={(val) => {
                        setWidgetLayout(val);
                        updateWidgetInParent({ widgetLayout: val });
                      }}
                      value={widgetLayout}
                    >
                      <HStack spacing={10}>
                        <Radio colorScheme="brand" value={WidgetLayout.list}>
                          <VStack pt={"5"}>
                            <Box p={"3"} border={"1px"} borderColor={"brand.500"} borderRadius={"xl"}>
                              <img alt="list view" src="./list.png" width={"24px"} height={"24px"} />
                            </Box>
                            <Text fontWeight={"semibold"}>List</Text>
                          </VStack>
                        </Radio>
                        <Radio colorScheme="brand" value={WidgetLayout.grid} isDisabled={!allowPremiumFeatures(currentSubscription)}>
                          <HStack>
                            <VStack pt={"5"}>
                              <Box p={"3"} border={"1px"} borderColor={"brand.500"} borderRadius={"xl"}>
                                <img alt="grid view" src="./grid.png" width={"24px"} height={"24px"} />
                              </Box>
                              <Text fontWeight={"semibold"}>Grid</Text>
                            </VStack>
                          </HStack>
                        </Radio>
                      </HStack>
                    </RadioGroup>
                    <PremiumFeature isPremiumUser={allowPremiumFeatures(currentSubscription)}></PremiumFeature>
                  </HStack>
                  <VStack pt={10} align={"left"} display={widgetLayout == WidgetLayout.grid ? "" : "none"}>
                    <Text as="b">Card Color</Text>
                    <ColorPicker
                      currentColor={cardColor}
                      updateCurrentColor={(val: string) => {
                        setCardColor(val);
                        updateWidgetInParent({ cardColor: val });
                      }}
                      colorUpdateComplete={(val: string) => {
                        updateWidgetInParent({ cardColor: val });
                      }}
                    ></ColorPicker>
                  </VStack>
                </VStack>
              </Flex>

              <Flex gap="2" my={8}>
                <Box p="2">
                  <VStack align={"left"}>
                    <Text as="b">Buttons Border Radius</Text>
                  </VStack>
                </Box>
                <Spacer />
                <Menu>
                  <MenuButton as={Button} color="brand.800" rightIcon={<ChevronDownIcon />}>
                    {buttonsBorderRadius}
                  </MenuButton>
                  <MenuList>
                    <MenuItem
                      onClick={() => {
                        setButtonsBorderRadius(0);
                        updateWidgetInParent({ buttonsBorderRadius: 0 });
                      }}
                    >
                      0
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        setButtonsBorderRadius(5);
                        updateWidgetInParent({ buttonsBorderRadius: 5 });
                      }}
                    >
                      5
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        setButtonsBorderRadius(10);
                        updateWidgetInParent({ buttonsBorderRadius: 10 });
                      }}
                    >
                      10
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        setButtonsBorderRadius(15);
                        updateWidgetInParent({ buttonsBorderRadius: 15 });
                      }}
                    >
                      15
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        setButtonsBorderRadius(20);
                        updateWidgetInParent({ buttonsBorderRadius: 20 });
                      }}
                    >
                      20
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        setButtonsBorderRadius(25);
                        updateWidgetInParent({ buttonsBorderRadius: 25 });
                      }}
                    >
                      25
                    </MenuItem>
                  </MenuList>
                </Menu>
              </Flex>

              <Flex gap="2" my={8}>
                <Box p="2">
                  <VStack align={"left"}>
                    <Text as="b">Review Date</Text>
                  </VStack>
                </Box>
                <Spacer />
                <Switch
                  aria-label="Review Date"
                  colorScheme="brand"
                  size="lg"
                  isChecked={reviewDate}
                  onChange={() => {
                    setReviewDate(!reviewDate);
                    updateWidgetInParent({ reviewDate: !reviewDate });
                  }}
                />
              </Flex>

              <Flex gap="2" my={8}>
                <Box p="2">
                  <VStack align={"left"}>
                    <Text as="b">Reviews Date Format</Text>
                  </VStack>
                </Box>
                <Spacer />
                <Menu>
                  <MenuButton as={Button} color="brand.800" rightIcon={<ChevronDownIcon />}>
                    {reviewDateFormat}
                  </MenuButton>
                  <MenuList>
                    <MenuItem
                      onClick={() => {
                        setReviewDateFormat("MM-DD-YYYY");
                        updateWidgetInParent({ reviewDateFormat: "MM-DD-YYYY" });
                      }}
                    >
                      MM-DD-YYYY
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        setReviewDateFormat("MM/DD/YYYY");
                        updateWidgetInParent({ reviewDateFormat: "MM/DD/YYYY" });
                      }}
                    >
                      MM/DD/YYYY
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        setReviewDateFormat("Month DD, YYYY");
                        updateWidgetInParent({ reviewDateFormat: "Month DD, YYYY" });
                      }}
                    >
                      Month DD, YYYY
                    </MenuItem>
                  </MenuList>
                </Menu>
              </Flex>

              <Flex gap="2" my={8}>
                <Box p="2">
                  <VStack align={"left"}>
                    <Text as="b">Review Title</Text>
                  </VStack>
                </Box>
                <Spacer />
                <Switch
                  aria-label="Review Title"
                  colorScheme="brand"
                  size="lg"
                  isChecked={reviewTitle}
                  onChange={() => {
                    setReviewTitle(!reviewTitle);
                    updateWidgetInParent({ reviewTitle: !reviewTitle });
                  }}
                />
              </Flex>

              <Flex gap="2" my={8}>
                <Box p="2">
                  <VStack align={"left"}>
                    <Text as="b">Show Verify badge</Text>
                  </VStack>
                </Box>
                <Spacer />
                <Switch
                  aria-label="Show Verify badge"
                  size="lg"
                  colorScheme="brand"
                  isChecked={showVerifyBadge}
                  onChange={() => {
                    setShowVerifyBadge(!showVerifyBadge);
                    updateWidgetInParent({ showVerifyBadge: !showVerifyBadge });
                  }}
                />
              </Flex>

              {/* Future todo v2 */}
              {/* <Flex gap="2" my={8}>
                <Box p="2">
                  <VStack align={"left"}>
                    <Text as="b">Hide if No Review Message</Text>
                    <Text>It will hide review if there is no message. However, it will count toward total average star rating.</Text>
                  </VStack>
                </Box>
                <Spacer />
                <Switch
                  colorScheme="brand"
                  size="lg"
                  isChecked={hideIfNoReview}
                  onChange={() => {
                    setHideIfNoReview(!hideIfNoReview);
                    updateWidgetInParent({ hideIfNoReview: !hideIfNoReview });
                  }}
                />
              </Flex> */}

              <Flex gap="2" my={8}>
                <Box p="2">
                  <VStack align={"left"}>
                    <Text as="b">Reviews Per Page</Text>
                  </VStack>
                </Box>
                <Spacer />
                <Menu>
                  <MenuButton as={Button} color="brand.800" rightIcon={<ChevronDownIcon />}>
                    {reviewsPerPage}
                  </MenuButton>
                  <MenuList>
                    <MenuItem
                      onClick={() => {
                        setReviewsPerPage(5);
                        updateWidgetInParent({ reviewsPerPage: 5 });
                      }}
                    >
                      5
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        setReviewsPerPage(10);
                        updateWidgetInParent({ reviewsPerPage: 10 });
                      }}
                    >
                      10
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        setReviewsPerPage(20);
                        updateWidgetInParent({ reviewsPerPage: 20 });
                      }}
                    >
                      25
                    </MenuItem>
                  </MenuList>
                </Menu>
              </Flex>

              <Flex gap="2" my={8}>
                <Box p="2">
                  <HStack align={"left"} alignItems={"center"}>
                    <VStack align={"left"}>
                      <Text as="b">Allow Sorting</Text>
                      <Text>Allow users to sort the reviews.</Text>
                    </VStack>
                    <PremiumFeature isPremiumUser={allowPremiumFeatures(currentSubscription)}></PremiumFeature>
                  </HStack>
                </Box>
                <Spacer />
                <Switch
                  aria-label="Allow Sorting"
                  size="lg"
                  colorScheme="brand"
                  isDisabled={!allowPremiumFeatures(currentSubscription)}
                  isChecked={allowSorting}
                  onChange={() => {
                    setAllowSorting(!allowSorting);
                    updateWidgetInParent({ allowSorting: !allowSorting });
                  }}
                />
              </Flex>

              <Flex gap="2" my={8}>
                <Box p="2">
                  <VStack align={"left"}>
                    <Text as="b" color={allowSorting ? "" : "gray.400"}>
                      Default Sorting
                    </Text>
                  </VStack>
                </Box>
                <Spacer />
                <Menu>
                  <MenuButton as={Button} color="brand.800" rightIcon={<ChevronDownIcon />} isDisabled={!allowSorting}>
                    {defaultSoring}
                  </MenuButton>
                  <MenuList>
                    <MenuItem
                      onClick={() => {
                        setDefaultSoring("Newest");
                        updateWidgetInParent({ defaultSoring: "Newest" });
                      }}
                    >
                      Newest
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        setDefaultSoring("Oldest");
                        updateWidgetInParent({ defaultSoring: "Oldest" });
                      }}
                    >
                      Oldest
                    </MenuItem>
                  </MenuList>
                </Menu>
              </Flex>

              <Flex gap="2" my={8}>
                <Box p="2">
                  <HStack align={"left"} alignItems={"center"}>
                    <Text as="b">Remove Brandzway Branding</Text>
                    <PremiumFeature isPremiumUser={allowPremiumFeatures(currentSubscription)}></PremiumFeature>
                  </HStack>
                </Box>
                <Spacer />
                <Switch
                  aria-label="Remove Brandzway Branding"
                  size="lg"
                  isDisabled={!allowPremiumFeatures(currentSubscription)}
                  isChecked={removeBrandzwayBranding}
                  colorScheme="brand"
                  onChange={() => {
                    setRemoveBrandzwayBranding(!removeBrandzwayBranding);
                    updateWidgetInParent({ removeBrandzwayBranding: !removeBrandzwayBranding });
                  }}
                />
              </Flex>

              <VStack align={"stretch"}>
                <Heading size="md" my={5}>
                  <Highlight query="Unverified Reviews" styles={{ px: "4", py: "2", rounded: "lg", bg: "red.100", fontSize: "lg" }}>
                    Unverified Reviews
                  </Highlight>
                </Heading>
                <Flex gap="2" my={8} pb={8}>
                  <Box p="2">
                    <VStack align={"left"}>
                      <Text as="b">Write Review Button</Text>
                      <Text>This review button will be displayed on the product page.</Text>
                    </VStack>
                  </Box>
                  <PremiumFeature isPremiumUser={allowPremiumFeatures(currentSubscription)}></PremiumFeature>
                  <Spacer />
                  <Switch
                    aria-label="Write Review Button"
                    colorScheme="brand"
                    size="lg"
                    isDisabled={!allowPremiumFeatures(currentSubscription)}
                    isChecked={writeReviewButton}
                    onChange={() => {
                      setWriteReviewButton(!writeReviewButton);
                      updateWidgetInParent({ writeReviewButton: !writeReviewButton });
                    }}
                  />
                </Flex>
              </VStack>
            </VStack>
            <Divider orientation="horizontal" my={10} />
          </VStack>
        </Box>
        <VStack flex="1" px={10} pb={10} backgroundColor={"gray.200"} ml={20} height={"1000px"} borderRadius={"20px"}>
          <Text as="b" fontSize={"xs"} p={2}>
            Sample Preview
          </Text>
          <HStack p={2}>
            <Button
              aria-label="Desktop view"
              background={"white"}
              color={"brand.600"}
              _hover={{
                background: "gray.100",
                color: "brand.600",
              }}
              borderColor="brand.600"
              borderWidth={previewSample === "Desktop" ? "1px" : "0px"}
              onClick={() => {
                setPreviewSample("Desktop");
                updatePreviewData({ starColor: starColor });
              }}
            >
              <Icon as={IoDesktopOutline}></Icon>
            </Button>
            <Button
              aria-label="Mobile view"
              background={"white"}
              color={"brand.600"}
              _hover={{
                background: "gray.100",
                color: "brand.600",
              }}
              borderColor="brand.600"
              borderWidth={previewSample === "Mobile" ? "1px" : "0px"}
              onClick={() => {
                setPreviewSample("Mobile");
              }}
            >
              <Icon as={FaMobileAlt}></Icon>
            </Button>
          </HStack>
          <Box backgroundColor={"white"} height={"100%"} className={previewSample === "Desktop" ? "desktop-scaled-frame" : "mobile-scaled-frame"}>
            <iframe title="Product Review Sample Preview" ref={iframeRef} src="./widget-testing/product-review/index.html" width="100%" height="100%"></iframe>
          </Box>
        </VStack>
      </Flex>
    </>
  );
};

const ColorPicker = ({ currentColor, updateCurrentColor, colorUpdateComplete }: any) => {
  //const [currentColor, updateCurrentColor] = useState("#671b1b");
  const [isColorPickerOpen, toggleColorPicker] = useState(false);
  const openColorPicker = (val: boolean) => {
    toggleColorPicker(val);
  };

  const onColorUpdate = (val: any) => {
    if (val.length >= 0) {
      updateCurrentColor(val);
    }
  };

  return (
    <>
      <InputGroup>
        <InputLeftElement>
          <Box bg={currentColor} w={10} height={"100%"} border={1} borderColor={"black"} borderRadius={7} onClick={() => openColorPicker(true)}></Box>
        </InputLeftElement>
        <Input type="tel" placeholder="#F23ED7" value={currentColor} pl={50} htmlSize={10} width="auto" onClick={() => openColorPicker(true)} onChange={(event) => onColorUpdate(event?.target.value)} onBlur={(event) => colorUpdateComplete(event?.target.value)} />
      </InputGroup>
      {isColorPickerOpen ? (
        <Box position={"absolute"} zIndex={5}>
          <Box position={"fixed"} top={0} left={0} right={0} bottom={0} onClick={() => openColorPicker(false)}></Box>
          <Box position={"absolute"} top={5} left={0}>
            <ChromePicker color={currentColor} onChange={(color) => updateCurrentColor(color.hex)} onChangeComplete={(color) => colorUpdateComplete(color.hex)}></ChromePicker>
          </Box>
        </Box>
      ) : null}
    </>
  );
};

const formatDate = (dateStr: string): string => {
  const date = new Date(dateStr);
  const year = date.getFullYear();
  const month = date.toLocaleString("default", { month: "long" });
  const day = date.getDate();
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const amOrPm = hours >= 12 ? "PM" : "AM";
  const formattedHours = hours % 12 || 12;
  const paddedMinutes = minutes.toString().padStart(2, "0");
  const formattedDate = `${month} ${day}, ${year} ${formattedHours}:${paddedMinutes} ${amOrPm}`;
  return formattedDate;
};
