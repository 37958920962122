import { ReactNode, useEffect, useRef, useState } from "react";
import { Box, Stack, HStack, Heading, Text, VStack, useColorModeValue, List, ListItem, ListIcon, Button, Tooltip, Switch, Icon, createIcon, Center, Flex, Spinner } from "@chakra-ui/react";
import { FaCheckCircle } from "react-icons/fa";
import { ArrowBackIcon } from "@chakra-ui/icons";
import { AccountInformation } from "../../appsync/API";
import { useNavigate } from "react-router-dom";
import { log } from "console";

function PriceWrapper({ children, bgColor, paddingTop }: { children: ReactNode; bgColor?: string; paddingTop?: number }) {
  return (
    <Box pt={paddingTop ? paddingTop : 0}>
      <Box mb={4} alignSelf={{ base: "center", lg: "flex-start" }} borderRadius={"xl"} bg={bgColor ? bgColor : "white"} h={"620px"} _hover={{ boxShadow: "lg", transform: "translateY(-5px)" }} boxShadow="md" transition="transform 0.2s ease-in-out">
        {children}
      </Box>
    </Box>
  );
}

export default function HomePricing({ isChangePlan, accountInformation = null }: { isChangePlan: boolean; accountInformation?: AccountInformation | null }) {
  const [isPricingShowingInYearly, setPricingShowingInYearly] = useState(false);
  const [currentSubscription, setcurrentSubscription] = useState("");
  const [isVisible, setIsVisible] = useState(false);
  const [currentPlanToShow, setCurrentPlanToShow] = useState("");
  const ref = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (accountInformation) {
      setcurrentSubscription(accountInformation?.currentSubscription?.tier || "");
      if (accountInformation.currentSubscription?.subscriptionChange) {
        setCurrentPlanToShow(accountInformation?.currentSubscription?.subscriptionChange?.nextTier as string);
      } else {
        setCurrentPlanToShow(accountInformation?.currentSubscription?.tier || "");
      }
    }

    if (isChangePlan) {
      setIsVisible(true);
    } else {
      const handleScroll = () => {
        if (ref.current && ref.current.getBoundingClientRect().top < window.innerHeight * 0.65) {
          setIsVisible(true);
        }
      };

      window.addEventListener("scroll", handleScroll);
      return () => window.removeEventListener("scroll", handleScroll);
    }
  }, [accountInformation, currentSubscription]);

  useEffect(() => {
    if (!isChangePlan) {
      const handleScroll = () => {
        if (ref.current && ref.current.getBoundingClientRect().top < window.innerHeight * 0.65) {
          setIsVisible(true);
        }
      };

      window.addEventListener("scroll", handleScroll);
      return () => window.removeEventListener("scroll", handleScroll);
    }
  }, []);

  const goToCheckoutForPlanUpgrade = (plan: string) => {
    if (plan == "Growth") {
      navigate("/checkout", { state: { newPlan: "Growth", byPlanIdBase: "reviews_growth", isYearlyPlan: isPricingShowingInYearly, isUpgrade: true, monthlyPrice: "18.00", yearlyPrice: "180", resumeSubscription: accountInformation?.currentSubscription?.resumeSubscription } });
    } else if (plan == "Pro") {
      navigate("/checkout", { state: { newPlan: "Pro", byPlanIdBase: "reviews_pro", isYearlyPlan: isPricingShowingInYearly, isUpgrade: true, monthlyPrice: "55.00", yearlyPrice: "576", resumeSubscription: accountInformation?.currentSubscription?.resumeSubscription } });
    }
  };

  const goToCheckoutForPlanDowngrade = (plan: string) => {
    if (plan == "Free") {
      navigate("/checkout", { state: { newPlan: "Free", byPlanIdBase: "reviews_free", isYearlyPlan: false, isUpgrade: false, monthlyPrice: "0", yearlyPrice: "0", resumeSubscription: accountInformation?.currentSubscription?.resumeSubscription } });
    } else if (plan == "Growth") {
      navigate("/checkout", { state: { newPlan: "Growth", byPlanIdBase: "reviews_growth", isYearlyPlan: isPricingShowingInYearly, isUpgrade: false, monthlyPrice: "18.00", yearlyPrice: "180", resumeSubscription: accountInformation?.currentSubscription?.resumeSubscription } });
    }
  };

  return (
    <>
      <Box py={20} bg={"gray.100"} ref={ref} minH={"100vh"}>
        {isChangePlan && (
          <>
            <Box mt={"-10"} ml={"10%"}>
              <Button leftIcon={<ArrowBackIcon />} colorScheme="gray" variant="ghost" onClick={() => navigate(-1)}>
                Back
              </Button>
            </Box>
            <VStack spacing={5} textAlign="center" pb={"50"} className={isVisible ? "animate-fade-down" : "opacity-zero"}>
              <Heading as="h1" fontSize="5xl" bgGradient="linear(to-l, #7928CA, #FF0080)" bgClip="text">
                Flexible Plans
              </Heading>
              <Text fontSize="xl" color={"gray.600"} bgGradient="linear(to-l, #7928CA, #FF0080)" bgClip="text" as="b">
                Choose a plan that works best for you and your team.
              </Text>
            </VStack>
          </>
        )}

        {!isChangePlan && (
          <VStack spacing={5} textAlign="center" pb={"50"} className={isVisible ? "animate-fade-down" : "opacity-zero"}>
            <Heading as="h1" fontSize="4xl">
              Flexible Plans
            </Heading>
            <Text fontSize="xl" color={"gray.600"}>
              Choose a plan that works best for you and your team.
            </Text>
          </VStack>
        )}

        {isChangePlan && !accountInformation ? (
          <Flex alignItems={"center"} justifyContent={"center"}>
            <Spinner thickness="4px" speed="0.5s" emptyColor="gray.200" color="brand.500" size="xl" />
          </Flex>
        ) : (
          <>
            <HStack h={100} justifyContent={"center"} alignItems={"center"} textAlign={"center"} className={isVisible ? "animate-fade-down" : "opacity-zero"}>
              <Text as="b" fontSize={"md"}>
                Pay monthly
              </Text>
              <Switch
                size={"lg"}
                colorScheme={"brand"}
                sx={{ transform: "scale(1.25)" }}
                px={"4"}
                isChecked={isPricingShowingInYearly}
                onChange={() => {
                  setPricingShowingInYearly(!isPricingShowingInYearly);
                }}
              />
              <Text as="b" fontSize={"md"}>
                Pay yearly
              </Text>
              <Box position={"absolute"} left={"calc(50% + 110px)"} marginTop={"-20px"}>
                <Text fontSize={"lg"} fontFamily={"CaveaFont"} position={"relative"} top={"-15px"} fontWeight={"bold"} transform={"rotate(10deg)"} marginLeft={"30px"} bg={"brand.500"} px={"10px"} py={"2px"} borderRadius={"20px"} color={"white"}>
                  Save 15%
                </Text>
                <Icon as={Arrow} color={"gray.800"} w={71} marginLeft={"-15px"} position={"relative"} top={"-15px"} />
              </Box>
            </HStack>
            <Stack direction={{ base: "column", md: "row" }} textAlign="center" justify="center" spacing={{ base: 4, lg: 10 }} py={10} className={isVisible ? "animate-fade-down" : "opacity-zero"}>
              <PriceWrapper paddingTop={30}>
                <Box py={4} px={12} borderRadius={"xl"}>
                  <Text fontWeight="500" fontSize="2xl">
                    Free
                  </Text>
                  <HStack justifyContent="center" alignItems={"center"}>
                    <Text fontSize="3xl" fontWeight="500">
                      $
                    </Text>
                    <Text fontSize="5xl" fontWeight="500">
                      0
                    </Text>
                    <Text fontSize="xl" color="gray.500">
                      /month
                    </Text>
                  </HStack>
                  <Box w="100%" pt={7}>
                    {isChangePlan && accountInformation && (
                      <>
                        {currentPlanToShow == "Free" && (
                          <Center flexDirection={"column"}>
                            <Text color={"#c15521"} border={"1px solid"} rounded={"full"} p={1} w={"200px"} fontSize={"sm"} as="b">
                              Current Plan
                            </Text>
                            <PlanUntilExpire accountInformation={accountInformation} />
                          </Center>
                        )}
                        {(currentPlanToShow == "Growth" || currentPlanToShow == "Pro") && (
                          <>
                            <Button isDisabled={accountInformation?.currentSubscription?.subscriptionChange?.nextTier == "Free"} w="full" colorScheme={"red"} variant={"outline"} rounded={"full"} h={"45"} fontSize={"lg"} _hover={{ bg: "#ff00001a" }} style={{ transform: "scale(0.75)" }} onClick={() => goToCheckoutForPlanDowngrade("Free")}>
                              Downgrade to Free
                            </Button>
                          </>
                        )}
                      </>
                    )}
                    {!isChangePlan && (
                      <Button w="full" colorScheme="brand" rounded={"full"} h={"45"} fontSize={"lg"}>
                        Sign up
                      </Button>
                    )}
                  </Box>
                </Box>
                <Box mt={"7"}>
                  <Text as="b">The Basics:</Text>
                </Box>
                <VStack py={4} borderBottomRadius={"xl"}>
                  <List spacing={3} textAlign="start" px={12}>
                    <ListItem>
                      <ListIcon as={FaCheckCircle} color="green.500" />
                      Up to 20 monthly orders
                    </ListItem>
                    <ListItem>
                      <ListIcon as={FaCheckCircle} color="green.500" />
                      Automatic Review request emails
                    </ListItem>
                    <ListItem>
                      <ListIcon as={FaCheckCircle} color="green.500" />
                      Moderate and Publish Reviews
                    </ListItem>
                    <ListItem>
                      <ListIcon as={FaCheckCircle} color="green.500" />
                      Display Review Widget
                    </ListItem>
                    <ListItem>
                      <ListIcon as={FaCheckCircle} color="green.500" />
                      Customize Widget & email
                    </ListItem>
                    <ListItem>
                      <ListIcon as={FaCheckCircle} color="green.500" />
                      Analytics
                    </ListItem>
                  </List>
                </VStack>
              </PriceWrapper>

              <PriceWrapper bgColor={"#1a1c29"} paddingTop={0}>
                <Box position="relative" color={"white"}>
                  <Box position="absolute" top="-16px" left="50%" style={{ transform: "translate(-50%)" }}>
                    <Text textTransform="uppercase" bg={"brand.500"} px={3} py={1} color={"white"} fontSize="sm" fontWeight="600" rounded="xl">
                      Most Popular
                    </Text>
                  </Box>
                  <Box py={4} px={12}>
                    <Text fontWeight="500" fontSize="2xl">
                      Growth
                    </Text>
                    <HStack justifyContent="center">
                      <Text fontSize="3xl" fontWeight="500">
                        $
                      </Text>
                      <Text fontSize="5xl" fontWeight="500">
                        {isPricingShowingInYearly ? "15" : "18"}
                      </Text>
                      {/* <Box h={"15px"} display={isPricingShowingInYearly ? "" : "none"}>
                        <Text as="sup" fontSize={"xl"} marginLeft={"-5px"} marginTop={"5px"}>
                          .30
                        </Text>
                      </Box> */}
                      <Text fontSize="xl" color="gray.500">
                        /month
                      </Text>
                    </HStack>
                    <Box h={"15px"} display={isPricingShowingInYearly ? "" : "none"} transition={"1s"}>
                      <Text fontSize={"xs"}>Billed $180 annually</Text>
                    </Box>
                    <Box w="100%" pt={7}>
                      {isChangePlan && accountInformation && (
                        <>
                          {currentPlanToShow == "Growth" && (
                            <Center flexDirection={"column"}>
                              <Text color={"#c15521"} border={"1px solid"} rounded={"full"} p={1} w={"200px"} fontSize={"sm"}>
                                Current Plan
                              </Text>
                              <PlanUntilExpire accountInformation={accountInformation} />
                            </Center>
                          )}
                          {currentPlanToShow == "Free" && (
                            <Button w="full" bg={"#0eb80b"} rounded={"full"} h={"45"} fontSize={"lg"} color={"white"} _hover={{ bg: "#11a10f" }} onClick={() => goToCheckoutForPlanUpgrade("Growth")}>
                              Upgrade to Growth
                            </Button>
                          )}
                          {currentPlanToShow == "Pro" && (
                            <>
                              <Button w="full" colorScheme={"red"} variant={"outline"} rounded={"full"} h={"45"} fontSize={"lg"} _hover={{ bg: "#ff00001a" }} style={{ transform: "scale(0.75)" }} onClick={() => goToCheckoutForPlanDowngrade("Growth")}>
                                Downgrade to Growth
                              </Button>
                            </>
                          )}
                        </>
                      )}
                      {!isChangePlan && (
                        <Button w="full" colorScheme="brand" rounded={"full"} h={"45"} fontSize={"lg"}>
                          Sign up
                        </Button>
                      )}
                    </Box>
                  </Box>
                  <Box mt={"7"}>
                    <Text as="b">Everything in Free, and</Text>
                  </Box>
                  <VStack py={4} borderBottomRadius={"xl"}>
                    <List spacing={3} textAlign="start" px={12}>
                      <ListItem>
                        <ListIcon as={FaCheckCircle} color="green.500" />
                        Up to 125 monthly orders
                      </ListItem>
                      <ListItem>
                        <ListIcon as={FaCheckCircle} color="green.500" />
                        Photo Reviews
                      </ListItem>
                      <ListItem>
                        <ListIcon as={FaCheckCircle} color="green.500" />
                        Grid widget layout
                      </ListItem>
                      <ListItem>
                        <ListIcon as={FaCheckCircle} color="green.500" />
                        Logo in review email
                      </ListItem>
                      <ListItem>
                        <ListIcon as={FaCheckCircle} color="green.500" />
                        Banner in review email
                      </ListItem>
                      <ListItem>
                        <ListIcon as={FaCheckCircle} color="green.500" />
                        Social media footer in review email
                      </ListItem>
                      <ListItem>
                        <ListIcon as={FaCheckCircle} color="green.500" />
                        Remove Brandzway branding
                      </ListItem>
                    </List>
                  </VStack>
                </Box>
              </PriceWrapper>

              <PriceWrapper paddingTop={30}>
                <Box py={4} px={12} borderRadius={"xl"}>
                  <Text fontWeight="600" fontSize="3xl">
                    Pro
                  </Text>
                  <Tooltip label="650 orders included in base price + $55 per additional 650 orders" fontSize="xs">
                    <Text fontWeight="500" fontSize="xs" as="ins" cursor={"pointer"}>
                      Pay as you grow
                    </Text>
                  </Tooltip>
                  <HStack justifyContent="center" alignItems={"center"}>
                    <Text fontSize="3xl" fontWeight="500">
                      $
                    </Text>
                    <Text fontSize="5xl" fontWeight="500">
                      {isPricingShowingInYearly ? "48" : "55"}
                    </Text>
                    {/* <Box h={"15px"} display={isPricingShowingInYearly ? "" : "none"}>
                      <Text as="sup" fontSize={"xl"} marginLeft={"-5px"} marginTop={"5px"}>
                        .60
                      </Text>
                    </Box> */}
                    <Text fontSize="xl" color="gray.500">
                      /month
                    </Text>
                  </HStack>
                  <Box h={"15px"} display={isPricingShowingInYearly ? "" : "none"}>
                    <Text fontSize={"xs"}>Billed $576 annually</Text>
                  </Box>
                  <Box w="100%" pt={7}>
                    {isChangePlan && accountInformation && (
                      <>
                        {currentPlanToShow == "Pro" && (
                          <Center flexDirection={"column"}>
                            <Text color={"#c15521"} border={"1px solid"} rounded={"full"} p={1} w={"200px"} fontSize={"sm"}>
                              Current Plan
                            </Text>
                            <PlanUntilExpire accountInformation={accountInformation} />
                          </Center>
                        )}
                        {currentPlanToShow != "Pro" && (
                          <Button w="full" bg={"#0eb80b"} rounded={"full"} h={"45"} fontSize={"lg"} color={"white"} _hover={{ bg: "#11a10f" }} onClick={() => goToCheckoutForPlanUpgrade("Pro")}>
                            Upgrade to Pro
                          </Button>
                        )}
                      </>
                    )}
                    {!isChangePlan && (
                      <Button w="full" colorScheme="brand" rounded={"full"} h={"45"} fontSize={"lg"}>
                        Sign up
                      </Button>
                    )}
                  </Box>
                </Box>
                <Box mt={"7"}>
                  <Text as="b">Everything in Free, and</Text>
                </Box>
                <VStack py={4} borderBottomRadius={"xl"}>
                  <List spacing={3} textAlign="start" px={12}>
                    <ListItem>
                      <ListIcon as={FaCheckCircle} color="green.500" />
                      Up to 650 monthly orders
                    </ListItem>
                    <ListItem>
                      <ListIcon as={FaCheckCircle} color="green.500" />
                      Photo Reviews
                    </ListItem>
                    <ListItem>
                      <ListIcon as={FaCheckCircle} color="green.500" />
                      Grid widget layout
                    </ListItem>
                    <ListItem>
                      <ListIcon as={FaCheckCircle} color="green.500" />
                      Logo in review email
                    </ListItem>
                    <ListItem>
                      <ListIcon as={FaCheckCircle} color="green.500" />
                      Banner in review email
                    </ListItem>
                    <ListItem>
                      <ListIcon as={FaCheckCircle} color="green.500" />
                      Social media footer in review email
                    </ListItem>
                    <ListItem>
                      <ListIcon as={FaCheckCircle} color="green.500" />
                      Remove Brandzway branding
                    </ListItem>
                    <ListItem>
                      <ListIcon as={FaCheckCircle} color="green.500" />
                      Priority Support
                    </ListItem>
                  </List>
                </VStack>
              </PriceWrapper>
            </Stack>
          </>
        )}
      </Box>
    </>
  );
}

const PlanUntilExpire = ({ accountInformation }: { accountInformation: AccountInformation }) => {
  const getFormatDate = (dateTime: string) => {
    const date = new Date(dateTime);
    const formattedDate = date.toLocaleDateString("en-US", { month: "short", day: "2-digit", year: "numeric" });
    return formattedDate;
  };
  return (
    <>
      {accountInformation?.currentSubscription?.subscriptionChange && (
        <Box mt={2} py={2} bg={"orange.200"} px={2} rounded={"full"} color={"black"}>
          <Text fontSize={"xs"}>
            Your {accountInformation?.currentSubscription?.tier} Plan benefits are active until {getFormatDate(accountInformation.currentSubscription.subscriptionChange.scheduledDate as string)}{" "}
          </Text>
        </Box>
      )}
    </>
  );
};

const Arrow = createIcon({
  displayName: "Arrow",
  viewBox: "0 0 72 24",
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      strokeWidth="20"
      d="M0.600904 7.08166C0.764293 6.8879 1.01492 6.79004 1.26654 6.82177C2.83216 7.01918 5.20326 7.24581 7.54543 7.23964C9.92491 7.23338 12.1351 6.98464 13.4704 6.32142C13.84 6.13785 14.2885 6.28805 14.4722 6.65692C14.6559 7.02578 14.5052 7.47362 14.1356 7.6572C12.4625 8.48822 9.94063 8.72541 7.54852 8.7317C5.67514 8.73663 3.79547 8.5985 2.29921 8.44247C2.80955 9.59638 3.50943 10.6396 4.24665 11.7384C4.39435 11.9585 4.54354 12.1809 4.69301 12.4068C5.79543 14.0733 6.88128 15.8995 7.1179 18.2636C7.15893 18.6735 6.85928 19.0393 6.4486 19.0805C6.03792 19.1217 5.67174 18.8227 5.6307 18.4128C5.43271 16.4346 4.52957 14.868 3.4457 13.2296C3.3058 13.0181 3.16221 12.8046 3.01684 12.5885C2.05899 11.1646 1.02372 9.62564 0.457909 7.78069C0.383671 7.53862 0.437515 7.27541 0.600904 7.08166ZM5.52039 10.2248C5.77662 9.90161 6.24663 9.84687 6.57018 10.1025C16.4834 17.9344 29.9158 22.4064 42.0781 21.4773C54.1988 20.5514 65.0339 14.2748 69.9746 0.584299C70.1145 0.196597 70.5427 -0.0046455 70.931 0.134813C71.3193 0.274276 71.5206 0.70162 71.3807 1.08932C66.2105 15.4159 54.8056 22.0014 42.1913 22.965C29.6185 23.9254 15.8207 19.3142 5.64226 11.2727C5.31871 11.0171 5.26415 10.5479 5.52039 10.2248Z"
      fill="#3366FF"
    />
  ),
});
