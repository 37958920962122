import { gql, useMutation } from "@apollo/client";
import { updateProductReviewsForDashboard } from "../appsync/graphql/mutations";

export const useUpdateProductReviewsForDashboard = () => {
  const [call, { loading, error, data }] = useMutation<{ success: String }>(gql(updateProductReviewsForDashboard), {
    fetchPolicy: "no-cache",
  });

  return {
    updateProductReviewsForDashboard: call,
    updateProductReviewsForDashboardData: data?.success,
    updateProductReviewsForDashboardLoading: loading,
    updateProductReviewsForDashboardError: error,
  };
};
