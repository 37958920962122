import { Text, Flex, Icon, Box, Heading, VStack } from "@chakra-ui/react";
import { HiHashtag } from "react-icons/hi";

export const NoResultFound = () => {
  return (
    <Flex justifyContent={"center"} pt={20}>
      <VStack>
        <Box pt={2} pb={1} px={2} borderRadius={"xl"} borderColor={"transparent"} bg={"brand.50"}>
          <Icon as={HiHashtag} fontSize={"32"} color={"brand.400"} />
        </Box>
        <Heading size="md" pt={3} color={"gray.800"}>
          No result found
        </Heading>
        <Text p={2} color={"gray.500"}>
          Please change the date or your filters.
        </Text>
      </VStack>
    </Flex>
  );
};
