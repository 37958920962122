import { gql, useLazyQuery } from "@apollo/client";
import { useState } from "react";
import { OrderDetails } from "../appsync/API";
import { getOrders } from "../appsync/graphql/queries";

export const useGetOrders = () => {
  const [isCalled, setIsCalled] = useState(false);
  const [call, { loading, error, data }] = useLazyQuery<{ getOrders: OrderDetails }>(gql(getOrders), {
    fetchPolicy: "no-cache",
  });

  const makeCall = (args?: any) => {
    call(args);
    setIsCalled(true);
  };

  return {
    getOrders: makeCall,
    getOrdersData: data?.getOrders,
    getOrdersLoading: loading,
    getOrdersError: error,
    isCalled: isCalled,
  };
};
