import { ChevronDownIcon } from "@chakra-ui/icons";
import { Box, Text, Button, Card, CardBody, CardFooter, CardHeader, Flex, Heading, Icon, Menu, MenuButton, MenuItem, MenuList, Popover, PopoverArrow, PopoverBody, PopoverContent, PopoverTrigger, Portal, Stat, StatArrow, StatHelpText, StatLabel, StatNumber, VStack, SimpleGrid, HStack, Center } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import Navbar from "../../components/navbar";
import { FiFilter } from "react-icons/fi";
import { BsFillCartCheckFill, BsCheckCircleFill, BsFillStarFill } from "react-icons/bs";
import { BiMoney } from "react-icons/bi";
import { MdScheduleSend } from "react-icons/md";
import { CgSearchLoading } from "react-icons/cg";
import { RiStarSmileFill } from "react-icons/ri";
import { AccountInformationSite } from "../../types/account";
import { PlanLimitReached } from "../../components/plan-limit-reached";
import { useGetProAnalytics } from "../../hooks/get-pro-analytics";
import _ from "lodash";
import { formattedDateAndTime, getDateBeforeXDaysInMonthDDFormat } from "../../utils/helpers";
import { LoadingSpinnerAndText } from "../../components/loading-spinner-and-text";
import { ErrorOccurred } from "../../components/error-occurred";

export default function Analytics({ accountInformation, selectedSite, switchSelectedSite }: { accountInformation: any; selectedSite: AccountInformationSite; switchSelectedSite: any }) {
  useEffect(() => {}, []);
  return <Navbar tabName="Analytics" children={<MainContainer accountInformation={accountInformation} selectedSite={selectedSite}></MainContainer>} accountInformation={accountInformation} selectedSite={selectedSite} switchSelectedSite={switchSelectedSite}></Navbar>;
}

const MainContainer = ({ accountInformation, selectedSite }: { accountInformation: any; selectedSite: any }) => {
  const { getProAnalytics, getProAnalyticsData, getProAnalyticsLoading, getProAnalyticsError } = useGetProAnalytics();
  useEffect(() => {
    getProAnalytics({ variables: { siteId: selectedSite.siteId } });
  }, []);

  const [dateRange, setDateRange] = useState("lastOneDay");

  return (
    <>
      <PlanLimitReached isUsageLimitReached={accountInformation && accountInformation.usage.used >= accountInformation.usage.total}></PlanLimitReached>
      <Box minH="100vh" p={4} bg="#f4f4f4" w="100%">
        <FilterBar
          dateRange={dateRange}
          setDateRange={(dateRange: any) => {
            setDateRange(dateRange);
          }}
        ></FilterBar>
        {getProAnalyticsLoading && (
          <Flex minH={"100vh"} align={"center"} justify={"center"} flex="1">
            <LoadingSpinnerAndText textToShow={"Calculating. It may be take upto a minute..."}></LoadingSpinnerAndText>
          </Flex>
        )}
        {getProAnalyticsError && <ErrorOccurred></ErrorOccurred>}
        {getProAnalyticsData && <AnalyticsWidgets dateRange={dateRange} analyticsData={getProAnalyticsData}></AnalyticsWidgets>}
      </Box>
    </>
  );
};

const FilterBar = ({ dateRange, setDateRange }: { dateRange: any; setDateRange: any }) => {
  const dateRangeFriendlyNamesMapping: any = {
    lastOneDay: "Yesterday",
    lastTwoDays: "Past 2 Days",
    lastSevenDays: "Past 7 Days",
  };

  const selectedDateRange = dateRangeFriendlyNamesMapping[dateRange] || "";
  return (
    <Flex bg={"transparent"} py={2} px={8} mb={2} borderRadius={"2xl"} justifyContent={"right"}>
      <Menu>
        <MenuButton as={Button} rightIcon={<ChevronDownIcon />} size={"sm"} width={"160px"} borderWidth={"1px"} borderColor={"gray.300"} variant={"outline"} bg={"white"}>
          {selectedDateRange}
        </MenuButton>
        <MenuList>
          <MenuItem onClick={() => setDateRange("lastOneDay")}>{dateRangeFriendlyNamesMapping["lastOneDay"]}</MenuItem>
          <MenuItem onClick={() => setDateRange("lastTwoDays")}>{dateRangeFriendlyNamesMapping["lastTwoDays"]}</MenuItem>
          <MenuItem onClick={() => setDateRange("lastSevenDays")}>{dateRangeFriendlyNamesMapping["lastSevenDays"]}</MenuItem>
        </MenuList>
      </Menu>
    </Flex>
  );
};

const AnalyticsWidgets = ({ dateRange, analyticsData }: { dateRange: any; analyticsData: any }) => {
  const [selectedRangeData, setSelectedRangeData] = useState<any>(null);
  const [comparedRangeData, setComparedRangeData] = useState<any>(null);
  useEffect(() => {
    if (dateRange == "lastOneDay") {
      setSelectedRangeData(analyticsData?.lastOneDay?.selectedRangeData);
      setComparedRangeData(analyticsData?.lastOneDay?.comparedRangeData);
    } else if (dateRange == "lastTwoDays") {
      setSelectedRangeData(analyticsData?.lastTwoDays?.selectedRangeData);
      setComparedRangeData(analyticsData?.lastTwoDays?.comparedRangeData);
    } else if (dateRange == "lastSevenDays") {
      setSelectedRangeData(analyticsData?.lastSevenDays?.selectedRangeData);
      setComparedRangeData(analyticsData?.lastSevenDays?.comparedRangeData);
    } else {
      setSelectedRangeData(null);
      setComparedRangeData(null);
    }
  }, [dateRange, analyticsData]);

  const getPercentageChange = (selectedData: number, comparedData: number) => {
    if (isNaN(selectedData) || isNaN(comparedData)) {
      return 0;
    } else if (comparedData == 0) {
      return 0;
    } else {
      return ((selectedData - comparedData) / comparedData) * 100;
    }
  };

  const getDateRangeForDisplay = (calculatedAt: string) => {
    const endDate: string = getDateBeforeXDaysInMonthDDFormat(calculatedAt, 1);
    if (dateRange == "lastOneDay") {
      return endDate;
    } else if (dateRange == "lastTwoDays") {
      const startDate: string = getDateBeforeXDaysInMonthDDFormat(calculatedAt, 2);
      return `${startDate} - ${endDate}`;
    } else if (dateRange == "lastSevenDays") {
      const startDate: string = getDateBeforeXDaysInMonthDDFormat(calculatedAt, 7);
      return `${startDate} - ${endDate}`;
    } else {
      return "";
    }
  };

  const getDateRangeForDisplayForComparedData = (calculatedAt: string) => {
    if (dateRange == "lastOneDay") {
      const endDate: string = getDateBeforeXDaysInMonthDDFormat(calculatedAt, 2);
      return endDate;
    } else if (dateRange == "lastTwoDays") {
      const endDate: string = getDateBeforeXDaysInMonthDDFormat(calculatedAt, 3);
      const startDate: string = getDateBeforeXDaysInMonthDDFormat(calculatedAt, 4);
      return `${startDate} - ${endDate}`;
    } else if (dateRange == "lastSevenDays") {
      const endDate: string = getDateBeforeXDaysInMonthDDFormat(calculatedAt, 8);
      const startDate: string = getDateBeforeXDaysInMonthDDFormat(calculatedAt, 15);
      return `${startDate} - ${endDate}`;
    } else {
      return "";
    }
  };

  const getLastUpdatedForDynamicData = (calculatedAt: string) => {
    const dateAndTime = formattedDateAndTime(calculatedAt);
    const dateInMonthDD = getDateBeforeXDaysInMonthDDFormat(calculatedAt, 0);
    return `last updated at ${dateInMonthDD} ${dateAndTime.time}`;
  };

  return (
    <>
      {selectedRangeData && comparedRangeData && (
        <>
          <Text fontSize={"xs"} pt={"5"} pl={10}>
            Data calculated based on GMT time
          </Text>
          <Box pl={"38px"} pt={"40px"}>
            <HStack verticalAlign={"bottom"}>
              <Text fontSize={"lg"} as="b">
                {getDateRangeForDisplay(selectedRangeData.calculatedAt)}
              </Text>
              <Text fontSize={"sm"} pt={0.5}>
                vs {getDateRangeForDisplayForComparedData(comparedRangeData.calculatedAt)}
              </Text>
            </HStack>
          </Box>
          <SimpleGrid minChildWidth={"330px"} spacing={"30px"} p={"30px"}>
            <SingleCard backgroundColor="brand.600" color="white" title="Orders Received" icon={BsFillCartCheckFill} iconColor={"white"} value={selectedRangeData?.ordersReceived} comparedValue={comparedRangeData?.ordersReceived} percentageChange={getPercentageChange(selectedRangeData?.ordersReceived, comparedRangeData?.ordersReceived)} isDynamicData={false} dynamicCalculatedAt=""></SingleCard>
            <SingleCard backgroundColor="white" color="black" title="Revenue" icon={BiMoney} value={!_.isNaN(selectedRangeData?.revenue) ? "$" + (selectedRangeData.revenue / 100).toFixed(2) : ""} comparedValue={!_.isNaN(comparedRangeData?.revenue) ? "$" + (comparedRangeData.revenue / 100).toFixed(2) : ""} percentageChange={getPercentageChange(selectedRangeData?.revenue, comparedRangeData?.revenue)} iconColor={"#19d44c"} isDynamicData={false} dynamicCalculatedAt=""></SingleCard>
            <SingleCard backgroundColor="white" color="black" title="Sent Review Requests" icon={BsCheckCircleFill} value={selectedRangeData?.sentReviewRequests} comparedValue={comparedRangeData?.sentReviewRequests} iconColor={"#19d44c"} percentageChange={getPercentageChange(selectedRangeData?.sentReviewRequests, comparedRangeData?.sentReviewRequests)} isDynamicData={false} dynamicCalculatedAt=""></SingleCard>
            <SingleCard backgroundColor="white" color="black" title="Average Star Ratings" icon={RiStarSmileFill} iconColor={"brand.600"} value={selectedRangeData?.averageStarRating} comparedValue={comparedRangeData?.averageStarRating} percentageChange={getPercentageChange(selectedRangeData?.averageStarRating, comparedRangeData?.averageStarRating)} isDynamicData={false} dynamicCalculatedAt=""></SingleCard>
            <SingleCard backgroundColor="white" color="black" title="1-Star Reviews" icon={BsFillStarFill} iconColor={"#f3c017"} value={selectedRangeData?.oneStarRating} comparedValue={comparedRangeData?.oneStarRating} percentageChange={getPercentageChange(selectedRangeData?.oneStarRating, comparedRangeData?.oneStarRating)} isDynamicData={false} dynamicCalculatedAt=""></SingleCard>
            <SingleCard backgroundColor="white" color="black" title="5-Star Reviews" icon={BsFillStarFill} iconColor={"#f3c017"} value={selectedRangeData?.fiveStarRating} comparedValue={comparedRangeData?.fiveStarRating} percentageChange={getPercentageChange(selectedRangeData?.fiveStarRating, comparedRangeData?.fiveStarRating)} isDynamicData={false} dynamicCalculatedAt=""></SingleCard>
          </SimpleGrid>
          <Box pl={"38px"} pt={"40px"}>
            <Text fontSize={"xl"} as="b">
              Near Real-time
            </Text>
          </Box>
          <SimpleGrid columns={2} spacing={"30px"} p={"30px"}>
            <SingleCard backgroundColor="white" color="black" title="Scheduled Review Requests" icon={MdScheduleSend} value={analyticsData?.scheduledReviewRequests} comparedValue={analyticsData?.scheduledReviewRequests} iconColor={"#f3c017"} percentageChange={getPercentageChange(selectedRangeData?.scheduledReviewRequests, comparedRangeData?.scheduledReviewRequests)} isDynamicData={true} dynamicCalculatedAt={getLastUpdatedForDynamicData(analyticsData?.dynamicCalculatedAt)}></SingleCard>
            <SingleCard backgroundColor="white" color="black" title="Reviews Pending For Approval" icon={CgSearchLoading} iconColor={"#f3c017"} value={analyticsData?.reviewsPendingForApproval} comparedValue={analyticsData?.reviewsPendingForApproval} percentageChange={getPercentageChange(selectedRangeData?.reviewsPendingForApproval, comparedRangeData?.reviewsPendingForApproval)} isDynamicData={true} dynamicCalculatedAt={getLastUpdatedForDynamicData(analyticsData?.dynamicCalculatedAt)}></SingleCard>
          </SimpleGrid>
        </>
      )}
    </>
  );
};

const SingleCard = ({ backgroundColor, color, title, icon, iconColor, value, comparedValue, percentageChange, isDynamicData, dynamicCalculatedAt }: { backgroundColor: string; color: string; title: string; icon: any; iconColor: string; value: string; comparedValue: string; percentageChange: number | string; isDynamicData: boolean; dynamicCalculatedAt: string }) => {
  return (
    <Card bg={backgroundColor} color={color} borderRadius={"2xl"} textAlign={"center"}>
      <CardBody>
        <Stat>
          <StatLabel fontSize={"lg"} my={"5"}>
            <VStack>
              <Icon as={icon} fontSize={"32"} color={iconColor}></Icon>
              <Text fontSize={"lg"}>{title}</Text>
            </VStack>
          </StatLabel>
          <StatNumber fontSize={"2.5rem"}>{value}</StatNumber>
          <StatHelpText>
            {percentageChange >= 0 ? <StatArrow type="increase" /> : <StatArrow type="decrease" />}
            {comparedValue} ({percentageChange}%)
          </StatHelpText>
        </Stat>
      </CardBody>
      {!isDynamicData && <CardFooter justifyContent={"center"}></CardFooter>}
      {isDynamicData && (
        <CardFooter justifyContent={"center"}>
          <VStack>
            <Text fontSize={"xs"} as="i">
              Periodic updates every six hours.
            </Text>
            <Text fontSize={"xs"} ml={"1"}>
              {dynamicCalculatedAt}
            </Text>
          </VStack>
        </CardFooter>
      )}
    </Card>
  );
};
