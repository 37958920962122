import { lowerCase } from "lodash";
import { config } from "../deployment/config";

export const redirectToWebflowForAuth = () => {
  window.location.replace(`${config.API_ENDPOINT}/webflow/install`);
};

export const geLast30DaysDateRange = () => {
  const today = new Date();
  const thirtyDaysAgo = new Date(today.getTime() - 30 * 24 * 60 * 60 * 1000);
  return [thirtyDaysAgo.toISOString(), today.toISOString()];
};

export const formattedDateAndTime = (dateInISOFormatString: string) => {
  const date = new Date(dateInISOFormatString);
  const day = date.getDate();
  const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  const monthIndex = date.getMonth();
  const year = date.getFullYear();
  const formattedDate = `${day} ${monthNames[monthIndex]} ${year}`;

  const hours = date.getHours();
  const minutes = date.getMinutes();
  const amOrPm = hours >= 12 ? "PM" : "AM";
  const formattedHours = hours % 12 || 12;
  const formattedMinutes = minutes.toString().padStart(2, "0");
  const formattedTime = `${formattedHours}:${formattedMinutes} ${amOrPm}`;

  return {
    date: formattedDate,
    time: formattedTime,
  };
};

export const allowPremiumFeatures = (currentSubscription: any) => {
  if (currentSubscription?.tier && (lowerCase(currentSubscription.tier) == "growth" || lowerCase(currentSubscription.tier) == "pro")) {
    return true;
  } else {
    return false;
  }
};

export const getDateBeforeXDaysInMonthDDFormat = (calculatedAt: string, numDaysBefore: number) => {
  if (!calculatedAt) {
    return "";
  }

  const dateAfterNumDaysBefore: Date = calculateDateBefore(calculatedAt, numDaysBefore);
  return dateAfterNumDaysBefore.toLocaleDateString("en-US", { month: "long", day: "numeric" });
};

export const calculateDateBefore = (dateInISOFormatString: string, days: number): Date => {
  const date = new Date(dateInISOFormatString);
  const millisecondsPerDay = 24 * 60 * 60 * 1000;
  const targetTime = date.getTime() - days * millisecondsPerDay;
  return new Date(targetTime);
};
