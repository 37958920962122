import { Auth } from "aws-amplify";
import axios from "axios";
import { ProductReviewSubmissionDetails } from "../types/submit-product-review";
import { config } from "../deployment/config";

export const submitReview = async (productReview: ProductReviewSubmissionDetails): Promise<any> => {
  let endpoint = `${config.API_ENDPOINT}/reviews/submit`;

  try {
    const response = await axios.post(endpoint, productReview);
    return response?.data;
  } catch (error: any) {
    console.error(`Error submitting review: ${error.message}`);
    return null;
  }
};
