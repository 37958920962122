import configDev from "./config/config-dev";
import configProd from "./config/config-prod";
import configStage from "./config/config-stage";

let config = configDev;

if (process.env.REACT_APP_CLOUD_ENV === "stage") {
  config = configStage;
} else if (process.env.REACT_APP_CLOUD_ENV === "prod") {
  config = configProd;
}

export { config };
