import { each, map } from "lodash";
import { config } from "./../../deployment/config";
import { Email } from "../../types/site";

export const productReviewEmailTemplate = (data: any, emailSettings: Email, removeBrandzwayBranding: boolean, forceUpdateEmailViewer: string) => {
  return `<!DOCTYPE html>
<html>

    <head>
        <title>Product Review Request</title>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <meta name="x-apple-disable-message-reformatting">
        <!--[if !mso]><!-->
        <meta http-equiv="X-UA-Compatible" content="IE=edge">
        <!--<![endif]-->
        <style>
        .email-content-table {
            width: 100%;
            max-width: 640px;
        }
    </style>
    </head>

    <body style="overflow-x: hidden; padding: 0">
        <!-- Full Email Including Background -->
        <table width="100%" border="0" cellspacing="0" cellpadding="0" style="background-color: ${emailSettings.canvasColor}; font-family: Arial, Helvetica, sans-serif;font-size: 16px">
            <tr>
                <td align="center" style="padding: 50px 0px">

                    <!-- Email  Starts here-->
                    <table class="email-content-table" border="0" cellspacing="0" cellpadding="0" >
                        <!-- Logo Image-->
                        <tr style="display:${emailSettings.logoImageEnabled ? "" : "none"}">
                            <td align="center" style="padding: 10px 0px">
                                <img src=${config.MEDIA_BASE_URL + "/" + emailSettings.logoImageDetails.imagePath + "?r=" + forceUpdateEmailViewer}
                                    alt="Brand Logo" style="width:400px; height:120px; object-fit:contain">
                            </td>
                        </tr>

                        <!-- Email Content -->
                        <tr>
                            <td align="center">
                                <table width="100%" border="0" cellspacing="0" cellpadding="0"
                                    style="background-color:  ${emailSettings.panelColor}; color: ${emailSettings.textColor} ;border-radius: 10px;">
                                    
                                    <!-- Banner Image -->
                                    <tr style="display:${emailSettings.bannerImageEnabled ? "" : "none"}">
                                        <td align="center" style="padding: 20px">
                                            <img src=${config.MEDIA_BASE_URL + "/" + emailSettings.bannerImageDetails.imagePath + "?r=" + forceUpdateEmailViewer} width="100%" alt="Banner Image">
                                        </td>
                                    </tr>

                                    <tr>
                                        <td align="center" style="padding: 60px 40px">
                                            ${getBodyWithParagraph(emailSettings.body)}
                                        </td>
                                    </tr>
                                    
                                    <!-- Products-->
                                    ${map(data.productsMetadata, (item: any) => {
                                      return getProductBox(item, emailSettings);
                                    })}

                                    <!-- Footer-->
                                    <tr style="display:${emailSettings.footerEnabled ? "" : "none"}">
                                        <td align="center" style="padding:40px">
                                            <p style="text-align: left;">${getFooterWithParagraph(emailSettings.footerText)}</p>
                                        </td>
                                    </tr>


                                    <!-- Social Media Title -->
                                    <tr style="display:${emailSettings.socialMediaLinkEnabled ? "" : "none"}">
                                        <td align="center" style="padding:20px 20px 10px 20px">
                                            <p style="text-align: center;">${emailSettings.socialMediaText}</p>
                                        </td>
                                    </tr>

                                    <!-- Social Media Links -->
                                    <tr style="display:${emailSettings.socialMediaLinkEnabled ? "" : "none"};">
                                        <td align="center" style="padding: 0px 0px 40px 0px">
                                            <table width="auto" border="0" cellspacing="0" cellpadding="5">
                                                <tr>
                                                    <td align="center">
                                                        <a style="text-decoration: none;display:${emailSettings.fbLink == "" ? "none" : ""}" href=${emailSettings.fbLink}>
                                                            <div style="width: 32px; height: 32px;background-color: ${emailSettings.textColor};border-radius: 50px;">
                                                                <img alt="facebook" src=${config.MEDIA_BASE_URL + "/assets/misc/fb.png"} style="width:32px; padding: 6px" />
                                                            </div>
                                                        </a>
                                                    </td>

                                                    <td align="center">
                                                        <a style="text-decoration: none;display:${emailSettings.tiktokLink == "" ? "none" : ""}" href=${emailSettings.tiktokLink}>
                                                            <div style="width: 32px; height: 32px;background-color: ${emailSettings.textColor};border-radius: 50px;">
                                                                <img alt="tiktok" src=${config.MEDIA_BASE_URL + "/assets/misc/tik-tok.png"} style="width:32px;padding: 8px" />
                                                            </div>
                                                        </a>
                                                    </td>

                                                    <td align="center">
                                                        <a style="text-decoration: none;display:${emailSettings.instagramLink == "" ? "none" : ""}" style="text-decoration: none;" href=${emailSettings.instagramLink}>
                                                            <div style="width: 32px; height: 32px;background-color: ${emailSettings.textColor};border-radius: 50px;">
                                                                <img alt="instagram" src=${config.MEDIA_BASE_URL + "/assets/misc/insta.png"} style="width:32px;padding: 8px" />
                                                            </div>
                                                        </a>
                                                    </td>

                                                    <td align="center">
                                                        <a style="text-decoration: none;display:${emailSettings.twitterLink == "" ? "none" : ""}" href=${emailSettings.twitterLink}>
                                                            <div style="width: 32px; height: 32px;background-color: ${emailSettings.textColor};border-radius: 50px;">
                                                                <img alt="twitter" src=${config.MEDIA_BASE_URL + "/assets/misc/twitter.png"} style="width:32px;padding: 4px" />
                                                            </div>
                                                        </a>
                                                    </td>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>

                                </table>
                            </td>
                        </tr>

                        <!-- Powered by Brandzway-->
                        <tr style="display:${removeBrandzwayBranding ? "none" : ""}">
                            <td align="center" style="padding: 70px 0px 0px 0px">
                                 <img src=${config.MEDIA_BASE_URL + "/assets/by-kit/brandzway-200-logo-gray.png"} style="width:42px;" />
                            </td>
                        </tr>
                        <tr style="display:${removeBrandzwayBranding ? "none" : ""}">
                            <td align="center" style="padding: 10px 0px 0px 0px; color:#5a5a5a; font-size:14px">
                                Powered by Brandzway
                            </td>
                        </tr>
                    </table>
                    <!-- Email  Ends here-->

                </td>
            </tr>
        </table>
    </body>

</html>`;
};

const getProductBox = (productMetadata: any, emailSettings: any) => {
  return `
<tr>
   <td align="center" style="padding: 40px;">
      <table width="100%" border="0" cellspacing="0" cellpadding="0">
         <!-- Product Image-->
         <tr>
            <td align="center">
               <img src=${productMetadata.imageUrl}
                  alt="Product Image" width="250">
            </td>
         </tr>
         <!-- Product Name-->
         <tr>
            <td align="center">
               <p style="font-weight: bold;text-align: center; padding: 10px 0px; color:${emailSettings.productNameColor}">${productMetadata.name}</p>
            </td>
         </tr>
         <!-- Review Button-->
         ${emailSettings.reviewButtonType === "Stars" ? getStarsButton(emailSettings.reviewButtonStarColor, productMetadata.writeReviewButtonUrl) : getReviewButton(emailSettings.reviewButtonTextColor, emailSettings.reviewButtonBackgroundColor, emailSettings.reviewButtonText, emailSettings.reviewButtonBorderRadius, productMetadata.writeReviewButtonUrl)}
      </table>
   </td>
</tr>`;
};

const getReviewButton = (textColor: string, backgroundColor: string, buttonText: string, borderRadius: number, writeReviewButtonUrl: string) => {
  return `<tr>
   <td align="center" style="padding-top: 20px;">
      <a href=${writeReviewButtonUrl}
         style="background-color: ${backgroundColor}; 
         color: ${textColor}; 
         padding: 10px 50px; 
         text-align: center; 
         text-decoration: none; 
         display: inline-block; 
         font-size: 18px;
         border-radius: ${borderRadius}px;">${buttonText}</a>
   </td>
</tr>`;
};

const getStarsButton = (starBtnBgdColor: string, starReviewButtonUrl: string) => {
  return `<tr>
   <td align="center" style="padding-top: 20px;">
        <a href=${starReviewButtonUrl} style="text-decoration: none">
            <div style="padding: 8px 0px; background-color: ${starBtnBgdColor}; border-radius: 20px; width:200px">
                <table border="0" cellspacing="2" cellpadding="2" >
                    <tr>
                        <td>${starImg()}</td>
                        <td>${starImg()}</td>
                        <td>${starImg()}</td>
                        <td>${starImg()}</td>
                        <td>${starImg()}</td>
                    </tr>
                </table>
            </div>
        </a>
   </td>
</tr>`;
};

const starImg = () => {
  return `<img alt="star" src="https://media.brandzway.com/assets/misc/star-filled-white.png" style="opacity: 0.5" width="24" width="24" />`;
};

const getBodyWithParagraph = (bodyText: string) => {
  let html = "";
  bodyText.split("\n").forEach((paragraph) => {
    if (paragraph !== "") {
      html += `<p style="text-align: left;padding:15px 0px; margin: 0">${paragraph}</p>`;
    }
  });
  return html;
};

const getFooterWithParagraph = (bodyText: string) => {
  let html = "";
  bodyText.split("\n").forEach((paragraph) => {
    if (paragraph !== "") {
      html += `<p style="text-align: center;padding:4px 0px; margin: 0">${paragraph}</p>`;
    }
  });
  return html;
};
