import { ArrowLeftIcon, ArrowRightIcon, ChevronLeftIcon, ChevronRightIcon, WarningTwoIcon } from "@chakra-ui/icons";
import { Table, Text, Thead, Tbody, Tr, Th, Td, Box, Flex, Button, Center, Popover, PopoverTrigger, Portal, PopoverContent, PopoverArrow, PopoverBody, VStack, Checkbox, HStack, IconButton, Tooltip, Select, Icon, Spacer } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import Navbar from "../../components/navbar";
// import { usePagination, useTable } from "react-table";
import React from "react";
import { usePagination, useSortBy, useTable } from "react-table";
import { FaSortUp, FaSortDown } from "react-icons/fa";
import { FiFilter } from "react-icons/fi";
import _ from "lodash";
import { useGetOrders } from "../../hooks/get-orders";
import { useSendProductReviewRequest } from "../../hooks/send-product-review-request";
import { DateRangePicker } from "../../components/date-range-picker";
import { AccountInformation, AccountInformationSite } from "../../types/account";
import { allowPremiumFeatures, formattedDateAndTime, geLast30DaysDateRange } from "../../utils/helpers";
import { PlanLimitReached } from "../../components/plan-limit-reached";
import { NoResultFound } from "../../components/no-result-found";
import { MdOutlinePending, MdPending } from "react-icons/md";
import { IoIosCheckmarkCircle } from "react-icons/io";
import { BsXCircleFill } from "react-icons/bs";
import { RiRefund2Fill } from "react-icons/ri";
import { MdWatchLater } from "react-icons/md";
import { LoadingSpinnerAndText } from "../../components/loading-spinner-and-text";
import { ErrorOccurred } from "../../components/error-occurred";

export default function Orders({ accountInformation, selectedSite, switchSelectedSite }: { accountInformation: AccountInformation; selectedSite: AccountInformationSite; switchSelectedSite: any }) {
  useEffect(() => {}, []);
  return <Navbar tabName="Orders" children={<MainContainer selectedSite={selectedSite} accountInformation={accountInformation}></MainContainer>} accountInformation={accountInformation} selectedSite={selectedSite} switchSelectedSite={switchSelectedSite}></Navbar>;
}

const MainContainer = ({ selectedSite, accountInformation }: { selectedSite: AccountInformationSite; accountInformation: AccountInformation }) => {
  const { getOrders, getOrdersData, getOrdersLoading, getOrdersError, isCalled } = useGetOrders();
  const [dateRangeinISO, setDateRangeinISO] = useState(geLast30DaysDateRange());

  useEffect(() => {
    if (selectedSite) {
      getOrders({ variables: { siteId: selectedSite.siteId, minDate: dateRangeinISO[0], maxDate: dateRangeinISO[1] } });
    }
  }, [selectedSite]);

  const [reviewStatusFilters, setReviewStatusFilters] = useState({
    yetToSchedule: true,
    notScheduled: true,
    scheduled: true,
    sent: true,
    manualSent: true,
    failedToSend: true,
    sendToEmailNotAllowed: true,
  });

  const setDateRange = (dateRange: any) => {
    let minDate = dateRange[0];
    let maxDate = dateRange[1];
    setDateRangeinISO([minDate, maxDate]);
    getOrders({ variables: { siteId: selectedSite.siteId, minDate: minDate, maxDate: maxDate } });
  };

  return (
    <>
      <PlanLimitReached isUsageLimitReached={accountInformation && accountInformation.usage.used >= accountInformation.usage.total}></PlanLimitReached>
      <Box minH="100vh" p={4} bg="#edf2f7" w="100%">
        <FilterBar
          reviewStatusFilters={reviewStatusFilters}
          setReviewStatusFilters={setReviewStatusFilters}
          setDateRange={(dateRange: any) => {
            setDateRange(dateRange);
          }}
        ></FilterBar>
        {isCalled && (
          <>
            {getOrdersLoading && (
              <Flex minH={"90vh"} align={"center"} justify={"center"}>
                <LoadingSpinnerAndText textToShow={"Fetching orders..."}></LoadingSpinnerAndText>
              </Flex>
            )}
            {getOrdersError && (
              <Flex minH={"90vh"} justify={"center"}>
                <ErrorOccurred></ErrorOccurred>
              </Flex>
            )}
            {getOrdersData && <OrdersTable siteId={selectedSite.siteId} ordersData={getOrdersData} reviewStatusFilters={reviewStatusFilters} accountInformation={accountInformation}></OrdersTable>}
          </>
        )}
        {/* {isCalled && !getOrdersLoading ? (
          <OrdersTable siteId={selectedSite.siteId} ordersData={getOrdersData} reviewStatusFilters={reviewStatusFilters} accountInformation={accountInformation}></OrdersTable>
        ) : (
          <Flex minH={"90vh"} align={"center"} justify={"center"}>
            <LoadingSpinnerAndText textToShow={"Fetching orders..."}></LoadingSpinnerAndText>
          </Flex>
        )} */}
      </Box>
    </>
  );
};

const FilterBar = ({ reviewStatusFilters, setReviewStatusFilters, setDateRange }: { reviewStatusFilters: any; setReviewStatusFilters: any; setDateRange: any }) => {
  return (
    <Flex bg={"white"} py={2} px={8} mb={2} borderRadius={"2xl"} justifyContent={"right"}>
      <Box w={"330px"} mr={"20px"} cursor={"pointer"}>
        <DateRangePicker
          isHorizontalStack={true}
          selectedDateRange={(dateRange: any) => {
            setDateRange(dateRange);
          }}
        ></DateRangePicker>
      </Box>
      <Spacer />
      <Popover placement="right-end">
        <PopoverTrigger>
          <Button size={"md"} leftIcon={<Icon as={FiFilter} />}>
            Filter By
          </Button>
        </PopoverTrigger>
        <Portal>
          <PopoverContent>
            <PopoverArrow />
            <PopoverBody>
              <Box>
                <Text as="b" fontSize={"sm"}>
                  Review Status
                </Text>
                <VStack alignItems={"left"} mt={2} mb={5}>
                  <Checkbox
                    colorScheme="brand"
                    isChecked={reviewStatusFilters.yetToSchedule}
                    onChange={(e) => {
                      setReviewStatusFilters({ ...reviewStatusFilters, yetToSchedule: e.target.checked });
                    }}
                  >
                    Yet to Schedule
                  </Checkbox>
                  <Checkbox
                    colorScheme="brand"
                    isChecked={reviewStatusFilters.scheduled}
                    onChange={(e) => {
                      setReviewStatusFilters({ ...reviewStatusFilters, scheduled: e.target.checked });
                    }}
                  >
                    Scheduled
                  </Checkbox>
                  <Checkbox
                    colorScheme="brand"
                    isChecked={reviewStatusFilters.notScheduled}
                    onChange={(e) => {
                      setReviewStatusFilters({ ...reviewStatusFilters, notScheduled: e.target.checked });
                    }}
                  >
                    Send Review Request
                  </Checkbox>
                  <Checkbox
                    colorScheme="brand"
                    isChecked={reviewStatusFilters.sent}
                    onChange={(e) => {
                      setReviewStatusFilters({ ...reviewStatusFilters, sent: e.target.checked });
                    }}
                  >
                    Sent
                  </Checkbox>
                  <Checkbox
                    colorScheme="brand"
                    isChecked={reviewStatusFilters.manualSent}
                    onChange={(e) => {
                      setReviewStatusFilters({ ...reviewStatusFilters, manualSent: e.target.checked });
                    }}
                  >
                    Manual Sent
                  </Checkbox>
                  <Checkbox
                    colorScheme="brand"
                    isChecked={reviewStatusFilters.failedToSend}
                    onChange={(e) => {
                      setReviewStatusFilters({ ...reviewStatusFilters, failedToSend: e.target.checked });
                    }}
                  >
                    Failed to Send
                  </Checkbox>
                  <Checkbox
                    colorScheme="brand"
                    isChecked={reviewStatusFilters.sendToEmailNotAllowed}
                    onChange={(e) => {
                      setReviewStatusFilters({ ...reviewStatusFilters, sendToEmailNotAllowed: e.target.checked });
                    }}
                  >
                    Email Blocked
                  </Checkbox>
                </VStack>

                <HStack mt={4}>
                  <Button
                    variant={"outline"}
                    colorScheme="brand"
                    size="sm"
                    onClick={() => {
                      setReviewStatusFilters({
                        yetToSchedule: false,
                        notScheduled: false,
                        scheduled: false,
                        sent: false,
                        manualSent: false,
                        failedToSend: false,
                        sendToEmailNotAllowed: false,
                      });
                    }}
                  >
                    Clear All
                  </Button>
                </HStack>
              </Box>
            </PopoverBody>
          </PopoverContent>
        </Portal>
      </Popover>
    </Flex>
  );
};

const OrdersTable = ({ siteId, ordersData, reviewStatusFilters, accountInformation }: { siteId: string; ordersData: any; reviewStatusFilters: any; accountInformation: any }) => {
  const columns = React.useMemo(
    () => [
      {
        Header: "Order Id",
        accessor: "orderId",
      },
      {
        Header: "Date",
        accessor: "orderDateTime",
      },
      {
        Header: "Name",
        accessor: "customerFullName",
      },
      {
        Header: "Total Price",
        accessor: "totalPrice",
      },
      {
        Header: "Status",
        accessor: "orderStatus",
      },
      {
        Header: "Review Request",
        accessor: "orderReviewStatus",
      },
    ],
    []
  );

  const checkIfFallsInFilter = (reviewStatus: string) => {
    if (reviewStatus == "Scheduled" && reviewStatusFilters.scheduled) {
    } else if (reviewStatus == "Not Scheduled" && reviewStatusFilters.notScheduled) {
    } else if (reviewStatus == "Yet to Schedule" && reviewStatusFilters.yetToSchedule) {
    } else if (reviewStatus == "Sent" && reviewStatusFilters.sent) {
    } else if (reviewStatus == "Manual Sent" && reviewStatusFilters.manualSent) {
    } else if (reviewStatus == "Failed to Send" && reviewStatusFilters.failedToSend) {
    } else if (reviewStatus == "Send to Email Not Allowed" && reviewStatusFilters.sendToEmailNotAllowed) {
    } else {
      return false;
    }

    return true;
  };

  // Ref : get Data
  //let data = React.useMemo(() => makeData(100), []);
  const [data, setData] = useState(ordersData);

  const setDataWithFilter = () => {
    const newData = _.filter(ordersData, (row) => {
      return checkIfFallsInFilter(row.orderReviewStatus);
    });
    setData(newData);
  };

  const updateStatus = (originalObject: any, finalOrderReviewStatus: string) => {
    const newData = data;
    for (let idx = 0; idx < newData.length; idx++) {
      if (newData[idx] == originalObject) {
        if (finalOrderReviewStatus == "Failed to Send") {
          newData[idx].orderReviewStatus = "Failed to Send";
        } else if (finalOrderReviewStatus == "Send to Email Not Allowed") {
          newData[idx].orderReviewStatus = "Send to Email Not Allowed";
        } else {
          newData[idx].orderReviewStatus = "Manual Sent";
        }
        setData([...newData]);
        break;
      }
    }
  };

  useEffect(() => {
    setDataWithFilter();
  }, [JSON.stringify(data), JSON.stringify(reviewStatusFilters)]);

  return data && (data.length == 0 ? <NoResultFound></NoResultFound> : <OrderTableRows siteId={siteId} columns={columns} data={data} updateStatus={updateStatus} accountInformation={accountInformation}></OrderTableRows>);
};

const OrderTableRows = ({ siteId, columns, data, updateStatus, accountInformation }: { siteId: string; columns: any; data: any; updateStatus: any; accountInformation: any }) => {
  //page, canPreviousPage, canNextPage, pageOptions, pageCount, gotoPage, nextPage, previousPage, setPageSize
  //initialState: { pageIndex: 0 },
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0,
      },
      autoResetSortBy: false,
      autoResetFilters: false,
    },
    useSortBy,
    usePagination
  );

  return (
    <Box bg={"white"} border="1px" borderRadius={"2xl"} borderColor={"#efefef"} p={4} height={"80%"}>
      <Table {...getTableProps()}>
        <Thead>
          {headerGroups.map((headerGroup: any) => (
            <Tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column: any) => (
                <Th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  <HeaderNameAndSorting columnName={column.render("Header")} column={column}></HeaderNameAndSorting>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {page.map((row: any, i: any) => {
            prepareRow(row);
            return (
              <Tr {...row.getRowProps()}>
                {row.cells.map((cell: any, idx: number) => {
                  if (idx == 1) {
                    return (
                      <Td {...cell.getCellProps()}>
                        <VStack align={"left"} spacing={0}>
                          <Text pl={1.5}>{formattedDateAndTime(cell.value).date}</Text>
                          <Text fontSize={"xs"} color={"gray.500"} pl={1.5}>
                            {formattedDateAndTime(cell.value).time}
                          </Text>
                        </VStack>
                      </Td>
                    );
                  } else if (idx == 3) {
                    return (
                      <Td {...cell.getCellProps()}>
                        <Text pl={1.5}>{"$" + (cell.value / 100).toFixed(2)}</Text>
                      </Td>
                    );
                  } else if (idx == 4) {
                    return (
                      <Td {...cell.getCellProps()}>
                        <GetStatusHTML status={cell.value}></GetStatusHTML>
                      </Td>
                    );
                  } else if (idx == 5) {
                    return (
                      <Td {...cell.getCellProps()}>
                        <GetReviewStatusHTML siteId={siteId} status={cell.value} orderId={row.values.orderId} updateStatus={(finalOrderReviewStatus: string) => updateStatus(cell.row.original, finalOrderReviewStatus)} isUsageLimitReached={accountInformation.usage.used >= accountInformation.usage.total} currentSubscription={accountInformation.currentSubscription}>
                          {cell.render("Cell")}
                        </GetReviewStatusHTML>
                      </Td>
                    );
                  } else {
                    return (
                      <Td {...cell.getCellProps()}>
                        <Text pl={1.5}>{cell.render("Cell")}</Text>
                      </Td>
                    );
                  }
                })}
              </Tr>
            );
          })}
        </Tbody>
      </Table>
      <PagniationBar canPreviousPage={canPreviousPage} previousPage={previousPage} gotoPage={gotoPage} pageIndex={pageIndex} pageOptions={pageOptions} nextPage={nextPage} canNextPage={canNextPage} pageCount={pageCount} pageSize={pageSize} setPageSize={setPageSize}></PagniationBar>
    </Box>
  );
};

const HeaderNameAndSorting = ({ columnName, column }: { columnName: string; column: any }) => {
  return (
    <HStack>
      <Text bg={getBackgroundColorForColumn(column.isSorted)} p={2} borderRadius={"lg"}>
        {columnName}
      </Text>
      <VStack height={"10px"}>
        <Icon as={FaSortUp} mt={-1} color={sortArrowColor(column.isSorted, !column.isSortedDesc)} fontSize={16}></Icon>
        <Icon as={FaSortDown} mt={-5} color={sortArrowColor(column.isSorted, column.isSortedDesc)} fontSize={16}></Icon>
      </VStack>
    </HStack>
  );
};

const getBackgroundColorForColumn = (isSorted: boolean) => {
  if (isSorted) {
    return "brand.100";
  } else {
    return "";
  }
};

const sortArrowColor = (isSorted: boolean, sortedAsArrow: boolean) => {
  if (isSorted && sortedAsArrow) {
    return "brand.500";
  } else {
    return "gray.400";
  }
};

const PagniationBar = ({ canPreviousPage, previousPage, gotoPage, pageIndex, pageOptions, nextPage, canNextPage, pageCount, pageSize, setPageSize }: { canPreviousPage: any; previousPage: any; gotoPage: any; pageIndex: any; pageOptions: any; nextPage: any; canNextPage: any; pageCount: any; pageSize: any; setPageSize: any }) => {
  return (
    <Flex justifyContent="space-between" m={4} alignItems="center">
      <Flex>
        <Tooltip label="First Page">
          <IconButton aria-label="Go to page 1" onClick={() => gotoPage(0)} isDisabled={!canPreviousPage} icon={<ArrowLeftIcon h={3} w={3} />} mr={3} />
        </Tooltip>
        <Tooltip label="Last Page">
          <IconButton aria-label="Go to last page" onClick={() => gotoPage(pageCount - 1)} isDisabled={!canNextPage} icon={<ArrowRightIcon h={3} w={3} />} ml={3} />
        </Tooltip>

        <Select
          aria-label="Number of items to show"
          fontSize={"sm"}
          w={32}
          value={pageSize}
          ml={4}
          onChange={(e) => {
            setPageSize(Number(e.target.value));
          }}
        >
          {[10, 20, 30].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </Select>
      </Flex>

      <Flex>
        <Center>
          <Text flexShrink="0" mr={8} fontSize={"sm"}>
            Page{" "}
            <Text fontWeight="bold" as="span">
              {pageIndex + 1}
            </Text>{" "}
            of{" "}
            <Text fontWeight="bold" as="span">
              {pageOptions.length}
            </Text>
          </Text>
        </Center>
        <Tooltip label="Previous Page">
          <IconButton onClick={previousPage} isDisabled={!canPreviousPage} icon={<ChevronLeftIcon h={6} w={6} />} aria-label={"Go to previous page"} />
        </Tooltip>
        <Tooltip label="Next Page">
          <IconButton onClick={nextPage} isDisabled={!canNextPage} icon={<ChevronRightIcon h={6} w={6} />} ml={4} aria-label={"Go to next page"} />
        </Tooltip>
      </Flex>
    </Flex>
  );
};

const GetStatusHTML = ({ status }: { status: string }) => {
  return (
    <>
      {status.toLocaleLowerCase() == "pending" && (
        <HStack>
          <Icon as={MdPending} fontSize="18" color={"gray.600"} />
          <Text>Pending</Text>
        </HStack>
      )}

      {status.toLocaleLowerCase() == "unfulfilled" && (
        <HStack>
          <Icon as={MdWatchLater} fontSize="18" color={"orange.400"} />
          <Text>Unfulfilled</Text>
        </HStack>
      )}

      {status.toLocaleLowerCase() == "fulfilled" && (
        <HStack>
          <Icon as={IoIosCheckmarkCircle} fontSize="18" color={"green.300"} />
          <Text>Fulfilled</Text>
        </HStack>
      )}

      {status.toLocaleLowerCase() == "disputed" && (
        <HStack>
          <Icon as={BsXCircleFill} fontSize="18" color={"red.500"} />
          <Text>Disputed</Text>
        </HStack>
      )}

      {status.toLocaleLowerCase() == "dispute-lost" && (
        <HStack>
          <Icon as={BsXCircleFill} fontSize="18" color={"red.500"} />
          <Text>Dispute-lost</Text>
        </HStack>
      )}

      {status.toLocaleLowerCase() == "refunded" && (
        <HStack>
          <Icon as={RiRefund2Fill} fontSize="18" color={"orange.400"} />
          <Text>Refunded</Text>
        </HStack>
      )}
    </>
  );
};

const GetReviewStatusHTML = ({ siteId, status, children, orderId, updateStatus, isUsageLimitReached, currentSubscription }: { siteId: string; status: string; children: any; orderId: string; updateStatus: any; isUsageLimitReached: boolean; currentSubscription: any }) => {
  const { sendProductReviewRequest, sendProductReviewRequestData, sendProductReviewRequestLoading, sendProductReviewRequestError } = useSendProductReviewRequest();
  const sendReviewRequestBtnClick = () => {
    sendProductReviewRequest({ variables: { siteId, orderId } });
  };

  useEffect(() => {
    if (sendProductReviewRequestData) {
      updateStatus(sendProductReviewRequestData.finalOrderReviewStatus);
    }
  }, [sendProductReviewRequestLoading]);

  return (
    (status == "Sent" && (
      <HStack w={"180px"} spacing={1} bg={"green.100"} py={2} px={4} justifyContent={"center"} borderRadius={"lg"}>
        <Icon as={IoIosCheckmarkCircle} fontSize="16" color={"green.500"} />
        <Text as="b" fontSize={"0.9rem"}>
          {children}
        </Text>
      </HStack>
    )) ||
    (status == "Manual Sent" && (
      <HStack w={"180px"} spacing={1} bg={"green.100"} py={2} px={4} justifyContent={"center"} borderRadius={"lg"}>
        <Icon as={IoIosCheckmarkCircle} fontSize="16" color={"green.500"} />
        <Text as="b" fontSize={"0.9rem"}>
          {children}
        </Text>
      </HStack>
    )) ||
    (status == "Scheduled" && (
      <HStack w={"180px"} spacing={1} bg={"orange.100"} py={2} px={4} justifyContent={"center"} borderRadius={"lg"}>
        <Icon as={MdWatchLater} fontSize="16" color={"orange.400"} />
        <Text as="b" fontSize={"0.9rem"}>
          {children}
        </Text>
      </HStack>
    )) ||
    (status == "Failed to Send" && (
      <VStack align={"left"} w={"180px"}>
        <HStack w={"180px"} spacing={1} bg={"red.100"} py={2} px={4} justifyContent={"center"} borderRadius={"lg"}>
          <Icon as={BsXCircleFill} fontSize="14" color={"red.500"} />
          <Text as="b" fontSize={"0.8rem"}>
            {"Failed"}
          </Text>
        </HStack>
        <Tooltip isDisabled={!isUsageLimitReached} hasArrow label="Plan limit reached">
          <Button size={"xs"} colorScheme={"brand"} isLoading={sendProductReviewRequestLoading} onClick={sendReviewRequestBtnClick} disabled={isUsageLimitReached || sendProductReviewRequestLoading}>
            Retry
          </Button>
        </Tooltip>
      </VStack>
    )) ||
    (status == "Send to Email Not Allowed" && (
      <HStack w={"180px"} spacing={1} bg={"red.100"} py={2} px={4} justifyContent={"center"} borderRadius={"lg"}>
        <Icon as={BsXCircleFill} fontSize="14" color={"red.500"} />
        <Text as="b" fontSize={"0.8rem"}>
          {"Email Blocked"}
        </Text>
      </HStack>
    )) ||
    (status == "Yet to Schedule" && (
      <HStack w={"180px"} spacing={1} bg={"orange.100"} py={2} px={4} justifyContent={"center"} borderRadius={"lg"}>
        <Icon as={MdOutlinePending} fontSize="16" color={"orange.400"} />
        <Text as="b" fontSize={"0.8rem"}>
          {"Yet to Schedule"}
        </Text>
      </HStack>
    )) || (
      <>
        {!isUsageLimitReached && (
          <Button colorScheme={"brand"} isLoading={sendProductReviewRequestLoading} onClick={sendReviewRequestBtnClick} isDisabled={isUsageLimitReached || sendProductReviewRequestLoading || !allowPremiumFeatures(currentSubscription)}>
            Send Review Request
          </Button>
        )}
        {isUsageLimitReached && (
          <HStack spacing={1} px={"1"}>
            <Icon as={WarningTwoIcon} color={"red"} boxSize={3} />
            <Text fontSize={"sm"} color={"red"} fontWeight={"semibold"}>
              Plan Limit Reached
            </Text>
          </HStack>
        )}
      </>
    )
  );
};
