import { gql, useMutation } from "@apollo/client";
import { SendProductReviewRequestResult } from "../appsync/API";
import { sendProductReviewRequest } from "../appsync/graphql/mutations";

export const useSendProductReviewRequest = () => {
  const [call, { loading, error, data }] = useMutation<{ sendProductReviewRequest: SendProductReviewRequestResult }>(gql(sendProductReviewRequest), {
    fetchPolicy: "no-cache",
  });

  return {
    sendProductReviewRequest: call,
    sendProductReviewRequestData: data?.sendProductReviewRequest,
    sendProductReviewRequestLoading: loading,
    sendProductReviewRequestError: error,
  };
};
