/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createSite = /* GraphQL */ `
  mutation CreateSite($code: String) {
    createSite(code: $code) {
      ... on Success {
        message
      }
      ... on UserAuthenticationError {
        message
      }
      ... on CreateSiteFailedToGetAccessToken {
        message
      }
      ... on CreateSiteInvalidCode {
        message
      }
      ... on CreateSiteUserNotAllowedAuthorization {
        message
      }
    }
  }
`;
export const updateSiteSettings = /* GraphQL */ `
  mutation UpdateSiteSettings($siteId: String!, $siteSettings: ISiteSettings!) {
    updateSiteSettings(siteId: $siteId, siteSettings: $siteSettings)
  }
`;
export const firstTimeSetup = /* GraphQL */ `
  mutation FirstTimeSetup($siteSettings: ISiteSettings!) {
    firstTimeSetup(siteSettings: $siteSettings)
  }
`;
export const updateProductReviewsForDashboard = /* GraphQL */ `
  mutation UpdateProductReviewsForDashboard(
    $productReviewRequestModerationDetails: IProductReviewRequestModerationDetails
  ) {
    updateProductReviewsForDashboard(
      productReviewRequestModerationDetails: $productReviewRequestModerationDetails
    )
  }
`;
export const sendProductReviewRequest = /* GraphQL */ `
  mutation SendProductReviewRequest($siteId: String!, $orderId: String!) {
    sendProductReviewRequest(siteId: $siteId, orderId: $orderId) {
      message
      finalOrderReviewStatus
    }
  }
`;
export const registerSubscription = /* GraphQL */ `
  mutation RegisterSubscription($byPlanId: String!) {
    registerSubscription(byPlanId: $byPlanId) {
      status
      approveLink
      subscriptionId
    }
  }
`;
