import { Flex, Box, Stack, VStack, Text, HStack, Switch, Tag, Spacer, Spinner, Center, Button, useToast, Icon } from "@chakra-ui/react";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { useEffect, useRef, useState } from "react";
import { useCreateSubscription } from "../../hooks/subscription/register-subscription";
import { useNavigate, useLocation } from "react-router-dom";
import { ArrowBackIcon, WarningTwoIcon } from "@chakra-ui/icons";
import { useGetCurrentPlanStatus } from "../../hooks/subscription/get-current-plan-status";
import { config } from "../../deployment/config";

enum PlanSwitchStatus {
  NotStarted,
  Processing,
  ProcessingApprovePayment,
  CheckingPlanStatus,
  FirstTimeCheckingPlanStatus,
  FirstTimePlanStatusCheckDone,
  Success,
  Failed,
}

export default function Checkout({ fetchAccountInformation }: { fetchAccountInformation: any }) {
  const location = useLocation();
  const navigate = useNavigate();
  const toast = useToast();
  const newPlan = location.state?.newPlan;
  const isYearlyPlan = location.state?.isYearlyPlan;
  const isUpgrade = location.state?.isUpgrade;
  const monthlyPrice = location.state?.monthlyPrice;
  const yearlyPrice = location.state?.yearlyPrice;
  const byPlanIdBase = location.state?.byPlanIdBase;
  const resumeSubscription = location.state?.resumeSubscription;
  let [payForYearly, setPayForYearly] = useState(false);
  const payForYearlySwitchRef = useRef<any>(payForYearly);

  const { registerSubscription, registerSubscriptionData, registerSubscriptionLoading, registerSubscriptionError, isCalled } = useCreateSubscription();
  const { getCurrentPlanStatus, getCurrentPlanStatusData, getCurrentPlanStatusLoading, getCurrentPlanStatusError } = useGetCurrentPlanStatus();
  const clientId = config.PAYPAL_CLIENT_ID;

  const [paymentConfirmed, setPaymentConfirmed] = useState(false);
  const [planSwitchStatus, setPlanSwitchStatus] = useState<PlanSwitchStatus>(PlanSwitchStatus.NotStarted);
  const [planStatusCheckCount, setPlanStatusCheckCount] = useState(0);

  const getPlanId = () => {
    let planId = byPlanIdBase;
    const billingCycleSuffix = isYearlyPlan ? "_yearly" : "_monthly";
    return planId + billingCycleSuffix;
  };

  const checkIfYearlyPlanSelected = () => {
    if (isYearlyPlan || payForYearlySwitchRef.current.checked) {
      return true;
    }

    return false;
  };

  const createSubscription = async (data: any, actions: any) => {
    try {
      const response = await registerSubscription({ variables: { byPlanId: getPlanId() } });
      const subscriptionId = response.data?.registerSubscription.subscriptionId || "";
      return subscriptionId;
    } catch (e) {
      toast({
        title: "Something went wrong.",
        status: "error",
        position: "top-right",
        duration: 5000,
        isClosable: true,
      });
      throw e;
    }
  };

  const checkCurrentPlanStatus = async (byPlanIdToCheck: string) => {
    const response = await getCurrentPlanStatus({ variables: { id: "1" } });
    const byPlanId = response.data?.getCurrentPlanStatus.byPlanId;
    return byPlanIdToCheck === byPlanId;
  };

  const completePlanSwitch = async () => {
    try {
      setPlanSwitchStatus(PlanSwitchStatus.Processing);
      const response = await registerSubscription({ variables: { byPlanId: getPlanId() } });
      const approveLink = response.data?.registerSubscription.approveLink as string;
      const status = response.data?.registerSubscription.status as string;
      if (status == "pending") {
        setPlanSwitchStatus(PlanSwitchStatus.ProcessingApprovePayment);
        if (approveLink.length > 0) {
          openPopupToApprovePayment(approveLink);
          pollForPlanStatus(true);
          return;
        }
      } else if (status == "success") {
        pollForPlanStatus(false);
        return;
      }

      throw Error("Something went wrong");
    } catch (e) {
      toast({
        title: "Something went wrong. Please try again later.",
        status: "error",
        position: "top-right",
        duration: 5000,
        isClosable: true,
      });
      setPlanSwitchStatus(PlanSwitchStatus.NotStarted);
    }
  };

  const openPopupToApprovePayment = (approveLink: string) => {
    const windowFeatures = `
            width=800, 
            height=600, 
            left=${(window.innerWidth - 800) / 2}, 
            top=${(window.innerHeight - 600) / 2},
            noreferrer,noopener
        `;
    window.open(approveLink, "_blank", windowFeatures);
  };

  const pollForPlanStatus = async (isDoingWithApprovePayment: boolean) => {
    if (planStatusCheckCount != 0) {
      return;
    }

    let pollCount = 18;
    if (isDoingWithApprovePayment) {
      setPlanSwitchStatus(PlanSwitchStatus.ProcessingApprovePayment);
    } else {
      pollCount = 30;
      setPlanSwitchStatus(PlanSwitchStatus.CheckingPlanStatus);
    }

    let interval = setInterval(async () => {
      if (planStatusCheckCount >= pollCount) {
        resetPollForPlanStatus(interval);
        setPlanSwitchStatus(PlanSwitchStatus.Failed);
        return;
      }

      try {
        setPlanStatusCheckCount((prev) => prev + 1);
        const isPlanSwitched = await checkCurrentPlanStatus(getPlanId());
        if (isPlanSwitched) {
          setPlanSwitchStatus(PlanSwitchStatus.Success);
          resetPollForPlanStatus(interval);
        }
      } catch (error) {
        console.error("Payment status check failed");
        clearInterval(interval);
      }
    }, 10000);
  };

  const resetPollForPlanStatus = (interval: any) => {
    setPlanStatusCheckCount(0);
    clearInterval(interval);
  };

  const pollForPlanStatusForFirstTime = async () => {
    if (planStatusCheckCount != 0) {
      return;
    }

    setPlanSwitchStatus(PlanSwitchStatus.FirstTimeCheckingPlanStatus);

    let interval = setInterval(async () => {
      if (planStatusCheckCount >= 12) {
        resetPollForPlanStatus(interval);
        setPlanSwitchStatus(PlanSwitchStatus.FirstTimePlanStatusCheckDone);
        return;
      }

      try {
        setPlanStatusCheckCount((prev) => prev + 1);
        const isPlanSwitched = await checkCurrentPlanStatus(getPlanId());
        if (isPlanSwitched) {
          setPlanSwitchStatus(PlanSwitchStatus.FirstTimePlanStatusCheckDone);
          resetPollForPlanStatus(interval);
        }
      } catch (error) {
        clearInterval(interval);
      }
    }, 15000);
  };

  const onApprove = async (data: any, actions: any) => {
    setPaymentConfirmed(true);
    pollForPlanStatusForFirstTime();
  };

  const openDashboard = () => {
    window.location.href = "/orders";
  };

  useEffect(() => {
    if (!location.state || !location.state.newPlan) {
      navigate("/login", { replace: true });
    }
  }, [payForYearly, paymentConfirmed]);

  return (
    <Stack minH={"100vh"} direction={{ base: "column", md: "row" }} bg={"gray.50"}>
      <Flex p={8} flex={1} justify={"center"} bg={"brand.500"}>
        <VStack align={"left"} spacing={"10"} minW={"60%"}>
          <Button position={"absolute"} left={"50"} mt={"5"} leftIcon={<ArrowBackIcon />} color={"gray.200"} variant="ghost" onClick={() => navigate(-1)} _hover={{ bg: "transparent", color: "white" }}>
            Back
          </Button>
          <VStack pb={10}>
            <Text w={"auto"} background={"brand.600"} color="white" fontFamily={"logoFont"} borderRadius="40px" fontSize={"2rem"} py={"10px"} px={"20px"} fontWeight={"bold"}>
              brandzway
            </Text>
          </VStack>
          <Text color={"brand.100"} fontSize={"2xl"} fontWeight={"semibold"}>
            Subscribe to {newPlan} Plan
          </Text>
          <VStack align={"left"} spacing={"-1"}>
            <HStack>
              <Text color={"white"} fontSize={"5xl"} as="b">
                ${isYearlyPlan || payForYearly ? yearlyPrice : monthlyPrice}
              </Text>
              <VStack spacing={-1} align={"left"}>
                <Text color={"brand.100"} fontSize={"md"} fontWeight={"semibold"}>
                  per
                </Text>
                <Text color={"brand.100"} fontSize={"md"} fontWeight={"semibold"}>
                  {isYearlyPlan || payForYearly ? "year" : "month"}
                </Text>
              </VStack>
            </HStack>
            <Text color={"brand.50"} fontSize={"sm"} pl={1}>
              Billed {isYearlyPlan || payForYearly ? "Yearly" : "Monthly"}
            </Text>
          </VStack>
          {!isYearlyPlan && byPlanIdBase != "reviews_free" && (
            <HStack border="1px" borderColor="#edf2f714" padding={"4"} borderRadius={"xl"} bg={"#f7fafc17"}>
              <Switch
                ref={payForYearlySwitchRef}
                colorScheme={"green"}
                bg={"white"}
                borderRadius={"xl"}
                border={"2px"}
                borderColor={"white"}
                isChecked={payForYearly}
                onChange={() => {
                  setPayForYearly(!payForYearly);
                }}
              />
              <Tag colorScheme={"green"}>Save ${(monthlyPrice * 12 - yearlyPrice).toFixed(1)}</Tag>
              <Text color={"brand.50"} fontSize={"sm"}>
                with annual billing
              </Text>
              <Spacer minW={"100px"} />
              <Text color={"brand.50"} fontSize={"sm"}>
                ${yearlyPrice}/year
              </Text>
            </HStack>
          )}
        </VStack>
      </Flex>
      <Flex flex={1} maxH={"100vh"} justify={"center"} align={"center"}>
        <VStack spacing={"-5"} width={"100%"}>
          {!resumeSubscription && (
            <>
              <Flex minH={"200px"} width={"100%"} justifyContent={"center"}>
                <PayPalScriptProvider options={{ "client-id": clientId, components: "buttons", vault: true, intent: "subscription" }}>
                  <Box w={"80%"} maxW={"500px"} alignContent={"center"} textAlign={"center"}>
                    <PayPalButtons
                      createSubscription={createSubscription}
                      onApprove={onApprove}
                      style={{
                        shape: "rect",
                        color: "black",
                        layout: "vertical",
                        label: "subscribe",
                      }}
                    />
                  </Box>
                </PayPalScriptProvider>
              </Flex>
              <Text maxW={"600px"} fontSize={"xs"} textAlign={"center"} color={"gray.500"} alignItems={"bottom"} pt={"50px"}>
                By confirming your subscription, you allow Brandzway to charge your card for this payment and future payments in accordance with their terms. You can always cancel your subscription.
              </Text>
            </>
          )}
          {resumeSubscription && (
            <Flex height={"auto"} width={"100%"} justifyContent={"center"} mt={-20}>
              <Button isLoading={planSwitchStatus == PlanSwitchStatus.Processing || planSwitchStatus == PlanSwitchStatus.ProcessingApprovePayment || planSwitchStatus == PlanSwitchStatus.CheckingPlanStatus} loadingText="Processing plan switch" bg={"black"} maxW={"500px"} color={"white"} width={"100%"} fontSize={"lg"} py={"30px"} fontWeight={"bold"} _hover={{ color: "gray.400" }} onClick={completePlanSwitch}>
                Complete Plan Switch
              </Button>
            </Flex>
          )}
        </VStack>
      </Flex>

      {paymentConfirmed && (
        <Flex position="absolute" top={0} left={0} width="100%" height="100%" backgroundColor="#0000008c" display="flex" justifyContent="center" alignItems="center" zIndex={1000}>
          <VStack bg={"white"} px={10} pb={20} borderRadius={"2xl"} textAlign={"center"} maxW={"2xl"}>
            <Center>
              <img src="./assets/on-subscription-success.png" width="400px" />
            </Center>
            <Text py={2} as="b" fontSize={"2xl"} letterSpacing={-1}>
              Payment Success!
            </Text>
            <Text py={2}>Thank you for choosing our premium plan! We can't wait to see all the amazing things you'll accomplish with our premium features.</Text>
            {planSwitchStatus === PlanSwitchStatus.FirstTimePlanStatusCheckDone && (
              <Button colorScheme={"brand"} onClick={openDashboard} mt={8}>
                Open Dashboard
              </Button>
            )}
            {planSwitchStatus === PlanSwitchStatus.FirstTimeCheckingPlanStatus && (
              <>
                <Text py={5} as="b" fontSize={"2xl"} letterSpacing={-1}>
                  Getting your plan ready...
                </Text>
                <Spinner thickness="4px" speed="0.55s" emptyColor="gray.200" size="xl" />
              </>
            )}
          </VStack>
        </Flex>
      )}

      {planSwitchStatus == PlanSwitchStatus.ProcessingApprovePayment && (
        <Flex position="absolute" top={0} left={0} width="100%" height="100%" backgroundColor="#0000008c" display="flex" justifyContent="center" alignItems="center" zIndex={1000}>
          <VStack bg={"white"} px={10} p={50} borderRadius={"2xl"} textAlign={"center"} maxW={"2xl"}>
            <Text py={5} as="b" fontSize={"2xl"} letterSpacing={-1}>
              Processing Plan Switch...
            </Text>
            <Text py={5} as="b" fontSize={"xl"}>
              Please approve the subscription in the popup window.
            </Text>
            <Spinner thickness="4px" speed="0.55s" emptyColor="gray.200" size="xl" />
            <Text pt={10} pb={5}>
              It may take a few minutes to confirm. Please hold on...
            </Text>
            <Text py={2} fontSize={"xs"} color={"gray.500"}>
              Don't refresh or go back
            </Text>
          </VStack>
        </Flex>
      )}

      {planSwitchStatus == PlanSwitchStatus.CheckingPlanStatus && (
        <Flex position="absolute" top={0} left={0} width="100%" height="100%" backgroundColor="#0000008c" display="flex" justifyContent="center" alignItems="center" zIndex={1000}>
          <VStack bg={"white"} px={10} p={50} borderRadius={"2xl"} textAlign={"center"} maxW={"2xl"}>
            <Text py={5} as="b" fontSize={"2xl"} letterSpacing={-1}>
              Confirming your Plan Switch...
            </Text>
            <Spinner thickness="4px" speed="0.55s" emptyColor="gray.200" size="xl" />
            <Text py={5}>It may take a few minutes. Please hold on...</Text>
            <Text py={5} fontSize={"xs"} color={"gray.500"}>
              Don't refresh or go back
            </Text>
          </VStack>
        </Flex>
      )}

      {planSwitchStatus == PlanSwitchStatus.Success && (
        <Flex position="absolute" top={0} left={0} width="100%" height="100%" backgroundColor="#0000008c" display="flex" justifyContent="center" alignItems="center" zIndex={1000}>
          <VStack bg={"white"} px={10} pb={20} borderRadius={"2xl"} textAlign={"center"} maxW={"2xl"}>
            <Center>
              <img src="./assets/on-subscription-success.png" width="400px" />
            </Center>
            <Text py={2} as="b" fontSize={"2xl"} letterSpacing={-1}>
              Plan Change Successful!
            </Text>
            <Text py={2}>You've successfully switched to the {newPlan} plan</Text>
            <Button colorScheme={"brand"} onClick={openDashboard} mt={10}>
              Open Dashboard
            </Button>
          </VStack>
        </Flex>
      )}

      {planSwitchStatus == PlanSwitchStatus.Failed && (
        <Flex position="absolute" top={0} left={0} width="100%" height="100%" backgroundColor="#0000008c" display="flex" justifyContent="center" alignItems="center" zIndex={1000}>
          <VStack bg={"white"} px={10} p={50} borderRadius={"2xl"} textAlign={"center"} maxW={"2xl"}>
            <Icon as={WarningTwoIcon} fontSize={"6xl"} color={"red.500"} />
            <Text py={5} as="b" fontSize={"2xl"} letterSpacing={-1}>
              Plan Switch Failed!
            </Text>
            <Text py={2}>Please try again after some time.</Text>
            <Button colorScheme={"brand"} onClick={openDashboard} mt={8}>
              Open Dashboard
            </Button>
          </VStack>
        </Flex>
      )}
    </Stack>
  );
}
