import { Auth } from "aws-amplify";
import axios from "axios";
import { AssetType } from "../components/file-uploader";
import { config } from "../deployment/config";

export const dashboardUploadFile = async (file: File, siteId: string, uploadAssetType: AssetType): Promise<void> => {
  const assetType = uploadAssetType.toString().toLocaleLowerCase();
  const endpoint = `${config.API_ENDPOINT}/dashboard/upload-file?siteId=${siteId}&assetType=${assetType}`;
  const formData = new FormData();
  formData.append("file", file);

  const session = (await Auth.currentSession()) as any;

  // Support for file names for REF
  // const files = [
  //   { data: file1, name: "file1.jpg" },
  //   { data: file2, name: "file2.jpg" },
  //   { data: file3, name: "file3.jpg" },
  // ];

  // const formData = new FormData();
  // files.forEach((file, index) => {
  //   formData.append(`file-${index}`, file.data, file.name);
  // });

  try {
    const response = await axios.post(endpoint, formData, {
      headers: {
        Authorization: `Bearer ${session.accessToken.jwtToken}`,
        "Content-Type": "multipart/form-data",
      },
    });
    console.log(`File uploaded successfully with response status: ${response.status}`);
  } catch (error: any) {
    console.error(`Error uploading file: ${error.message}`);
    throw error;
  }
};
