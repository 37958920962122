import { gql, useMutation } from "@apollo/client";
import { updateSiteSettings } from "../appsync/graphql/mutations";

export const useUpdateSiteSettings = () => {
  const [call, { loading, error, data }] = useMutation<{ success: String }>(gql(updateSiteSettings), {
    fetchPolicy: "no-cache",
  });

  return {
    updateSiteSettings: call,
    updateSiteSettingsData: data?.success,
    updateSiteSettingsLoading: loading,
    updateSiteSettingsError: error,
  };
};
