import { Text, Flex, VStack } from "@chakra-ui/react";

export const ErrorOccurred = () => {
  return (
    <Flex justifyContent={"center"} pt={5}>
      <VStack>
        <Text size="lg" pt={3} color={"gray.600"}>
          Error Occurred. Please try again later
        </Text>
      </VStack>
    </Flex>
  );
};
