// export interface SiteSettings2 {
//   widget: Widget;
//   email: Email;
//   settings: Settings;
// }

export interface LogoImageDetails {
  imagePath: string;
  imageThumbailPath: string;
  lastUpdated: string;
}

export interface BannerImageDetails {
  imagePath: string;
  imageThumbailPath: string;
  lastUpdated: string;
}

export enum WidgetLayout {
  list = "List",
  grid = "Grid",
}

export enum ReviewButtonType {
  stars = "Stars",
  button = "Button",
}

export enum DefaultSortings {
  newest = "Newest",
  oldest = "Oldest",
}

export enum EmailTriggerAt {
  placed = "Placed",
  fulfilled = "Fullfilled",
}

export interface Widget {
  primaryColor: string;
  starColor: string;
  widgetLayout: WidgetLayout;
  cardColor: string;
  reviewDate: boolean;
  reviewDateFormat: string;
  reviewTitle: boolean;
  showVerifyBadge: boolean;
  hideIfNoReview: boolean;
  reviewsPerPage: number;
  allowSorting: boolean;
  defaultSoring: string;
  removeBrandzwayBranding: boolean;
  writeReviewButton: boolean;
  buttonsBorderRadius: number;
}

export interface Email {
  canvasColor: string;
  panelColor: string;
  textColor: string;
  productNameColor: string;
  logoImageEnabled: boolean;
  logoImageDetails: LogoImageDetails;
  bannerImageEnabled: boolean;
  bannerImageDetails: BannerImageDetails;
  subject: string;
  body: string;
  reviewButtonType: ReviewButtonType;
  reviewButtonStarColor: string;
  reviewButtonText: string;
  reviewButtonTextColor: string;
  reviewButtonBackgroundColor: string;
  reviewButtonBorderRadius: number;
  footerEnabled: boolean;
  footerText: string;
  socialMediaLinkEnabled: boolean;
  socialMediaText: string;
  fbLink: string;
  tiktokLink: string;
  instagramLink: string;
  twitterLink: string;
}

export interface Settings {
  autoSendReviewEmails: boolean;
  emailDelay: number;
  emailTriggerAt: EmailTriggerAt;
  autoPublishReview: boolean;
  starRatingForAutoPublishReview: number;
  notifyOn1Star: boolean;
  notifyOn2Star: boolean;
  notifyOn3Star: boolean;
  notifyOn4Star: boolean;
  notifyOn5Star: boolean;
  notifyOnNewReview: boolean;
  replyToEmail: string;
  siteName: string;
}
