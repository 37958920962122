import { Flex, Box, FormControl, FormLabel, Input, InputGroup, HStack, InputRightElement, Stack, Button, Heading, Text, useColorModeValue, Link, FormErrorMessage, Popover, PopoverTrigger, PopoverContent, PopoverArrow, PopoverBody, Icon } from "@chakra-ui/react";
import { useState } from "react";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import passwordValidator from "password-validator";
import { useForm } from "react-hook-form";
import { TiTick } from "react-icons/ti";

import { FiXCircle } from "react-icons/fi";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Auth } from "aws-amplify";
import { useToast } from "@chakra-ui/react";
import PulseLoader from "react-spinners/PulseLoader";

export default function Signup() {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [passwordPolicy, setPasswordPolicy] = useState([false, false, false, false, false]);
  const [isPasswordValid, setPasswordValid] = useState(false);
  const [firstPassword, setFirstPassword] = useState("");
  const [isPasswordMatch, setPasswordMatch] = useState(false);
  const passwordRef = React.useRef<HTMLInputElement>(null);
  const confirmPasswordRef = React.useRef<HTMLInputElement>(null);
  const [isPasswordPopOverOpen, setPasswordPopOverOpen] = useState(false);
  const [isConfirmPasswordPopOverOpen, setConfirmPasswordPopOverOpen] = useState(false);
  const [isCreatingAccount, setCreatingAccount] = useState(false);

  // create a password schema
  const schema = new passwordValidator();
  schema.is().min(8).has().uppercase().has().lowercase().has().digits().has().symbols();

  const navigate = useNavigate();
  const toast = useToast();

  const valdiateFirstPassword = (event: any) => {
    let tempfirstPassword = event.target.value;
    setFirstPassword(tempfirstPassword);
    const validationRulesErrors = schema.validate(tempfirstPassword, { list: true });
    handlePasswordValidateError(validationRulesErrors);
  };

  const valdiateSecondPassword = (event: any) => {
    const secondPassword = event.target.value;
    if (firstPassword == secondPassword) {
      setPasswordMatch(true);
    } else {
      setPasswordMatch(false);
    }
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const handlePasswordValidateError = (errors: any) => {
    let newPasswordPolicy: Array<boolean> = [true, true, true, true, true];
    for (let i = 0; i < errors.length; i++) {
      if (errors[i] === "min") {
        newPasswordPolicy[0] = false;
      } else if (errors[i] === "lowercase") {
        newPasswordPolicy[1] = false;
      } else if (errors[i] === "uppercase") {
        newPasswordPolicy[2] = false;
      } else if (errors[i] === "digits") {
        newPasswordPolicy[3] = false;
      } else if (errors[i] === "symbols") {
        newPasswordPolicy[4] = false;
      }
    }

    let checker: boolean = newPasswordPolicy.every((v) => v === true);
    setPasswordValid(checker);
    setPasswordPolicy(newPasswordPolicy);
  };

  function onSubmit(values: any) {
    if (!isPasswordValid) {
      setPasswordPopOverOpen(true);
    }

    if (values["password"] != values["confirmPassword"]) {
      setConfirmPasswordPopOverOpen(true);
      setPasswordMatch(false);
    } else {
      setPasswordMatch(true);
    }

    if (isPasswordValid && isPasswordMatch) {
      setCreatingAccount(true);
      signUp(values["name"].trim(), values["email"].trim(), values["password"]);
    }
  }

  async function signUp(name: string, email: string, password: string) {
    try {
      const { user } = await Auth.signUp({
        username: email,
        password,
        attributes: {
          email,
          name,
        },
        autoSignIn: {
          // enables auto sign in after user is confirmed
          enabled: true,
        },
      });
      localStorage.setItem("username", email);
      navigate("/verify", { replace: true });
      //Log
    } catch (error: any) {
      let titleMessage = "Something went wrong. Please try again later.";
      if (error && error.code == "UsernameExistsException") {
        titleMessage = "Email already registered.";
      }

      toast({
        title: titleMessage,
        status: "error",
        position: "top-left",
        duration: 5000,
        isClosable: true,
      });
      setCreatingAccount(false);

      //Log
    }
  }
  return (
    <Stack minH={"100vh"} direction={{ base: "column", md: "row" }} bg={useColorModeValue("gray.50", "gray.800")}>
      <Flex p={8} flex={1} align={"center"} justify={"center"}>
        <Stack spacing={8} mx={"auto"} width={["lg"]} maxW={"lg"} py={12} px={6}>
          <Stack align={"center"}>
            <Heading fontSize={"4xl"} textAlign={"center"}>
              Create an Account.
            </Heading>
            <Text fontSize={"lg"} color={"gray.600"}>
              Create account to start using Brandzway ✌️
            </Text>
          </Stack>
          <Box rounded={"lg"} bg={useColorModeValue("white", "gray.700")} boxShadow={"lg"} p={8}>
            <Stack spacing={4}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <FormControl id="name" isRequired isDisabled={isCreatingAccount} mb={4}>
                  <FormLabel>Full Name</FormLabel>
                  <Input
                    type="text"
                    {...register("name", {
                      required: "Please enter your Full Name.",
                      minLength: { value: 1, message: "Full Name can't be empty." },
                    })}
                  />
                  <FormErrorMessage>{errors && errors.message?.toString()}</FormErrorMessage>
                </FormControl>
                <FormControl id="email" isRequired isDisabled={isCreatingAccount} mb={4}>
                  <FormLabel>Email address</FormLabel>
                  <Input
                    type="email"
                    {...register("email", {
                      required: "Please enter your email.",
                      minLength: { value: 2, message: "Invalid email address." },
                    })}
                  />
                  <FormErrorMessage>{errors.title && errors.title.message?.toString()}</FormErrorMessage>
                </FormControl>
                <FormControl id="password" isRequired isDisabled={isCreatingAccount} mb={4}>
                  <FormLabel>Password</FormLabel>
                  <Popover placement="right" initialFocusRef={passwordRef} isOpen={isPasswordPopOverOpen}>
                    <PopoverTrigger>
                      <InputGroup ref={passwordRef}>
                        <Input
                          type={showPassword ? "text" : "password"}
                          onFocus={() => setPasswordPopOverOpen(true)}
                          {...register("password", {
                            required: "Please enter the password.",
                            onChange: valdiateFirstPassword,
                            onBlur: () => isPasswordValid && setPasswordPopOverOpen(false),
                            minLength: { value: 8, message: "Invalid Password" },
                          })}
                        />
                        <InputRightElement h={"full"}>
                          <Button variant={"ghost"} onClick={() => setShowPassword((showPassword) => !showPassword)}>
                            {showPassword ? <ViewIcon /> : <ViewOffIcon />}
                          </Button>
                        </InputRightElement>
                      </InputGroup>
                    </PopoverTrigger>
                    <PopoverContent maxW={260}>
                      <PopoverArrow />
                      <PopoverBody fontSize={"0.9rem"}>
                        <PasswordPolicyItem message="Be at least 8 Characters" policyStatus={passwordPolicy[0]} />
                        <PasswordPolicyItem message="At least one Lowercase letter" policyStatus={passwordPolicy[1]} />
                        <PasswordPolicyItem message="At least one Uppercase letter" policyStatus={passwordPolicy[2]} />
                        <PasswordPolicyItem message="At least one digit(0-9) letter" policyStatus={passwordPolicy[3]} />
                        <PasswordPolicyItem message="At least one special character" policyStatus={passwordPolicy[4]} />
                      </PopoverBody>
                    </PopoverContent>
                  </Popover>
                </FormControl>
                <FormControl id="confirmPassword" isRequired isDisabled={isCreatingAccount} mb={4}>
                  <FormLabel>Confirm Password</FormLabel>
                  <Popover placement="right" initialFocusRef={confirmPasswordRef} isOpen={!isPasswordMatch && isConfirmPasswordPopOverOpen}>
                    <PopoverTrigger>
                      <InputGroup ref={confirmPasswordRef}>
                        <Input
                          type={showConfirmPassword ? "text" : "password"}
                          onFocus={() => setConfirmPasswordPopOverOpen(true)}
                          {...register("confirmPassword", {
                            required: "Please enter the password.",
                            minLength: { value: 8, message: "Invalid Password" },
                            onChange: valdiateSecondPassword,
                            onBlur: () => isPasswordMatch && setConfirmPasswordPopOverOpen(false),
                          })}
                        />
                        <InputRightElement h={"full"}>
                          <Button variant={"ghost"} onClick={() => setShowConfirmPassword((showConfirmPassword) => !showConfirmPassword)}>
                            {showConfirmPassword ? <ViewIcon /> : <ViewOffIcon />}
                          </Button>
                        </InputRightElement>
                      </InputGroup>
                    </PopoverTrigger>
                    <PopoverContent maxW={200}>
                      <PopoverArrow />
                      <PopoverBody fontSize={"0.8rem"}>
                        <PasswordPolicyItem message="Password do not match." policyStatus={false} />
                      </PopoverBody>
                    </PopoverContent>
                  </Popover>
                </FormControl>
                <Stack spacing={10} pt={5}>
                  <Button
                    type="submit"
                    isLoading={isCreatingAccount}
                    spinner={<PulseLoader size={10} color="white" />}
                    loadingText="Submitting"
                    size="lg"
                    bg={"brand.500"}
                    color={"white"}
                    _hover={{
                      bg: "brand.600",
                    }}
                  >
                    Create Account
                  </Button>
                </Stack>
              </form>
              <Stack pt={6}>
                <Text align={"center"}>
                  Already a user?{" "}
                  <Link color={"brand.600"} onClick={() => navigate("/login", { replace: true })}>
                    Login
                  </Link>
                </Text>
              </Stack>
            </Stack>
          </Box>
        </Stack>
      </Flex>
      <Flex flex={1} maxH={"100vh"}>
        <img alt={"Signup Image"} src={"./signup-cover.jpg"} className={"signup-cover"} />
        <Text position={"absolute"} marginLeft={"-115px"} top={"50px"} background={"brand.600"} color="white" fontFamily={"logoFont"} borderRadius="40px" fontSize={"2rem"} py={"10px"} px={"20px"} fontWeight={"bold"}>
          brandzway
        </Text>
      </Flex>
    </Stack>
  );
}

const PasswordPolicyItem = ({ message, policyStatus, children, ...rest }: any) => {
  return (
    <p style={{ color: policyStatus ? "green" : "red" }}>
      {!policyStatus && <Icon mr="2" fontSize="12" as={FiXCircle} color={"red"} />}
      {policyStatus && <Icon mr="1" fontSize="14" as={TiTick} color={"green"} />}
      {message}
    </p>
  );
};
